import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function PassportPhotoErrors (props) {
  return (
    <>
      <Title level={3}>Основные ошибки</Title>
      <Text>Убедитесь, что в кадре нет посторонних предметов, пальцы не перекрывают страницы</Text>
      <br />
      <img src='/img/photo-doc/passport-err-1.png' className='img-doc' />
      <img src='/img/photo-doc/passport-err-2.png' className='img-doc' />
      <br />
      <Text>Нет теней и бликов.<br />Текст и изображения на страницах чёткие, хорошо видны</Text>
      <br />
      <img src='/img/photo-doc/passport-err-3.png' className='img-doc' />
      <img src='/img/photo-doc/passport-err-4.png' className='img-doc' />
      <br />
      <Text>Вы и вся информация в паспорте видны на одном кадре, рука не перекрывает данные</Text>
      <br />
      <img src='/img/photo-doc/passport-err-5.png' className='img-doc' />
      <br />
      <Text>Все фотографии чёткие и хорошего качества</Text>
      <br />
      <img src='/img/photo-doc/passport-err-6.png' className='img-doc' />
    </>
  )
}

export default PassportPhotoErrors
