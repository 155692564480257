import React, { useReducer } from 'react'
import { api } from '../../../api/react-query/api'
import { Button, message, Table } from 'antd'
import moment from 'moment-timezone'
import { AppConfig } from '../../../AppConfig'
import { simpleReducer } from '../../../helper'

function ReportList ({ isLoadingReports, dataReports, showTypeColumn = false }) {
  const initialState = {
    currentLoadingReportId: null
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleGetReport = async (id) => {
    setState({ currentLoadingReportId: id })
    await api.downloadFile({
      path: `reports/download/${id}`,
      method: 'GET',
      filename: `report ${id}.xlsx`,
      onSuccess: () => {
        setState({ currentLoadingReportId: null })
      },
      onError: () => {
        setState({ currentLoadingReportId: null })
        message.error('Ошибка при генерации отчета.')
      }
    })
  }

  const reportColumns = [
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      render: date => (date ? moment(date).format(AppConfig.formats.shortDateAndTime) : '')
    },
    {
      title: 'Тип отчета',
      dataIndex: 'type',
      render: (type, record) => record.name
    },
    {
      title: 'Статус',
      dataIndex: 'status'
    },
    {
      title: 'Действия',
      render: (row) => (
        <Button
          type='primary'
          loading={row.id === state.currentLoadingReportId}
          disabled={row.statusCode !== AppConfig.report.statuses.ready}
          onClick={() => handleGetReport(row.id)}
        >
          Скачать
        </Button>
      )
    }
  ]

  return (
    <Table
      loading={isLoadingReports}
      dataSource={dataReports}
      columns={showTypeColumn ? reportColumns : reportColumns.filter(col => col.dataIndex !== 'type')}
      rowKey={record => record.id}
      pagination={{ pageSize: 20 }}
    />
  )
}

export default ReportList
