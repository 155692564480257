import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, message } from 'antd'
import { GoogleOutlined } from '@ant-design/icons'
import env from '../../../../env.json'
import { ADMIN_AUTH_PAGE, ADMIN_PARTNERS_PAGE } from '../../../../routes'
import { getHeaders } from '../../../../api/react-query/api'

function GoogleAuth () {
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2
        .init({
          client_id: env.googleClientID
        })
    })
  }, [])

  const handleSignIn = () => {
    setIsLoading(true)
    const auth2 = window.gapi?.auth2?.getAuthInstance()
    auth2?.currentUser?.listen(googleUser => {
      if (!googleUser.isSignedIn()) return
      fetch(env.apiEndpoint + 'admin/auth/google', {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
          token: googleUser?.getAuthResponse()?.id_token
        })
      })
        .then(r => {
          if (r.status === 401) {
            history?.push(ADMIN_AUTH_PAGE)
            return Promise.resolve({})
          }
          return r.json()
        })
        .then(r => {
          setIsLoading(false)
          if (r.isSuccess) {
            if (process.env.NODE_ENV === 'development') {
              document.cookie = 'user=' + googleUser?.getAuthResponse()?.id_token
            }
            history.push(ADMIN_PARTNERS_PAGE)
          } else {
            message.error('Неудачная попытка авторизации')
          }
        })
        .catch(e => {
          setIsLoading(false)
          console.log(e)
          message.error('Неудачная попытка авторизации')
        })
    })
    auth2?.signIn()
  }

  return (
    <Button icon={<GoogleOutlined />} loading={isLoading} type='primary' onClick={handleSignIn}>Войти c Google</Button>
  )
}

export default GoogleAuth
