import { errors } from '../translates'
import useCommonDict from './useCommonDict'
import { useOrgCitiesQuery } from '../api/react-query/org'
import { sortByName } from '../helper'

export default function useCities (data, options) {
  return useCommonDict({
    errorMessage: errors.cities,
    queryMethod: useOrgCitiesQuery,
    queryBody: data,
    queryOptions: options,
    successCallback: data => [...sortByName(data?.result || [])]
  })
}
