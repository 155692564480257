import React, { useEffect } from 'react'
import { Col, Row } from 'antd'

import ADALAuth from './ADAL/ADALAuth'
import GoogleAuth from './Google/GoogleAuth'

import env from '../../../env.json'
import './AdminAuth.css'
import { COORDINATOR_PARTNER_ID } from '../../../Constants'

function AdminAuth () {
  useEffect(() => {
    localStorage.removeItem(COORDINATOR_PARTNER_ID)
  }, [])

  return (
    <div className='admin-auth'>
      <h2>Войти на партнерский сайт Купер</h2>
      <Row align='center'>
        {
          env.envName === 'dev' && <Col className='mr-3'><GoogleAuth /></Col>
        }
        <Col className='ml-3'><ADALAuth /></Col>
      </Row>
    </div>
  )
}

export default AdminAuth
