import React, { useMemo } from 'react'
import { useStores } from '../../../../Store/Store'
import { partnerStatusCodes } from '../../../../AppConfig'
import { Alert } from 'antd'
import { errors, profileScreen } from '../../../../translates'
import { flowV2Steps } from '../../V2/ProfileV2'
import './ErrorStep.css'

function CorrectInfo ({ step }) {
  const { partnerProfile } = useStores()

  const errorList = useMemo(() => {
    const profile = partnerProfile?.profile
    const inputs = profileScreen.input
    const list = {
      [flowV2Steps.passportStep]: [
        profile?.personalData?.statusReason,
        profile?.[inputs.passportPhoto1.name]?.statusReason,
        profile?.[inputs.passportPhoto2.name]?.statusReason
      ],
      [flowV2Steps.legalAgeStep]: [
        profile?.[inputs.legalCapacityConfirmation.name]?.statusReason,
        profile?.[inputs.trusteeAgreement.name]?.statusReason,
        profile?.[inputs.trusteePassport.name]?.statusReason,
        profile?.[inputs.trusteeConfirmation.name]?.statusReason
      ],
      [flowV2Steps.innStep]: [
        profile?.innDetails?.statusReason
      ]
    }
    return list[step]
  }, [step])

  const description = useMemo(() => {
    return (
      <ul>
        {errorList?.map((error, index) => (
          error && <li key={index + error}>{error}</li>
        ))}
      </ul>
    )
  }, [errorList])

  if (partnerProfile?.profile?.statusCode !== partnerStatusCodes.correction) return null

  return (
    <Alert
      className='correct-info'
      message={errors.reasonsTitle}
      description={description}
      type='error'
      showIcon
    />
  )
}

export default CorrectInfo
