import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function TrusteeConfirmation () {
  return (
    <>
      <Title level={3}>Какие документы подходят</Title>
      <Text>1. Свидетельство о рождении</Text>
      <br />
      <Text>2. Свидетельство об усыновлении/удочерении</Text>
      <br />
      <Text>3. Акт об опекунстве</Text>
      <br />
      <Text>4. Паспорт РФ законного представителя, стр. 16–17 — сведения о детях</Text>
      <br />
      <img src='/img/photo-doc/passport-doc-3.png' className='img-doc' />
    </>
  )
}

export default TrusteeConfirmation
