import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function EgripPhoto () {
  return (
    <>
      <Title level={3}>Перейдите на сайт ФНС</Title>
      <Text>Введите свой ИНН или ОГРНИП и нажмите кнопку «Найти»</Text>
      <br />
      <br />
      <img src='/img/photo-doc/egrip-doc-1.png' className='img-doc' />
    </>
  )
}

export default EgripPhoto
