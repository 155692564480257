import React from 'react'
import { Button, Modal, Typography } from 'antd'
import { profileScreen } from '../../../translates'

const { Text } = Typography

export const medicalDocTypes = {
  medicalBookNumber: profileScreen.input.medicalBookNumber.name,
  lmkAllowancePhoto: profileScreen.input.lmkAllowancePhoto.name,
  lmkAttestationPhoto: profileScreen.input.lmkAttestationPhoto.name
}

const modalTitles = {
  [profileScreen.input.medicalBookNumber.name]: profileScreen.input.medicalBookNumber.label,
  [profileScreen.input.lmkAllowancePhoto.name]: profileScreen.input.lmkAllowancePhoto.label,
  [profileScreen.input.lmkAttestationPhoto.name]: profileScreen.input.lmkAttestationPhoto.label
}

function MedicalDocModal ({
  type = profileScreen.input.medicalBookNumber.name,
  visible = false,
  onCancel = () => {}
}) {
  return (
    <Modal
      title={modalTitles[type]}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      {
        {
          [profileScreen.input.medicalBookNumber.name]: <MedicalBookNumberContent />,
          [profileScreen.input.lmkAllowancePhoto.name]: <MedicalAllowancePhotoContent />,
          [profileScreen.input.lmkAttestationPhoto.name]: <MedicalAttestationPhotoContent />
        }[type]
      }
      <div style={{ textAlign: 'right', marginTop: '1rem' }}>
        <Button onClick={onCancel}>Закрыть</Button>
      </div>
    </Modal>
  )
}

function MedicalBookNumberContent () {
  return (
    <>
      <Text>
        Введите номер медкнижки, который располагается внизу страниц
        каждой нечетной страницы с 1-15-ю и состоит из № и 8 красных цифр
      </Text>
      <img src='/img/photo-doc/medical-number.png' className='img-doc' style={{ width: '100%', marginTop: '0.5rem' }} />
    </>
  )
}

function MedicalAllowancePhotoContent () {
  return (
    <>
      <Text>{profileScreen.input.lmkAllowancePhoto.hint}</Text>
      <img src='/img/photo-doc/medical-allowance-photo.png' className='img-doc' style={{ width: '100%', marginTop: '0.5rem' }} />
      <br />
      <br />
      <img src='/img/photo-doc/medical-allowance-photo2.png' className='img-doc' style={{ width: '100%', marginTop: '0.5rem' }} />
    </>
  )
}

function MedicalAttestationPhotoContent () {
  return (
    <>
      <Text>{profileScreen.input.lmkAttestationPhoto.hint}</Text>
      <img src='/img/photo-doc/medical-attestation-photo.png' className='img-doc' style={{ width: '100%', marginTop: '0.5rem' }} />
    </>
  )
}

export default MedicalDocModal
