import { api } from './api'
import { useQuery } from 'react-query'

export const STATS_KEY = 'stats'

const fetchVerification = ({ queryKey }) => {
  const [/* key */, statKey] = queryKey
  return api.get({ path: `${STATS_KEY}/verification/${statKey}` })
}

const fetchStatsList = () => {
  return api.get({ path: `${STATS_KEY}/list` })
}

export function useGetVerificationStatQuery (statType, options) {
  return useQuery([STATS_KEY, statType], fetchVerification, options)
}

export function useGetStatsListQuery (options) {
  return useQuery([STATS_KEY, 'list'], fetchStatsList, options)
}
