import { Button, Col, Form, message, Modal, Row, Select, Spin, Tag, Typography } from 'antd'
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import Tags from '@yaireo/tagify/dist/react.tagify'

import { useActByTypeQuery, useActTemplateTagsQuery, useMutateActCreate } from '../../../api/react-query/acts'
import { useActTypesQuery } from '../../../api/react-query/dict'
import { arrayUniqValues, simpleReducer } from '../../../helper'
import moment from 'moment-timezone'
import { AppConfig } from '../../../AppConfig'

const { Option } = Select
const { Text, Paragraph } = Typography
const { confirm } = Modal

const initState = {
  templateTags: [],
  insertedTags: [],
  replacementTags: []
}
const tagsSettings = whitelist => ({
  whitelist,
  pattern: /@/,
  autoComplete: {
    enabled: true,
    rightKey: true
  },
  editTags: false,
  duplicates: true,
  enforceWhitelist: true,
  trim: false,
  dropdown: {
    enabled: 1,
    highlightFirst: true // automatically highlights first sugegstion item in the dropdown
  // },
  // delimiters: ',|[\\n\\r]',
  // originalInputValueFormat: e => {
  //   console.log('%c e', 'color: red; font-weight: bold; font-size: 22px', e)
  }
})
const NewActTemplate = ({
  visible = false,
  onClose = () => {}
}) => {
  const tagifyRef = useRef(null)
  const [form] = Form.useForm()
  const [actTypes, setActTypes] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedActType, setSelectedActType] = useState('')
  const [templatesContent, setTemplatesContent] = useState({})
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [state, setState] = useReducer(simpleReducer, initState)

  const handleOpenViewModal = () => setIsViewModalOpen(true)
  const handleCloseViewModal = () => setIsViewModalOpen(false)
  const handleChangeActType = value => setSelectedActType(value)

  const {
    mutate: mutateActCreate,
    isError: isErrorActCreate,
    isLoading: isLoadingActCreate,
    isSuccess: isSuccessActCreate
  } = useMutateActCreate()

  useEffect(() => {
    if (isSuccessActCreate) {
      message.success('Шаблон успешно добавлен')
      onClose()
    } else if (isErrorActCreate) {
      message.error('Ошибка добавления шаблона')
    }
  }, [onClose, isSuccessActCreate, isErrorActCreate])

  const {
    data: actByTypeData,
    isError: isErrorActByType,
    isLoading: isLoadingActByType,
    isSuccess: isSuccessActByType
  } = useActByTypeQuery(selectedActType, { enabled: !!selectedActType })

  useEffect(() => {
    if (isSuccessActByType) {
      let content = actByTypeData?.template?.content || ''
      state.replacementTags.forEach(tag => {
        const re = new RegExp(tag.token.replace(/[{}|]/g, '\\$&'), 'g')
        content = content.replace(re, tag.pattern)
      })
      tagifyRef?.current?.loadOriginalValues(content)

      if (actByTypeData?.template?.content) {
        // если есть активный шаблон заполняем данные для выбраных тэгов и для превью и контента
        setSelectedTags(state.insertedTags?.map(t => t.value))

        let preview = content

        state.replacementTags.forEach(tag => {
          const re = new RegExp(tag.pattern.replace(/[{}[\]]/g, '\\$&'), 'g')
          preview = preview.replace(re, tag.example)
        })

        setTemplatesContent({
          preview,
          content: actByTypeData?.template?.content
        })
      } else {
        setSelectedTags([])
        setTemplatesContent({})
      }
    } else if (isErrorActByType) {
      tagifyRef?.current?.loadOriginalValues('')
      setSelectedTags([])
    }
  }, [state.insertedTags, state.replacementTags, actByTypeData, isSuccessActByType, isErrorActByType])

  const {
    data: actTypesData,
    isError: isErrorActTypes,
    isSuccess: isSuccessActTypes
  } = useActTypesQuery({})

  useEffect(() => {
    if (isSuccessActTypes) {
      setActTypes(actTypesData?.result)
    } else if (isErrorActTypes) {
      console.error(actTypesData)
      message.error('Ошибка получения списка типов актов')
    }
  }, [actTypesData, isErrorActTypes, isSuccessActTypes])

  const {
    data: actTemplateTagsData,
    isError: isErrorActTemplateTags,
    isSuccess: isSuccessActTemplateTags
  } = useActTemplateTagsQuery({})

  useEffect(() => {
    if (isSuccessActTemplateTags) {
      const insertedTags = actTemplateTagsData?.result?.map(tag => ({
        value: tag.name
      }))
      setState({
        templateTags: actTemplateTagsData?.result,
        insertedTags,
        replacementTags: actTemplateTagsData?.result?.map(tag => ({
          pattern: '[[' + JSON.stringify(insertedTags.find(t => tag.name === t.value)) + ']]',
          example: tag.example,
          token: tag.token
        }))
      })
    } else if (isErrorActTemplateTags) {
      console.error(actTemplateTagsData)
      message.error('Ошибка получения списка тэгов шаблона')
    }
  }, [actTemplateTagsData, isErrorActTemplateTags, isSuccessActTemplateTags])

  const handleOnSave = () => {
    form.validateFields().then(fields => {
      // if (state.templateTags.length !== selectedTags.length) {
      //   message.warn('Вам необходимо использовать все тэги из списка')
      //   form.scrollToField('tags')
      //   return
      // }
      if (actByTypeData?.template?.content) {
        confirm({
          title: 'Сохранить новую редакцию шаблона',
          content: `Шаблон от ${moment(actByTypeData?.template?.createdAt).format(AppConfig.formats.shortDate)} будет перемещен в Архив. Создаваемая редакция станет Активной`,
          onOk: () => {
            mutateActCreate({
              content: templatesContent.content,
              actType: fields.actType
            })
          },
          onCancel: () => {
          }
        })
      } else {
        mutateActCreate({
          content: templatesContent.content,
          actType: fields.actType
        })
      }
    })
      .catch(() => {
        form.scrollToField('actType')
      })
  }
  const okButtonProps = useMemo(
    () => ({ className: 'green', loading: isLoadingActCreate }),
    [isLoadingActCreate]
  )

  // on tag add/edit/remove
  const onChange = useCallback((e) => {
    setSelectedTags(arrayUniqValues(e.detail.tagify.value?.map(t => t.value)))

    // удаляем из текста вставку по горячей клавише
    const newValue = e.detail.value.replace(/,"prefix":"@"/g, '')
    let preview = newValue
    let content = newValue

    state.replacementTags.forEach(tag => {
      const re = new RegExp(tag.pattern.replace(/[{}[\]]/g, '\\$&'), 'g')
      preview = preview.replace(re, tag.example)
      content = content.replace(re, tag.token)
    })

    setTemplatesContent({
      preview,
      content
    })
  }, [state.replacementTags])

  const handleTagClick = tag => {
    if (!tagifyRef?.current?.state?.selection) {
      message.info('Установите курсор в то место где хотите вставить тэг')
      return
    }
    const el = tagifyRef?.current?.createTagElem({ value: tag.name })
    tagifyRef?.current?.injectAtCaret(el)
    const text = tagifyRef?.current?.insertAfterTag(el, ' ')
    tagifyRef?.current?.placeCaretAfterNode(text)
  }

  return (
    <Modal
      width={900}
      style={{ top: 20 }}
      visible={visible}
      title='Добавление шаблона'
      onCancel={onClose}
      okText='Сохранить'
      cancelText='Отменить'
      okButtonProps={okButtonProps}
      onOk={handleOnSave}
      destroyOnClose
    >
      <Form
        layout='vertical'
        name='NewActTemplate'
        className='NewActTemplate'
        form={form}
        scrollToFirstError
      >
        <Form.Item
          name='actType'
          label={
            <Col>
              <Row>Тип акта</Row>
              <Row>
                <Text italic type='secondary'>
                  При выборе типа акта значение шаблона будет изменено на значение последнего акта или на пустое
                  значение
                </Text>
              </Row>
            </Col>
          }
          rules={[{ required: true, message: 'Пожалуйста, укажите тип акта' }]}
        >
          <Select
            size='middle'
            dropdownClassName='dropdown'
            placeholder='Выберите тип акта'
            onSelect={handleChangeActType}
          >
            {actTypes.map(act => (
              <Option key={act.id} value={act.id}>
                <div>{act.name}</div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='tags'
          label='Тэги шаблона'
          className='custom-margin'
        >
          <Col>
            {state.templateTags?.map(tag => (
              <Tag key={tag.name} color={selectedTags.includes(tag.name) ? '#19bb4f' : ''}>
                <a onClick={() => handleTagClick(tag)}>{tag.name} - {tag.example}</a>
              </Tag>
            ))}
          </Col>
        </Form.Item>
        <Form.Item
          required
          label={
            <Col>
              <Row>Шаблон</Row>
              <Row>
                <Text italic type='secondary'>
                  Для вставки тэга в шаблон поставьте курсор в нужном месте шаблона и нажмите на тэг из списка выше,
                  или введите @ и начните вводить имя тэга
                </Text>
              </Row>
            </Col>
          }
        >
          {state.insertedTags.length > 0 && (
            <Col>
              <Spin spinning={isLoadingActByType}>
                <Tags
                  className='tags-textarea'
                  InputMode='textarea'
                  settings={tagsSettings(state.insertedTags)}
                  tagifyRef={tagifyRef}
                  onChange={onChange}
                />
              </Spin>
            </Col>
          )}
        </Form.Item>
        <Button onClick={handleOpenViewModal}>
          Предпросмотр
        </Button>
      </Form>
      <Modal
        width={850}
        style={{ top: 40 }}
        visible={isViewModalOpen}
        title='Пример шаблона акта'
        onCancel={handleCloseViewModal}
        okText='Ок'
        okButtonProps={okButtonProps}
        cancelButtonProps={{ className: 'hide' }}
        onOk={handleCloseViewModal}
        destroyOnClose
      >
        <Paragraph className='document'>{templatesContent?.preview}</Paragraph>
      </Modal>
    </Modal>
  )
}
export default NewActTemplate
