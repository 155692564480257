import React, { useReducer, useEffect } from 'react'
import { Button, Modal, Input, Form, message } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { simpleReducer } from '../../../../helper'
import { useMutateUpdateUuid } from '../../../../api/react-query/adminPartners'

const initialState = {
  isOpenModal: false,
  uuid: null,
  modalError: null
}

function UuidEditButton ({ profile, partnerUuid }) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    mutate: updateUuid,
    data: dataUpdate,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    isError: isErrorUpdate
  } = useMutateUpdateUuid()

  useEffect(() => {
    if (isSuccessUpdate && dataUpdate?.isSuccess) {
      message.success('Данные о партнере успешно сохранены')
      setState(initialState)
    } else if (!dataUpdate?.isSuccess && dataUpdate?.errorMessage) {
      setState({ modalError: dataUpdate.errorMessage })
    } else if (isErrorUpdate && dataUpdate?.errorMessage) {
      message.error(dataUpdate.errorMessage || 'Ошибка сохранения данных партнера')
    }
  }, [dataUpdate, isSuccessUpdate, isErrorUpdate])

  useEffect(() => {
    setState({
      uuid: partnerUuid
    })
  }, [partnerUuid, state.isOpenModal])

  const handleOpen = () => {
    setState({ isOpenModal: true })
  }

  const handleClose = () => {
    setState({
      ...initialState,
      uuid: state.uuid
    })
  }

  const handleChange = (e) => {
    setState({ uuid: e.target.value })
  }

  const handleSave = () => {
    if (!state.uuid) {
      setState({ modalError: 'Необходимо указать partner_uuid' })
      return
    }
    if (state.uuid === partnerUuid) {
      setState({ modalError: 'Необходимо указать новый partner_uuid' })
      return
    }

    updateUuid({
      phone: profile?.personalData?.mobilePhone,
      uuid: state.uuid
    })
  }

  return (
    <>
      <Button type='link' icon={<EditOutlined />} onClick={handleOpen} />
      <Modal
        title='Редактирование partner_uuid'
        visible={state.isOpenModal}
        onCancel={handleClose}
        onOk={handleSave}
        okText='Сохранить'
        okButtonProps={{ loading: isLoadingUpdate }}
        destroyOnClose
      >
        <Form.Item
          validateStatus={state.modalError && 'error'}
          help={state.modalError}
          required
        >
          <Input onChange={handleChange} value={state.uuid} status='error' />
        </Form.Item>

      </Modal>
    </>
  )
}

export default UuidEditButton
