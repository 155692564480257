import React from 'react'
import { isMobile } from '../../../helper'
import { Drawer, Modal } from 'antd'

function ModalDrawerPopup (props) {
  const {
    className = '',
    drawerClassName = '',
    modalClassName = '',
    isOpen,
    onClose = () => {},
    closeable = false,
    maskClosable = false,
    destroyOnClose = false,
    drawerHeight = 300
  } = props

  return (
    <>
      {isMobile()
        ? (
          <Drawer
            className={`mobileDrawerModal ${className} ${drawerClassName}`}
            visible={isOpen}
            onClose={onClose}
            destroyOnClose={destroyOnClose}
            closable={closeable}
            maskClosable={maskClosable}
            placement='bottom'
            height={drawerHeight}
          >
            {props.children}
          </Drawer>
        )
        : (
          <Modal
            className={`mobileDrawerModal ${className} ${modalClassName}`}
            visible={isOpen}
            onCancel={onClose}
            destroyOnClose={destroyOnClose}
            closable={closeable}
            maskClosable={maskClosable}
            footer={null}
          >
            {props.children}
          </Modal>
        )}
    </>
  )
}

export default ModalDrawerPopup
