import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Modal, Row, Col, message, Table } from 'antd'
import { useQueryAdminPartnerStatusHistory } from '../../../../api/react-query/adminPartners'
import { AppConfig } from '../../../../AppConfig'

function StatusHistory ({ partnerId, disabled }) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  const {
    data: statusHistoryData,
    isLoading: statusHistoryLoading,
    isError: statusHistoryError
  } = useQueryAdminPartnerStatusHistory(partnerId, {
    enabled: !!partnerId && isModalVisible
  })

  useEffect(() => {
    if (statusHistoryError || (statusHistoryData && !statusHistoryData.isSuccess)) {
      message.error('Ошибка получения статусов партнера')
    }
  }, [statusHistoryData, statusHistoryError])

  const columns = [
    {
      title: 'Дата и время',
      dataIndex: 'timestamp',
      render: timestamp => (timestamp ? moment(timestamp).format(AppConfig.formats.shortDateAndTime) : '')
    },
    {
      title: 'Измененный статус',
      dataIndex: 'statusType'
    },
    {
      title: 'Новое значение статуса',
      dataIndex: 'statusValue'
    },
    {
      title: 'Автор действия',
      dataIndex: 'actorName',
      render: actorName => actorName || 'Не указано'
    }
  ]

  return (
    <div>
      <Button
        disabled={disabled}
        onClick={showModal}
        type='primary'
      >
        История статусов
      </Button>
      <Modal
        width={1200}
        style={{ top: 20 }}
        title={
          <Row gutter={[12, 6]} align='middle'>
            <Col className='mr-3'><b>История статусов партнера</b></Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className='assetsModalForm'
      >
        <Table
          columns={columns}
          loading={statusHistoryLoading}
          dataSource={statusHistoryData?.history || []}
        />
      </Modal>
    </div>
  )
}

export default StatusHistory
