import React from 'react'
import { successScreen } from '../../../translates'
import { Typography } from 'antd'

const { Title } = Typography

function ExternalBlockedStep () {
  return (
    <div className='error-step'>
      <Title level={3}>{successScreen.externalBlocked} {successScreen.externalBlocked2}</Title>
    </div>
  )
}

export default ExternalBlockedStep
