import React, { useContext, useEffect, useReducer } from 'react'
import { ConfigContext } from '../../../context/configContext'
import { Button, message, Modal, Space, Spin, Radio, Row } from 'antd'
import { simpleReducer } from '../../../helper'
import {
  useMutateUserPriorityPartnerSelector,
  useMutateUserSetPriorityPartnerSelector
} from '../../../api/react-query/users'
import { AppConfig } from '../../../AppConfig'

const initialState = {
  isModalOpen: false,
  partnerTakePriority: null
}

function PartnerTakeNewPriorityButton () {
  const { isCoordinator, user } = useContext(ConfigContext)
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    mutate: getPriority,
    data: dataPriority,
    isSuccess: isSuccessPriority,
    isLoading: isLoadingPriority,
    isError: isErrorPriority
  } = useMutateUserPriorityPartnerSelector()

  useEffect(() => {
    if (isSuccessPriority && dataPriority?.isSuccess) {
      setState({ partnerTakePriority: dataPriority?.selector })
    } else if (isErrorPriority || dataPriority?.errorMessage) {
      message.error(dataPriority?.errorMessage || 'Ошибка получения данных приоритета анкеты')
    }
  }, [dataPriority, isErrorPriority, isSuccessPriority])

  const {
    mutate: setPriority,
    data: dataSetPriority,
    isSuccess: isSuccessSetPriority,
    isLoading: isLoadingSetPriority,
    isError: isErrorSetPriority
  } = useMutateUserSetPriorityPartnerSelector()

  useEffect(() => {
    if (isSuccessSetPriority && dataSetPriority?.isSuccess) {
      message.success('Приоритет анкеты сохранен')
    } else if (isErrorSetPriority || dataSetPriority?.errorMessage) {
      message.error(dataSetPriority?.errorMessage || 'Ошибка сохранения данных приоритета анкеты')
    }
  }, [dataSetPriority, isSuccessSetPriority, isErrorSetPriority])

  const handleClick = () => {
    getPriority()
    setState({ isModalOpen: true })
  }

  const handleClose = () => {
    setState({ isModalOpen: false })
  }

  const handleSave = () => {
    setPriority({
      userId: user?.id,
      selector: state.partnerTakePriority
    })
    setState({ isModalOpen: false })
  }

  const handleChange = (event) => {
    setState({ partnerTakePriority: event?.target?.value })
  }

  useEffect(() => {
    if (!state.isModalOpen) {
      setState(initialState)
    }
  }, [state.isModalOpen])

  if (!isCoordinator) {
    return null
  }

  return (
    <Space>
      <Button
        loading={isLoadingPriority}
        onClick={handleClick}
      >
        Задать приоритет анкет
      </Button>
      {
        state.isModalOpen && (
          <Modal
            visible={state.isModalOpen}
            onCancel={handleClose}
            onOk={handleSave}
            title='Задать приоритет выбора анкет'
            destroyOnClose
          >
            <Spin spinning={isLoadingPriority || isLoadingSetPriority}>
              <Radio.Group onChange={handleChange} value={state.partnerTakePriority}>
                <Row style={{ marginBottom: '0.5rem' }}><Radio value={AppConfig.coordinatorTakePriority.new}>Новая</Radio></Row>
                <Row style={{ marginBottom: '0.5rem' }}><Radio value={AppConfig.coordinatorTakePriority.old}>Старая</Radio></Row>
                <Row><Radio value={AppConfig.coordinatorTakePriority.bankRequisite}>Анкета БР</Radio></Row>
              </Radio.Group>
            </Spin>
          </Modal>
        )
      }
    </Space>
  )
}

export default PartnerTakeNewPriorityButton
