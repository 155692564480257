import React from 'react'
import { Form, Select } from 'antd'
import { profileScreen } from '../../../../translates'
import { trueFalseList } from '../../../../helper'

const { Option } = Select

function MedicalBookSelectFormItem ({
  onSelect,
  required = false
}) {
  return (
    <Form.Item
      name={['lmkDetails', profileScreen.input.hasMedicalBook.name]}
      label={profileScreen.input.hasMedicalBook.label}
      rules={[{ required, message: profileScreen.input.hasMedicalBook.message }]}
    >
      <Select
        size='large'
        placeholder={profileScreen.input.hasMedicalBook.placeholder}
        onSelect={onSelect}
      >
        {
          trueFalseList.map(c => <Option key={c.label} value={c.value}>{c.label}</Option>)
        }
      </Select>
    </Form.Item>
  )
}

export default MedicalBookSelectFormItem
