import { AppConfig } from '../AppConfig'

export const profileScreen = {
  title: 'Ваши данные',
  title2: 'Банковские данные',
  title3: 'Документы',
  titleMedical: 'Документы ЛМК',
  input: {
    legalForm: {
      name: 'legalForm',
      placeholder: 'Статус партнера',
      label: 'Тип занятости',
      message: 'Пожалуйста, укажите Ваш статус'
    },
    notFormerCivilEmployee: {
      placeholder: null,
      label: 'Подтвердите, что вы не являлись государственным служащим РФ',
      message: 'Пожалуйста, подтвердите, что вы не являлись государственным служащим РФ',
      hint: 'Под государственной службой понимается военная или гражданская служба в государственных органах (например, в министерствах, налоговой службе и т.д.)'
    },
    surname: {
      name: 'surname',
      placeholder: 'Иванов',
      label: 'Фамилия',
      message: 'Пожалуйста, укажите Вашу фамилию, используйте русские буквы и символы \'. -'
    },
    firstName: {
      name: 'firstName',
      placeholder: 'Иван',
      label: 'Имя',
      message: 'Пожалуйста, укажите Ваше имя, используйте русские буквы и символы \'. -'
    },
    middleName: {
      name: 'middleName',
      placeholder: 'Иванович',
      label: 'Отчество',
      without: 'Нет отчества',
      message: 'Пожалуйста, укажите Ваше отчество, используйте русские буквы и символы \'. -'
    },
    mobilePhone: {
      name: 'mobilePhone',
      placeholder: '+7xxxxxxxxxx',
      label: 'Телефон',
      message: 'Пожалуйста, укажите телефон в формате +70001234567'
    },
    email: {
      name: 'email',
      placeholder: 'email@email.ru',
      label: 'Email',
      message: 'Пожалуйста, укажите email в формате email@email.ru'
    },
    role: {
      placeholder: 'Вид услуги',
      label: 'Услуги',
      message: 'Пожалуйста, укажите вид услуг'
    },
    citizenship: {
      placeholder: 'Россия',
      label: 'Гражданство',
      message: 'Пожалуйста, выберите гражданство',
      placeholderOnPolicy: 'Укажите гражданство'
    },
    birthDate: {
      name: 'birthDate',
      placeholder: '25.12.1990',
      label: 'Дата рождения',
      message: 'Введите дату в формате дд.мм.гггг',
      yearStart: 16,
      yearEnd: 100,
      messageYear: 'Возраст должен быть в пределах 16-100 лет'
    },
    city: {
      placeholder: 'Москва',
      label: 'Город оказания услуг',
      message: 'Пожалуйста, укажите город'
    },
    storeChain: {
      placeholder: 'Торговая сеть',
      label: 'Торговая сеть',
      message: 'Пожалуйста, укажите торговую сеть'
    },
    store: {
      name: 'store',
      placeholder: 'Магазин',
      label: 'Магазин',
      message: 'Пожалуйста, укажите магазин'
    },
    briefingDate: {
      name: 'briefingDate',
      placeholder: '25.12.1990',
      label: 'Дата прохождения инструктажа',
      message: 'Пожалуйста, укажите дату прохождения инструктажа'
    },
    clothingSize: {
      placeholder: 'Размер одежды',
      label: 'Размер одежды',
      message: 'Пожалуйста, укажите размер одежды'
    },
    hasMedicalBook: {
      name: 'hasMedicalBook',
      placeholder: 'Медкнижка',
      label: 'Есть ли у вас медкнижка',
      message: 'Пожалуйста, укажите есть ли у вас медкнижка'
    },
    medicalBookNumber: {
      name: 'medicalBookNumber',
      placeholder: 'Номер медкнижки',
      label: 'Номер медкнижки',
      message: 'Пожалуйста, укажите номер медкнижки'
    },
    medicalexaminationDate: {
      name: 'examinationDate',
      placeholder: '25.12.1990',
      label: 'Дата прохождения медкомиссии',
      message: 'Введите дату в формате дд.мм.гггг',
      yearExpire: 1
    },
    medicalattestationDate: {
      name: 'attestationDate',
      placeholder: '25.12.1990',
      label: 'Дата прохождения аттестации',
      message: 'Введите дату в формате дд.мм.гггг',
      yearExpire: 2,
      hint: 'Аттестация ЛМК — это контроль санитарно-гигиенических знаний (сдача письменного, в форме тестирования, экзамена) по результатам профессиональной гигиенической подготовки, а также проверку полноты необходимого объема медицинских осмотров.'
    },
    vaccinated: {
      name: 'vaccinated',
      placeholder: 'Вы вакцинированы?',
      label: 'Вы вакцинированы?',
      message: 'Пожалуйста, укажите вакцинированы ли вы'
    },
    isForeignVehicleNumber: {
      name: 'isForeignVehicleNumber',
      placeholder: '',
      label: 'Другой формат',
      message: ''
    },
    carLicencePlate: {
      name: 'carLicencePlate',
      placeholder: 'А 000 АА 000',
      label: 'Госномер авто',
      message: 'Пожалуйста, укажите госномер авто',
      messageValidation: 'Введен некорректный номер Транспортного средства',
      messageAttention: 'Проверьте внимательно указанный номер'
    },
    passportseries: {
      name: 'series',
      placeholder: '1234',
      label: 'Серия паспорта',
      message: 'Пожалуйста, укажите серию паспорта из 4 цифр'
    },
    passportnumber: {
      name: 'number',
      placeholder: '123456',
      label: 'Номер паспорта',
      message: 'Пожалуйста, укажите номер паспорта из 6 цифр'
    },
    passportissuerName: {
      name: 'issuerName',
      placeholder: 'МВД Даниловского района',
      label: 'Кем выдан',
      message: 'Пожалуйста, укажите кем выдан паспорт, используйте русские буквы и символы \'., - №'
    },
    passportissuerCode: {
      name: 'issuerCode',
      placeholder: '123-456',
      label: 'Код подразделения',
      message: 'Пожалуйста, укажите код подразделения в формате 123-456'
    },
    passportissueDate: {
      name: 'issueDate',
      placeholder: '25.12.1990',
      label: 'Дата выдачи',
      message: 'Введите дату в формате дд.мм.гггг',
      yearExpire: 100,
      messageYear: 'Дата выдачи паспорта более 100 лет назад'
    },
    placeOfBirth: {
      placeholder: 'гор. Таганрог, Ростовской обл.',
      label: 'Место рождения',
      message: 'Пожалуйста, укажите место рождения, используйте русские буквы и символы \'. -'
    },
    inn: {
      name: 'inn',
      placeholder: '401325754326',
      label: 'ИНН',
      message: 'Введите 12 цифр вашего ИНН'
    },
    bankAccount: {
      name: 'bankAccount',
      placeholder: '40817810099910004312',
      label: 'Номер расчетного счета',
      message: 'Введите номер расчетного счета, состоящий из 20 цифр и начинающийся с “408”.'
    },
    bankBik: {
      name: 'bankBik',
      placeholder: '044540132',
      label: 'БИК',
      message: 'Введите БИК вашего банка, состоящий из 9 цифр и начинающийся с “04”.'
    },
    bankName: {
      name: 'bankName',
      placeholder: 'Сбербанк',
      label: 'Название банка',
      message: 'Пожалуйста, укажите название банка, используйте русские/английские буквы и символы \'., - №'
    },
    name: {
      name: 'name',
      placeholder: 'ФИО Банковских реквизитов',
      label: 'ФИО Банковских реквизитов',
      message: 'Пожалуйста, укажите ФИО Банковских реквизитов'
    },
    requisitesbankAccount: {
      name: 'bankAccount',
      placeholder: '40817810099910004312',
      label: 'Номер расчетного счета',
      message: 'Введите номер расчетного счета, состоящий из 20 цифр и начинающийся с “408”.'
    },
    requisitesbankBik: {
      name: 'bankBik',
      placeholder: '044540132',
      label: 'БИК',
      message: 'Введите БИК вашего банка, состоящий из 9 цифр и начинающийся с “04”.'
    },
    requisitesbankName: {
      name: 'bankName',
      placeholder: 'Сбербанк',
      label: 'Название банка',
      message: 'Пожалуйста, укажите название банка, используйте русские/английские буквы и символы \'., - №'
    },
    requisitesname: {
      name: 'name',
      placeholder: 'ФИО Банковских реквизитов',
      label: 'ФИО Банковских реквизитов',
      message: 'Пожалуйста, укажите ФИО Банковских реквизитов'
    },
    requisitesfirstName: {
      name: 'firstName',
      placeholder: 'Имя',
      label: 'Имя',
      message: 'Пожалуйста, укажите Имя для Банковских реквизитов'
    },
    requisiteslastName: {
      name: 'lastName',
      placeholder: 'Фамилия',
      label: 'Фамилия',
      message: 'Пожалуйста, укажите Фамилию для Банковских реквизитов'
    },
    requisitespatronymic: {
      name: 'patronymic',
      placeholder: 'Отчество',
      label: 'Отчество',
      message: 'Пожалуйста, укажите Отчество для Банковских реквизитов'
    },
    passportPhoto1: {
      name: 'passportPhoto1',
      placeholder: 'Загрузить документ',
      label: 'Первый разворот паспорта',
      message: 'Пожалуйста, загрузите первый разворот паспорта'
    },
    passportPhoto2: {
      name: 'passportPhoto2',
      placeholder: 'Загрузить документ',
      label: 'Разворот паспорта с пропиской',
      message: 'Пожалуйста, загрузите разворот паспорта с пропиской'
    },
    passportPhoto3: {
      name: 'passportPhoto3',
      placeholder: 'Загрузить документ',
      label: 'Селфи с паспортом',
      message: 'Пожалуйста, загрузите селфи с паспортом'
    },
    passportPhotoTmpReg: {
      name: 'passportPhotoTmpReg',
      placeholder: 'Загрузить документ',
      label: 'Справка о временной регистрации',
      message: 'Пожалуйста, загрузите справку о временной регистрации'
    },
    passportPhotoExtraReg: {
      name: 'passportPhotoExtraReg',
      placeholder: 'Загрузить документ',
      label: 'Дополнительный разворот с пропиской (даже пустой)',
      message: 'Пожалуйста, загрузите дополнительный разворот с пропиской (даже пустой)'
    },
    innPhoto: {
      name: 'innPhoto',
      placeholder: 'Загрузить документ',
      label: 'ИНН',
      labelFull: 'Фото, скриншот или PDF',
      message: 'Пожалуйста, загрузите ИНН'
    },
    mnregPhoto: {
      name: 'mnregPhoto',
      placeholder: 'Загрузить документ',
      label: 'Подтверждение регистрации в приложении “мой налог”',
      message: 'Пожалуйста, загрузите подтверждение регистрации в приложении “мой налог”'
    },
    egripPhoto: {
      name: 'egripPhoto',
      placeholder: 'Загрузить документ',
      label: 'Выписка из ЕГРИП',
      message: 'Пожалуйста, загрузите выписку из ЕГРИП'
    },
    bankDetailsPhoto: {
      name: 'bankDetailsPhoto',
      placeholder: 'Загрузить документ',
      label: 'Банковские реквизиты',
      message: 'Пожалуйста, загрузите банковские реквизиты'
    },
    requisitesBankPhoto: {
      name: 'bankRequisitesPhoto',
      placeholder: 'Загрузить документ',
      label: 'Банковские реквизиты',
      message: 'Пожалуйста, загрузите банковские реквизиты'
    },
    trusteeAgreement: {
      name: 'trusteeAgreement',
      placeholder: 'Загрузить документ',
      label: 'Согласие на оказание услуг несовершеннолетним',
      message: 'Пожалуйста, загрузите согласие на оказание услуг несовершеннолетним'
    },
    trusteePassport: {
      name: 'trusteePassport',
      placeholder: 'Загрузить документ',
      label: 'Фотография главного разворота паспорта законного представителя',
      message: 'Пожалуйста, загрузите фотографию главного разворота паспорта законного представителя'
    },
    vaccinacionPhoto: {
      name: 'vaccinacionPhoto',
      placeholder: 'Загрузить документ',
      label: 'Справка о вакцинации',
      message: 'Пожалуйста, загрузите справку о вакцинации'
    },
    lmkMainPhoto: {
      name: 'lmkMainPhoto',
      placeholder: 'Загрузить документ',
      label: 'Фото главного разворота ЛМК',
      message: 'Пожалуйста, загрузите фото личной медицинской книжки',
      hint: 'Фото страницы 2-3, Ф.И.О. и номер ЛМК должны хорошо читаться, ваша фотография должна быть узнаваема'
    },
    lmkAllowancePhoto: {
      name: 'lmkAllowancePhoto',
      placeholder: 'Загрузить документ',
      label: 'Страница с допуском к работе',
      message: 'Пожалуйста, загрузите фото страницы с допуском',
      hint: 'Фото страницы 7,8 или 9 с печатью допуска от терапевта или профнатолога с актуальной датой'
    },
    lmkAttestationPhoto: {
      name: 'lmkAttestationPhoto',
      placeholder: 'Загрузить документ',
      label: 'Страница с голограммой аттестации',
      message: 'Пожалуйста, загрузите фото страницы с голограммой аттестации',
      hint: 'Фото 28 или 29 страницы с голограммой и актуальной датой прохождения санминимума'
    },
    withoutMiddleName: {
      name: 'withoutMiddleName'
    },
    registrationKind: {
      name: 'registrationKind',
      label: 'Регистрация',
      placeholder: 'Укажите регистрацию',
      message: 'Пожалуйста, укажите регистрацию',
      blockTitles: {
        [AppConfig.registrationTypes.unregistered]: 'Фактический адрес проживания',
        [AppConfig.registrationTypes.temporary]: 'Адрес временной регистрации',
        [AppConfig.registrationTypes.permanent]: 'Адрес постоянной регистрации'
      }
    },
    registrationAddresszipCode: {
      name: 'zipCode',
      placeholder: '349000',
      label: 'Индекс',
      message: 'Пожалуйста, укажите индекс из 6 цифр'
    },
    registrationAddresscountry: {
      name: 'country',
      placeholder: 'Россия',
      label: 'Страна',
      message: 'Пожалуйста, укажите страну, используйте русские буквы и символы \'. -'
    },
    registrationAddressregion: {
      name: 'region',
      placeholder: 'Московская обл.',
      label: 'Регион',
      message: 'Пожалуйста, укажите регион, используйте русские буквы, цифры и символы \'. -'
    },
    registrationAddressdistrict: {
      name: 'district',
      placeholder: 'р-н Ивановский',
      label: 'Район',
      message: 'Пожалуйста, укажите район, используйте русские буквы, цифры и символы \'. -'
    },
    registrationAddresscity: {
      name: 'city',
      placeholder: 'Москва',
      label: 'Город',
      message: 'Пожалуйста, укажите город, используйте русские буквы, цифры и символы \'. -'
    },
    registrationAddresssettlement: {
      name: 'settlement',
      placeholder: 'с. Видное',
      label: 'Населенный пункт',
      message: 'Пожалуйста, укажите населенный пункт, используйте русские буквы, цифры и символы \'. -'
    },
    registrationAddressstreet: {
      name: 'street',
      placeholder: 'ул. Ленина',
      label: 'Улица',
      message: 'Пожалуйста, укажите улицу, используйте русские буквы, цифры и символы \'. -'
    },
    registrationAddresshouse: {
      name: 'house',
      placeholder: '53',
      label: 'Дом',
      message: 'Пожалуйста, укажите номер дома, используйте русские буквы, цифры и символы \'. -'
    },
    registrationAddressbuilding: {
      name: 'building',
      placeholder: 'корпус А',
      label: 'Строение',
      message: 'Пожалуйста, укажите строение, используйте русские буквы, цифры и символы \'. -'
    },
    registrationAddressapartment: {
      name: 'apartment',
      placeholder: 'кв. 5',
      label: 'Квартира',
      message: 'Пожалуйста, укажите номер квартиры, используйте русские буквы, цифры и символы \'. -'
    },
    isLegalAgeCapable: {
      name: 'isLegalAgeCapable',
      placeholder: '',
      label: 'У меня полная дееспособность',
      message: 'Пожалуйста, подтвердите полную дееспособность',
      hint: 'Таким документом может быть свидетельство о браке или решение об объявлении несовершеннолетнего полностью дееспособным',
      hintV2: 'Наступает по достижении совершеннолетия или при регистрации брака, трудового договора, предпринимательской деятельности'
    },
    trusteeConfirmation: {
      name: 'trusteeConfirmation',
      placeholder: 'Загрузить документ',
      label: 'Документ подтверждающий законное представительство',
      message: 'Пожалуйста, загрузите документ подтверждающий законное представительство',
      hint: 'Таким документом может быть свидетельство о рождении, свидетельство об усыновлении/удочерении, акт об опекунстве, стр. 16-17 паспорта законного представителя, если там есть сведения о детях'
    },
    legalCapacityConfirmation: {
      name: 'legalCapacityConfirmation',
      placeholder: 'Загрузить документ',
      label: 'Подтверждение дееспособности',
      message: 'Пожалуйста, загрузите документ подтверждение дееспособности',
      hint: 'Таким документом может быть свидетельство о браке или решение об объявлении несовершеннолетнего полностью дееспособным'
    }
  },
  button: {
    submit: 'Далее',
    continueLater: 'Продолжить позже',
    send: 'Отправить на проверку',
    continue: 'Продолжить'
  },
  messages: {
    draftSaved: 'Черновик Вашего профиля успешно сохранен.',
    profileSaved: 'Ваш профиль успешно сохранен.'
  },
  draft: 'Черновик'
}
