import React, { useEffect, useMemo, useState } from 'react'
import { Button, Input, Divider, Typography, Radio, Space, AutoComplete, message, Select } from 'antd'
import {
  ACCEPT,
  ACCEPTED,
  DECLINE,
  DECLINED,
  NONE,
  ACCEPT_CONDITION,
  NOT_FILLED,
  FILLED_IN_SHOPPER,
  FILLED_IN_TINKOFF
} from '../../../Constants'
import { useDeclineReasonsQuery, usePartnerCardFormConditionsQuery } from '../../../api/react-query/dict'
import ReasonSelectorWithAdditional from './ReasonSelectorWithAdditional'
import { AppConfig, innDetailsStatusCodes } from '../../../AppConfig'

const { Text } = Typography
const radioStyle = { height: '30px', lineHeight: '30px' }

function Approval ({
  onCommit,
  onCancel,
  loading,
  defaultOutcome,
  defaultComment,
  multiline = false,
  multilineRows = 4,
  disabled = false,
  autocomplete = false,
  isReasonWithAdditional = false,
  targetType = null,
  isAbleCondition = false
}) {
  const lastApprovalAction = useMemo(() => {
    if (defaultOutcome === ACCEPTED) return ACCEPT
    if (defaultOutcome === DECLINED) return DECLINE
    return NONE
  }, [defaultOutcome])

  const [outcome, setOutcome] = useState(lastApprovalAction ?? NONE)
  const [comment, setComment] = useState(defaultComment)
  const [additionalComment, setAdditionalComment] = useState(null)
  const [commentOptions, setCommentOptions] = useState([])
  const [descriptionOptions, setDescriptionOptions] = useState([])
  const [conditionOptions, setConditionOptions] = useState([])
  const [condition, setCondition] = useState(null)

  const {
    data: dataDeclineReasons,
    isSuccess: isSuccessReasons
  } = useDeclineReasonsQuery(targetType, {
    enabled: Boolean(targetType && (autocomplete || isReasonWithAdditional))
  })

  useEffect(() => {
    if (isSuccessReasons && dataDeclineReasons?.isSuccess) {
      setCommentOptions(dataDeclineReasons.result.map(item => ({ label: item.reason, value: item.reason })))
      setDescriptionOptions(dataDeclineReasons.result.map(item => ({ reason: item.reason, description: item.description })))
    }
  }, [dataDeclineReasons, isSuccessReasons])

  const {
    data: dataConditions,
    isSuccess: isSuccessConditions,
    isError: isErrorConditions
  } = usePartnerCardFormConditionsQuery({
    enabled: !!isAbleCondition
  })

  useEffect(() => {
    if (isSuccessConditions && dataConditions?.isSuccess) {
      setConditionOptions(dataConditions.result.map(item => ({ label: item, value: item })))
    } else if (isErrorConditions || dataConditions?.errorMessage) {
      message.error('Ошибка получения условий частичного принятия')
    }
  }, [dataConditions, isSuccessConditions, isErrorConditions])

  const handleOKClick = () => {
    if (onCommit) {
      onCommit({ outcome, comment, condition, additionalComment })
    }
  }

  const clear = () => {
    setOutcome(NONE)
    setComment(null)
    setCondition(null)
    setAdditionalComment(null)
    if (onCancel) {
      onCancel()
    }
  }

  const handleChangeCondition = (value) => {
    setCondition(value)
  }

  const handleChangeOutcome = e => {
    setOutcome(e.target.value)
    setCondition(null)
  }

  const canSubmit = (
    outcome === ACCEPT ||
    (outcome === DECLINE && (
      targetType === AppConfig.reasonTargets.requisitesDetails
        ? !!comment && !!additionalComment
        : !!comment
    )) ||
    (outcome === ACCEPT_CONDITION && !!condition)
  ) && !disabled

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <div>
        <Radio.Group
          defaultValue={lastApprovalAction}
          onChange={handleChangeOutcome}
          value={outcome}
        >
          <Space direction='vertical'>
            <Radio style={radioStyle} value={ACCEPT}>
              Принять
            </Radio>
            {isAbleCondition && (
              <Radio style={radioStyle} value={ACCEPT_CONDITION}>
                Принять с условием
                {outcome === ACCEPT_CONDITION && (
                  <Select
                    style={{ width: 400, marginLeft: 30 }}
                    onChange={handleChangeCondition}
                    options={conditionOptions}
                    onClick={(e) => e.preventDefault()}
                  />
                )}
              </Radio>
            )}
            <Radio style={radioStyle} value={DECLINE}>
              Требуются изменения
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      {outcome === DECLINE &&
        <div>
          {
            autocomplete
              ? (
                <AutoComplete
                  options={commentOptions}
                  style={{ width: '100%' }}
                  onSelect={(item) => {
                    setComment((prevComment) => prevComment ? `${prevComment} ${item}` : item)
                  }}
                  allowClear
                  onClear={() => setComment(null)}
                  defaultValue={comment}
                  value={comment}
                >
                  {
                    multiline
                      ? <Input.TextArea rows={multilineRows} defaultValue={comment} value={comment} onChange={e => { setComment(e.target.value) }} placeholder='Введите причину' />
                      : <Input defaultValue={comment} value={comment} onChange={e => { setComment(e.target.value) }} placeholder='Введите причину' />
                  }
                </AutoComplete>

              )
              : isReasonWithAdditional
                ? (
                  <ReasonSelectorWithAdditional
                    multilineRows={multilineRows}
                    multiline={multiline}
                    options={commentOptions}
                    descriptions={descriptionOptions}
                    onSelect={item => setComment(item)}
                    onInput={value => setAdditionalComment(value)}
                  />
                )
                : (
                  <>
                    {
                      multiline
                        ? <Input.TextArea rows={multilineRows} defaultValue={comment} value={comment} onChange={e => { setComment(e.target.value) }} placeholder='Введите причину' />
                        : <Input defaultValue={comment} value={comment} onChange={e => { setComment(e.target.value) }} placeholder='Введите причину' />
                    }
                  </>
                )
          }
        </div>}
      <div>
        <Button type='primary' disabled={!canSubmit} onClick={handleOKClick} loading={loading}>Сохранить</Button>
        <Divider type='vertical' />
        <Button type='default' onClick={clear}>Отмена</Button>
      </div>
    </Space>
  )
}

const Approved = () => <Text type='success'>Одобрен</Text>
const Rejected = () => <Text type='danger'>Отклонен</Text>
const Pending = () => <Text>Ожидает проверку</Text>
const NotFilled = () => <Text>Не заполнялись</Text>
const FilledInShopper = () => <Text>Заполнено в Shopper App</Text>
const FilledInTinkoffId = () => <Text>Заполнено в TinkoffId</Text>
const StatusSelfFee = ({ status, type }) => <Text type={type}>{status}</Text>

export const ApprovalStatus = ({ status, forceStatus }) => {
  if (forceStatus) {
    return (
      <StatusSelfFee
        status={forceStatus}
        type={status === innDetailsStatusCodes.accepted ? 'success' : null}
      />
    )
  }
  if (status === ACCEPTED) return <Approved />
  if (status === DECLINED) return <Rejected />
  if (status === NOT_FILLED) return <NotFilled />
  if (status === FILLED_IN_SHOPPER) return <FilledInShopper />
  if (status === FILLED_IN_TINKOFF) return <FilledInTinkoffId />
  return <Pending />
}

export default Approval
