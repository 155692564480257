import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function PassportPhoto2 (props) {
  return (
    <>
      <Title level={3}>Разворот с пропиской</Title>
      <Text>Убедитесь, что печать прописки или регистрации хорошо видны</Text>
      <br />
      <img src='/img/photo-doc/passport-doc-2.png' className='img-doc' />
    </>
  )
}

export default PassportPhoto2
