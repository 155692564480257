import { Form, Tooltip } from 'antd'
import React from 'react'
import { MaskedInput } from 'antd-masked-input'
import { errors, profileScreen } from '../../../../translates'
import { validateDate, validateDate1BeforeDate2, validateDateNotFuture } from '../../../../helper'
import moment from 'moment-timezone'
import { AppConfig } from '../../../../AppConfig'
import { QuestionCircleOutlined } from '@ant-design/icons'

const MedicalAttestationDateFormItem = ({
  label = profileScreen.input.medicalattestationDate.label,
  rules = undefined,
  required = false,
  messageRule = profileScreen.input.medicalattestationDate.message,
  size = 'large',
  placeholder = profileScreen.input.medicalattestationDate.placeholder
}) => {
  return (
    <Form.Item
      name={['lmkDetails', profileScreen.input.medicalattestationDate.name]}
      label={
        <>
          {label}
          <Tooltip placement='top' title={profileScreen.input.medicalattestationDate.hint} trigger='click' color='white' overlayInnerStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}>
            <QuestionCircleOutlined className='ml-2 mr-3' />
          </Tooltip>
        </>
      }
      rules={rules ?? [
        { required: required, message: messageRule, validator: validateDate },
        {
          message: errors.oldDate,
          validator: (rule, value) => validateDate1BeforeDate2({
            rule,
            date1: moment().subtract(profileScreen.input.medicalattestationDate.yearExpire, 'year').format(AppConfig.formats.shortDate),
            date2: value
          })
        },
        {
          message: errors.futureDate,
          validator: (rule, value) => validateDateNotFuture({ rule, date: value })
        }
      ]}
    >
      <MaskedInput
        size={size}
        placeholder={placeholder}
        mask='11.11.1111'
      />
    </Form.Item>
  )
}
export default MedicalAttestationDateFormItem
