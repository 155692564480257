import React, { useMemo } from 'react'
import InnFormItem from '../../Common/FormItems/InnFormItem'
import { errors, profileScreen } from '../../../translates'
import { validateFullInn, validateInn } from '../../../helper'
import { useStores } from '../../../Store/Store'
import LegalFormItem from '../../Common/FormItems/LegalFormItem/LegalFormItem'
import ModalMyTaxApp from './ModalMyTaxApp/ModalMyTaxApp'
import { AppConfig } from '../../../AppConfig'
import { flowV2Steps } from './ProfileV2'
import CorrectInfo from './ErrorStep/CorrectInfo'

function InnStep ({ form }) {
  const {
    partnerProfile: {
      profile
    } = {}
  } = useStores()

  const isDisabledInn = useMemo(() => {
    return profile?.innDetails?.inn
  }, [profile?.innDetails?.inn])

  const isShowLegalFormItem = useMemo(() => {
    return [
      AppConfig.smzStatusCodes.bindingConfirmed,
      AppConfig.smzStatusCodes.error,
      AppConfig.smzStatusCodes.statusConfirmation
    ].includes(profile?.smz?.statusCode)
  }, [profile?.smz?.statusCode])

  const isDisabledLegalForm = useMemo(() => {
    return profile?.personalData?.legalForm ||
      (profile?.smz?.statusCode === AppConfig.smzStatusCodes.error && !profile?.personalData?.legalForm)
  }, [profile?.personalData?.legalForm, profile?.smz?.statusCode])

  return (
    <>
      <CorrectInfo step={flowV2Steps.innStep} />
      <InnFormItem
        label={profileScreen.input.inn.label}
        placeholder={profileScreen.input.inn.placeholder}
        rules={[
          { required: profile?.personalData?.required, message: profileScreen.input.inn.message, validator: validateInn },
          { message: errors.innCheckSum, validator: (rule, value) => validateFullInn({ rule, inn: value }) }
        ]}
        classNameInput='noScrollNumber'
        disabled={isDisabledInn}
      />

      {isShowLegalFormItem && (
        <LegalFormItem form={form} profile={profile} disabled={isDisabledLegalForm} />
      )}

      <ModalMyTaxApp />
    </>
  )
}

export default InnStep
