import React, { useEffect, useReducer } from 'react'
import { AutoComplete, Button, Input, message, Typography, Modal, Spin } from 'antd'
import {
  useMutateSEVerificationAllow,
  useMutateSEVerificationDeny,
  useMutateSEVerificationRetry
} from '../../../../api/react-query/adminPartners'
import { AppConfig, seVerificationStatusCodes } from '../../../../AppConfig'
import { useDeclineReasonsQuery } from '../../../../api/react-query/dict'
import { simpleReducer } from '../../../../helper'

const initialState = {
  declineReasons: [],
  comment: null,
  errorComment: false,
  isModalOpen: false
}

const { Text } = Typography

function SEButtons ({ partnerId, verification }) {
  const [state, setState] = useReducer(simpleReducer, initialState)
  const {
    mutate: retry, isLoading: isLoadingRetry, data: dataRetry, isError: isErrorRetry
  } = useMutateSEVerificationRetry()
  const {
    mutate: allow, isLoading: isLoadingAllow, data: dataAllow, isError: isErrorAllow, isSuccess: isSuccessAllow
  } = useMutateSEVerificationAllow()
  const {
    mutate: deny, isLoading: isLoadingDeny, data: dataDeny, isError: isErrorDeny
  } = useMutateSEVerificationDeny()

  const {
    data: dataDeclineReasons,
    isSuccess: isSuccessReasons
  } = useDeclineReasonsQuery(AppConfig.reasonTargets.seVerification, {
    enabled: verification.statusCode === seVerificationStatusCodes.denied
  })

  useEffect(() => {
    if (isSuccessReasons && dataDeclineReasons?.isSuccess) {
      setState({
        declineReasons: dataDeclineReasons.result.map(item => ({ label: item.reason, value: item.reason }))
      })
    }
  }, [dataDeclineReasons, isSuccessReasons])

  useEffect(() => {
    if (isErrorRetry || dataRetry?.errorMessage) {
      message.error(dataRetry?.errorMessage || 'Ошибка повторной проверки')
    }
  }, [isErrorRetry, dataRetry])

  useEffect(() => {
    if (isSuccessAllow) {
      setState({ isModalOpen: false })
    }
    if (isErrorAllow || dataAllow?.errorMessage) {
      message.error(dataAllow?.errorMessage || 'Ошибка установки статуса')
    }
  }, [isErrorAllow, dataAllow, isSuccessAllow])

  useEffect(() => {
    if (isErrorDeny || dataDeny?.errorMessage) {
      message.error(dataDeny?.errorMessage || 'Ошибка установки статуса')
    }
  }, [isErrorDeny, dataDeny])

  const handleAutoCommentChange = (item) => {
    setState({
      comment: state.comment ? `${state.comment} ${item}` : item
    })
  }
  const handleAutoCommentClear = () => setState({ comment: null })

  const handleCommentChange = e => setState({
    comment: e.target.value,
    errorComment: false
  })

  const handleClose = () => {
    setState({ isModalOpen: false })
  }

  const handleAllow = () => {
    if (verification.statusCode === seVerificationStatusCodes.denied) {
      setState({ isModalOpen: true })
    } else {
      allow({ id: partnerId })
    }
  }

  const handleSave = () => {
    setState({ errorComment: false })
    if (!state.comment && verification.statusCode === seVerificationStatusCodes.denied) {
      setState({ errorComment: true })
      return
    }
    allow({
      id: partnerId,
      data: { reason: state.comment }
    })
  }

  useEffect(() => {
    setState({
      errorComment: false,
      comment: null
    })
  }, [state.isModalOpen])

  return (
    <div>
      {
        verification.allowRetry &&
          <Button type='primary' className='mr-3' onClick={() => retry(partnerId)} loading={isLoadingRetry}>Повторить</Button>
      }
      {
        verification.allowVerify &&
          <Button type='primary' className='mr-3 ant-btn-success' onClick={handleAllow} loading={isLoadingAllow}>Проверено</Button>
      }
      {
        verification.allowDeny &&
          <Button type='primary' onClick={() => deny(partnerId)} loading={isLoadingDeny} danger>Отказано</Button>
      }
      <Modal
        visible={state.isModalOpen}
        onCancel={handleClose}
        onOk={handleSave}
        okText='Сохранить'
        cancelText='Отмена'
        destroyOnClose
      >
        <Spin spinning={isLoadingAllow}>
          <Text>Укажите причину для принятия проверки 1С</Text>
          <br /><br />
          <AutoComplete
            options={state.declineReasons}
            style={{ width: '100%' }}
            onSelect={handleAutoCommentChange}
            allowClear
            onClear={handleAutoCommentClear}
            defaultValue={state.comment}
            value={state.comment}
            status='error'
          >
            <Input
              style={{ width: '100%', ...(state.errorComment && { borderColor: '#ff4d4f' }) }}
              defaultValue={state.comment}
              value={state.comment}
              onChange={handleCommentChange}
              placeholder='Введите причину'
            />
          </AutoComplete>
          {
            state.errorComment && (
              <div style={{ width: '100%' }}>
                <Text type='danger'>Введите причину</Text>
              </div>
            )
          }
        </Spin>
      </Modal>
    </div>
  )
}
export default SEButtons
