import { decorate, observable } from 'mobx'

class PartnerList {
  result = []
  pageSize = 20
  pageNumber = 1
  resultsTotal = 0
  isSuccess = true
  errorMessage = ''
}

export default decorate(PartnerList,
  {
    result: observable,
    pageSize: observable,
    pageNumber: observable,
    resultsTotal: observable,
    isSuccess: observable,
    errorMessage: observable
  })
