/* eslint-disable */
'use strict'

function _typeof (obj) { '@babel/helpers - typeof'; return _typeof = typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol' ? function (obj) { return typeof obj } : function (obj) { return obj && typeof Symbol === 'function' && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj }, _typeof(obj) }

Object.defineProperty(exports, '__esModule', {
  value: true
})
exports.AuthenticationContext = void 0
exports.adalFetch = adalFetch
exports.adalGetToken = adalGetToken
exports.runWithAdal = runWithAdal
exports.withAdalLogin = void 0

var _react = _interopRequireDefault(require('react'))

var _adal = _interopRequireDefault(require('./adal.mod'))

function _interopRequireDefault (obj) { return obj && obj.__esModule ? obj : { default: obj } }

function _classCallCheck (instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function') } }

function _defineProperties (target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor) } }

function _createClass (Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); Object.defineProperty(Constructor, 'prototype', { writable: false }); return Constructor }

function _inherits (subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function') } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); Object.defineProperty(subClass, 'prototype', { writable: false }); if (superClass) _setPrototypeOf(subClass, superClass) }

function _setPrototypeOf (o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf (o, p) { o.__proto__ = p; return o }; return _setPrototypeOf(o, p) }

function _createSuper (Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal () { var Super = _getPrototypeOf(Derived); var result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget) } else { result = Super.apply(this, arguments) } return _possibleConstructorReturn(this, result) } }

function _possibleConstructorReturn (self, call) { if (call && (_typeof(call) === 'object' || typeof call === 'function')) { return call } else if (call !== void 0) { throw new TypeError('Derived constructors may only return object or undefined') } return _assertThisInitialized(self) }

function _assertThisInitialized (self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called") } return self }

function _isNativeReflectConstruct () { if (typeof Reflect === 'undefined' || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === 'function') return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true } catch (e) { return false } }

function _getPrototypeOf (o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf (o) { return o.__proto__ || Object.getPrototypeOf(o) }; return _getPrototypeOf(o) }

function _defineProperty (obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }) } else { obj[key] = value } return obj }

var isSSR = typeof window === 'undefined' // fake context on SSR

var AuthenticationContext = isSSR ? function () {} : _adal.default
exports.AuthenticationContext = AuthenticationContext
var redirectMessages = ['AADSTS16002', // old sid - https://github.com/salvoravida/react-adal/issues/46
  'AADSTS50076', // MFA support - https://github.com/salvoravida/react-adal/pull/45
  'AADSTS50079' // MFA support
]

function shouldAcquireNewToken (message) {
  return redirectMessages.some(function (v) {
    return message.indexOf(v) !== -1
  })
}

function parseResourceInfo (resourceInfo) {
  return typeof resourceInfo === 'string' ? {
    resourceGuiId: resourceInfo
  } : resourceInfo
}

function adalGetToken (authContext, resourceInfo, callback) {
  var _parseResourceInfo = parseResourceInfo(resourceInfo)
  var resourceGuiId = _parseResourceInfo.resourceGuiId
  var extraQueryParameters = _parseResourceInfo.extraQueryParameters
  var claims = _parseResourceInfo.claims

  return new Promise(function (resolve, reject) {
    authContext.acquireToken(resourceGuiId, function (message, token, msg) {
      if (!msg) {
        resolve(token)
      } else if (shouldAcquireNewToken(message)) {
        // Default to redirect for multi-factor authentication
        // but allow using popup if a callback is provided
        if (callback) {
          authContext.acquireTokenPopup(resourceGuiId, extraQueryParameters, claims, callback)
        } else {
          authContext.acquireTokenRedirect(resourceGuiId, extraQueryParameters, claims)
        }
      } else {
        reject({
          message: message,
          msg: msg
      });} // eslint-disable-line

    })
  })
}

function runWithAdal (authContext, app, doNotLogin) {
  // SSR support
  if (isSSR) {
    if (doNotLogin) app()
    return
  } // it must run in iframe too for refreshToken (parsing hash and get token)

  authContext.handleWindowCallback() // Clear the resource cache on new login
  // https://github.com/salvoravida/react-adal/issues/68

  authContext.invalidateResourceTokens() // prevent iframe double app !!!

  if (window === window.parent) {
    if (!authContext.isCallback(window.location.hash)) {
      // adal sdk assigns clientId if loginResource is not provided
      var resource = authContext.config.loginResource
      var token = authContext.getCachedToken(resource)
      var user = authContext.getCachedUser()

      if (!token || !user) {
        if (doNotLogin) {
          app()
        } else {
          authContext.login()
        }
      } else {
        app()
      }
    }
  }
}

function adalFetch (authContext, resourceInfo, fetch, url, options) {
  return adalGetToken(authContext, resourceInfo).then(function (token) {
    var o = options || {}
    if (!o.headers) o.headers = {}
    o.headers.Authorization = 'Bearer '.concat(token)
    return fetch(url, o)
  })
} // eslint-disable-next-line

var withAdalLogin = function withAdalLogin (authContext, resourceInfo) {
  // eslint-disable-next-line
  return function (WrappedComponent, renderLoading, renderError) {
    return /* #__PURE__ */(function (_React$Component) {
      _inherits(_class2, _React$Component)

      var _super = _createSuper(_class2)

      function _class2 (props) {
        var _this

        _classCallCheck(this, _class2)

        _this = _super.call(this, props)

        _defineProperty(_assertThisInitialized(_this), 'safeSetState', function (state) {
          if (_this.mounted) {
            _this.setState(state)
          } else {
            _this.todoSetState = state
          }
        })

        _defineProperty(_assertThisInitialized(_this), 'componentDidMount', function () {
          _this.mounted = true

          if (_this.todoSetState) {
            _this.setState(_this.todoSetState)
          }
        })

        _defineProperty(_assertThisInitialized(_this), 'componentWillUnmount', function () {
          _this.mounted = false
        })

        _this.state = {
          logged: false,
          error: null
        } // #67 Using react-adal with Server Side Rendering(Next.js)

        if (!isSSR) {
          adalGetToken(authContext, resourceInfo).then(function () {
            _this.safeSetState({
              logged: true
            })
          }).catch(function (error) {
            var msg = error.msg
            console.log('adalGetToken', error); // eslint-disable-line
            // Avoid the infinite loop when access_denied
            // https://github.com/salvoravida/react-adal/issues/33

            var loginError = authContext.getLoginError()
            var loginWasTriedButFailed = loginError !== undefined && loginError !== null && loginError !== ''

            if (loginWasTriedButFailed) {
              _this.safeSetState({
                error: loginError
              })
            } else if (msg === 'login required') {
              authContext.login()
            } else {
              _this.safeSetState({
                error: error
              })
            }
          })
        }

        return _this
      }

      _createClass(_class2, [{
        key: 'render',
        value: function render () {
          if (isSSR) return null
          var _this$state = this.state
          var logged = _this$state.logged
          var error = _this$state.error
          if (logged) return /* #__PURE__ */_react.default.createElement(WrappedComponent, this.props)
          if (error) return typeof renderError === 'function' ? renderError(error) : null
          return typeof renderLoading === 'function' ? renderLoading() : null
        }
      }])

      return _class2
    }(_react.default.Component))
  }
}

exports.withAdalLogin = withAdalLogin
