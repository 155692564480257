import { Button, Typography, Modal, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'

import { useMutateAdminUnBanPartner } from '../../../../api/react-query/adminPartners'
import { useParams } from 'react-router-dom'

const { Text } = Typography

const UnBan = ({ allowUnban = false }) => {
  const { id } = useParams()
  const [isShow, setIsShow] = useState(false)

  const {
    mutate: mutateUnBanPartner,
    data: dataUnBanPartner,
    isError: isErrorUnBanPartner,
    isLoading: isLoadingUnBanPartner,
    isSuccess: isSuccessUnBanPartner
  } = useMutateAdminUnBanPartner()

  useEffect(() => {
    if (isErrorUnBanPartner) {
      message.error(dataUnBanPartner?.errorMessage || 'Ошибка разбана Партнера.')
    }
  }, [dataUnBanPartner, isErrorUnBanPartner])
  useEffect(() => {
    if (isSuccessUnBanPartner) {
      if (dataUnBanPartner?.errorMessage) {
        return message.error(dataUnBanPartner?.errorMessage)
      }
      setIsShow(false)
      message.success('Партнер разбанен.')
    }
  }, [dataUnBanPartner, isSuccessUnBanPartner])

  const handleOnSave = () => mutateUnBanPartner({ id })
  const okButtonProps = useMemo(() => ({ className: 'green', loading: isLoadingUnBanPartner }), [isLoadingUnBanPartner])
  const cancelButtonProps = useMemo(() => ({ disabled: isLoadingUnBanPartner }), [isLoadingUnBanPartner])
  if (!allowUnban) return null

  return (
    <>
      <Button onClick={() => setIsShow(true)} disabled={isLoadingUnBanPartner} className='mb-3 ml-3' type='success'>
        Разбанить
      </Button>
      <Modal
        width={700}
        visible={isShow}
        title='Разбанить Партнера'
        onCancel={() => setIsShow(false)}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
        onOk={handleOnSave}
      >
        <Text>
          Партнер будет разбанен и получит статус свободного партнера. <br />
          Партнер получит СМС и сможет в своей анкете внести изменения и отправить ее на проверку.
        </Text>
      </Modal>
    </>
  )
}
export default UnBan
