import React, { useEffect } from 'react'
import { Button, Form, message, Spin } from 'antd'
import InnPhotoWithDescription from './InnPhotoWithDescription'
import { useMutatePartnerProfile } from '../../../api/react-query/partners'
import { profileScreen } from '../../../translates'
import { createApiDataFromForm } from '../../../helper'
import { useStores } from '../../../Store/Store'

function InnPhotoDescriptionForm ({ profile }) {
  const [form] = Form.useForm()
  const { partnerProfile } = useStores()

  const {
    mutate: updatePartnerProfile,
    data: dataUpdatePartnerProfile,
    isSuccess: isSuccessUpdatePartnerProfile,
    isError: isErrorUpdatePartnerProfile,
    isLoading: isLoadingUpdatePartnerProfile
  } = useMutatePartnerProfile()

  useEffect(() => {
    if (isSuccessUpdatePartnerProfile && dataUpdatePartnerProfile?.isSuccess) {
      message.success('Данные успешно сохранены')
      partnerProfile.setPartnerProfile({
        ...dataUpdatePartnerProfile,
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
    } else if (isErrorUpdatePartnerProfile || dataUpdatePartnerProfile?.errorMessage) {
      message.error(dataUpdatePartnerProfile?.errorMessage || 'Ошибка записи данных партнера')
    }
  }, [dataUpdatePartnerProfile, isSuccessUpdatePartnerProfile, isErrorUpdatePartnerProfile])

  const handleFinish = (values) => {
    const info = createApiDataFromForm(values)
    updatePartnerProfile({ info })
  }

  return (
    <Form form={form} onFinish={handleFinish}>
      <Spin spinning={isLoadingUpdatePartnerProfile}>
        <InnPhotoWithDescription form={form} profile={profile} />
        <Form.Item>
          <Button
            block
            size='large'
            type='primary'
            htmlType='submit'
            className='continue-button'
          >
            {profileScreen.button.send}
          </Button>
        </Form.Item>
      </Spin>
    </Form>
  )
}

export default InnPhotoDescriptionForm
