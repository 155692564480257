import React, { useEffect, useMemo, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Layout, message, PageHeader, Row, Spin, Typography } from 'antd'
import { LeftOutlined } from '@ant-design/icons'

import { useGetOffer } from '../../../api/react-query/partners'
import MobileHeader from '../MobileHeader/MobileHeader'
import { agreementScreen } from '../../../translates'
import DocWithNotices from '../PolicyAgreement/DocWithNotices'

const { Paragraph } = Typography

function OfferSimple () {
  const history = useHistory()
  const { search } = useLocation()
  const role = useMemo(() => {
    const params = new URLSearchParams(search)
    return params.get('role')
  }, [search])

  const {
    data: dataOffer,
    isError: isErrorOffer,
    isLoading: isLoadingOffer
  } = useGetOffer(role, {
    enabled: !!role
  })

  useEffect(() => {
    if (isErrorOffer) {
      message.error('Не удалось загрузить оферту. Попробуйте позже')
    }
  }, [
    isErrorOffer
  ])
  const myRef = useRef(null)
  useEffect(() => {
    if (myRef.current) {
      document.body.scrollIntoView({ behavior: 'smooth' })
    }
  }, [myRef])

  return (
    <div ref={myRef}>
      <Layout className='PolicyAgreement'>
        <MobileHeader />
        <Row className='mt-3'>
          <Button type='text' onClick={() => { history.goBack() }}>
            <LeftOutlined /> {agreementScreen.button.back}
          </Button>
        </Row>
        <Spin spinning={isLoadingOffer} size='large'>
          <Row justify='space-around'>
            <PageHeader title={dataOffer?.offer?.subject} />
          </Row>
          {
            dataOffer?.offer?.body &&
              <Row>
                <Paragraph className='document'>
                  <DocWithNotices docData={dataOffer.offer} />
                </Paragraph>
              </Row>
          }
        </Spin>
      </Layout>
    </div>
  )
}

export default OfferSimple
