import React, { useEffect, useMemo, useReducer } from 'react'
import { Checkbox } from 'antd'
import { simpleReducer } from '../../../../helper'
import { AppConfig } from '../../../../AppConfig'

function AssetInventoryFlag ({ item, onSuccess, isReturnActType = false }) {
  const list = useMemo(() => {
    return Object.keys(item?.assetInventory || {})
      .filter(key => Object.keys(AppConfig.inventoryTypes).includes(key))
      .filter(key => item.assetInventory[key])
      .filter(i => i !== AppConfig.inventoryTypes.new)
  }, [item?.assetInventory])

  const initialState = {
    checked: isReturnActType && item.isSuit && list.length > 0 ? [list[0]] : []
  }

  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleChange = (value) => {
    const checkedList = value?.target?.checked ? [value?.target?.value] : []
    if (isReturnActType && item.isSuit && state.checked.length === 1 && !checkedList.length) {
      return
    }
    setState({ checked: checkedList })
  }

  useEffect(() => {
    const inventoryFlags = { ...item?.assetInventory }
    if (state.checked.length) {
      Object.keys(inventoryFlags).filter(key => !state.checked.includes(key)).forEach(key => {
        delete inventoryFlags[key]
      })
    } else {
      Object.keys(inventoryFlags).filter(key => key !== AppConfig.inventoryTypes.new).forEach(key => {
        delete inventoryFlags[key]
      })
    }
    onSuccess(Object.keys(inventoryFlags)?.[0] || null, item)
  }, [state.checked])

  if (!list.length) {
    return null
  }

  return (
    <div className='asset-inventory-flags'>
      {list.map(item => {
        return (
          <Checkbox
            key={item}
            value={item}
            checked={state.checked?.includes(item)}
            onChange={handleChange}
          >
            {AppConfig.inventoryTypeLabels[item]}
          </Checkbox>
        )
      })}
    </div>
  )
}

export default AssetInventoryFlag
