import React from 'react'
import { decorate, observable } from 'mobx'
import { MobXProviderContext } from 'mobx-react'
import PartnerList from './Admin/Model'
import PartnerProfile from './PartnerProfile/Model'

class Store {
  adminPartnerList = new PartnerList()
  adminBannedPartnerList = new PartnerList()
  partnerProfile = new PartnerProfile()
}

export default decorate(Store, {
  adminPartnerList: observable,
  adminBannedPartnerList: observable,
  partnerProfile: observable
})

export const useStores = () => {
  return React.useContext(MobXProviderContext)
}
