import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function EgripPhoto2 () {
  return (
    <>
      <Title level={3}>Скачайте файл</Title>
      <Text>Ниже у вас появиться ваша выписка ОГРНИП, скачайте её и добавьте в анкету</Text>
      <br />
      <br />
      <img src='/img/photo-doc/egrip-doc-2.png' className='img-doc' />
    </>
  )
}

export default EgripPhoto2
