export const offerScreen = {
  prefixTitle: 'Пожалуйста, ознакомьтесь и подтвердите согласие с Условиями оказания услуг',
  titleCourier: 'Условия оказания услуг по сборке',
  titleDriver: 'Условия оказания услуг по доставки',
  prefixFrom: 'С',
  prefixTo: 'по',
  input: {
    accept: {
      label: 'недель',
      placeholder: 'Нажимая на кнопку, вы подтверждаете, что ознакомлены, согласны и полностью принимаете условия',
      placeholderOld: 'Нажимая на кнопку “Подписать оферту“, Вы подтверждаете, что ознакомлены, согласны с условиями оферты и полностью принимаете их.'
    }

  },
  offerParts: {
    roleOffer: 'Оферты',
    cyberSecurityPolicy: 'Обязательства о соблюдении требований кибербезопасности в ПАО Сбербанк',
    assemblyAndDeliveryPolicy: 'Корректировки при нарушении правил сборки и доставки',
    cyberSecurityPolicySingle: 'Обязательство о соблюдении требований кибербезопасности в ПАО Сбербанк',
    assemblyAndDeliveryPolicySingle: 'Корректировки при нарушении правил сборки и доставки'
  },
  offerText: 'Подтвердите, что  ознакомились с Условиями оферты',
  lmkAgreement: 'Подтвердите, что  ознакомились с условиями документа',
  successTextStart: 'Согласие с условиями:',
  successTextEnd: ' — подтверждено',
  button: {
    submit: 'Подписать оферту'
  },
  errors: {
    offer: 'Не удалоось загрузить оферту'
  }
}
