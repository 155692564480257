import React, { useEffect } from 'react'
import { Button, message } from 'antd'
import { useMutateIssueCardAdmin } from '../../../../api/react-query/adminPartners'
const IssueCardRequest = ({ partnerId }) => {
  const {
    mutate: issueCard,
    data: issueCardData,
    isLoading: isLoadingIssueCard,
    isError
  } = useMutateIssueCardAdmin()

  useEffect(() => {
    if (isError || issueCardData?.errorMessage) {
      message.error(issueCardData?.errorMessage || 'Не удалось отправить запрос на выпуск карты')
    } else if (issueCardData?.isSuccess) {
      message.success('Запрос на выпуск карты принят')
    }
  }, [issueCardData, isError])

  const handleClick = () => {
    issueCard({ id: partnerId })
  }

  if (!partnerId) {
    return null
  }

  return (
    <div>
      <Button
        ghost
        type='primary'
        size='middle'
        onClick={handleClick}
        loading={isLoadingIssueCard}
      >
        Выпустить карту
      </Button>
    </div>
  )
}

export default IssueCardRequest
