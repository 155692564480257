import React, { useCallback, useEffect } from 'react'
import { Button, message, Spin, Typography } from 'antd'
import './MyTaxApp.css'
import { nbsp } from '../../../Constants'
import {
  useMutateConfirmMyTaxStatus,
  useMutateConfirmMyTaxBind,
  useMutateCreateMyTaxStatus,
  useMutateSendMyTaxBind
} from '../../../api/react-query/partners'
import { AppConfig } from '../../../AppConfig'
import { useStores } from '../../../Store/Store'
import { handleMyTax } from '../../../helper'

const { Title } = Typography
const { smzStatusCodes, smzErrorCodes, smzRequiredActions } = AppConfig

function MyTaxApp ({ profile }) {
  const { partnerProfile } = useStores()

  const {
    data: dataCreateTaxStatus,
    mutate: createTaxStatus,
    isSuccess: isSuccessCreateTaxStatus,
    isError: isErrorCreateTaxStatus,
    isLoading: isLoadingCreateTaxStatus
  } = useMutateCreateMyTaxStatus()

  const {
    data: dataTaxStatus,
    mutate: confirmTaxStatus,
    isSuccess: isSuccessTaxStatus,
    isError: isErrorTaxStatus,
    isLoading: isLoadingTaxStatus
  } = useMutateConfirmMyTaxStatus()

  const {
    data: dataTaxBind,
    mutate: confirmTaxBind,
    isSuccess: isSuccessTaxBind,
    isError: isErrorTaxBind,
    isLoading: isLoadingTaxBind
  } = useMutateConfirmMyTaxBind()

  const {
    data: dataSendTaxBind,
    mutate: sendTaxBind,
    isSuccess: isSuccessSendTaxBind,
    isError: isErrorSendTaxBind,
    isLoading: isLoadingSendTaxBind
  } = useMutateSendMyTaxBind()

  useEffect(() => {
    if (isSuccessCreateTaxStatus && dataCreateTaxStatus?.isSuccess) {
      message.success('Запрос отправлен')
      partnerProfile.setPartnerProfile(dataCreateTaxStatus?.partner)
    } else if (isErrorCreateTaxStatus || dataCreateTaxStatus?.errorMessage) {
      message.error(dataCreateTaxStatus?.errorMessage || 'Ошибка запроса')
    }
  }, [isSuccessCreateTaxStatus, isErrorCreateTaxStatus, dataCreateTaxStatus])

  useEffect(() => {
    if (isSuccessTaxStatus && dataTaxStatus?.isSuccess) {
      message.success('Запрос отправлен')
      if (
        dataTaxStatus?.partner?.profile?.smz?.requiredAction === profile?.smz?.requiredAction &&
        dataTaxStatus?.partner?.profile?.smz?.errorCode === profile?.smz?.errorCode
      ) {
        showMessageError({
          requiredAction: dataTaxStatus?.partner?.profile?.smz?.requiredAction,
          errorCode: dataTaxStatus?.partner?.profile?.smz?.errorCode
        })
      }
      partnerProfile.setPartnerProfile(dataTaxStatus?.partner)
    } else if (isErrorTaxStatus || dataTaxStatus?.errorMessage) {
      message.error(dataTaxStatus?.errorMessage || 'Ошибка запроса проверки статуса')
    }
  }, [isSuccessTaxStatus, isErrorTaxStatus, dataTaxStatus])

  useEffect(() => {
    if (isSuccessTaxBind && dataTaxBind?.isSuccess) {
      if (!dataTaxBind?.partner?.profile?.smz?.errorCode !== smzErrorCodes.requestNotProcessed) {
        message.success('Запрос отправлен')
      }
      partnerProfile.setPartnerProfile(dataTaxBind?.partner)
    } else if (isErrorTaxBind || dataTaxBind?.errorMessage) {
      message.error(dataTaxBind?.errorMessage || 'Ошибка запроса подтверждения партнерства')
    }
  }, [isSuccessTaxBind, isErrorTaxBind, dataTaxBind])

  useEffect(() => {
    if (isSuccessSendTaxBind && dataSendTaxBind?.isSuccess) {
      message.success('Запрос отправлен')
      partnerProfile.setPartnerProfile(dataSendTaxBind?.partner)
    } else if (isErrorSendTaxBind || dataSendTaxBind?.errorMessage) {
      message.error(dataSendTaxBind?.errorMessage || 'Ошибка запроса')
    }
  }, [isSuccessSendTaxBind, isErrorSendTaxBind, dataSendTaxBind])

  useEffect(() => {
    if (profile?.smz?.errorCode === smzErrorCodes.requestNotProcessed) {
      message.error({
        content: (
          <>
            <b>Партнерство не получено</b><br />
            Партнер еще не передал права в моем налоге
          </>
        ),
        duration: 5
      })
    }
    if (profile?.smz?.errorCode === smzErrorCodes.invalidInn) {
      message.error('ИНН невалиден', 5)
    }
  }, [profile?.smz?.errorCode])

  useEffect(() => {
    showMessageError({
      requiredAction: profile?.smz?.requiredAction, errorCode: profile?.smz?.errorCode
    })
  }, [profile?.smz?.errorCode, profile?.smz?.requiredAction])

  const showMessageError = ({ requiredAction, errorCode }) => {
    if (requiredAction === smzRequiredActions.ConfirmSmz && [
      smzErrorCodes.unregistered,
      smzErrorCodes.partnerUnregistered
    ].includes(errorCode)
    ) {
      message.error({
        content: (
          <>
            <b>Вы пока не встали на учет в качестве самозанятого</b><br />
            Пожалуйста перейдите в приложении «Мой налог» и выполните шаги согласно инструкции
          </>
        ),
        duration: 5
      })
    }
  }

  const handleConfirmStatus = () => {
    confirmTaxStatus()
  }

  const handleConfirmBind = () => {
    confirmTaxBind()
  }

  const handleReload = useCallback(() => {
    if (profile?.smz?.requiredAction === smzRequiredActions.SendCreate) {
      createTaxStatus()
    } else if (profile?.smz?.requiredAction === smzRequiredActions.ConfirmSmz) {
      confirmTaxStatus()
    } else if (profile?.smz?.requiredAction === smzRequiredActions.SendBind) {
      sendTaxBind()
    } else if (profile?.smz?.requiredAction === smzRequiredActions.ConfirmBind) {
      confirmTaxBind()
    } else {
      location.reload()
    }
  }, [profile?.smz?.requiredAction])

  const getContentSelfEmployedStatus = () => {
    return (
      <>
        <Title level={4}>Получите статус самозанятого</Title>
        <p>
          Без статуса не получится выполнять заказы и оказывать услуги.
        </p>
        <ul>
          <li>Перейдите в «Мой налог»{nbsp}— на сайт или в приложение</li>
          <li>Зарегистрируйтесь используя любой удобный метод</li>
          <li>Дождитесь сообщения о регистрации. Обычно это занимает не более 5 минут</li>
          <li><b>Вернитесь на этот экран и нажмите «Подтвердить статус»</b></li>
        </ul>
        <div>
          <Button
            ghost
            size='large'
            type='primary'
            className='margin-top-30'
            onClick={handleMyTax}
          >
            Перейти в «Мой налог»
          </Button>
        </div>
        <div>
          <Button
            ghost
            size='large'
            type='primary'
            className='margin-top-30'
            onClick={handleConfirmStatus}
            loading={isLoadingTaxStatus}
          >
            Подтвердить статус
          </Button>
        </div>
      </>
    )
  }

  const getContentActivate = () => {
    return (
      <>
        <Title level={4}>Подключите партнёрство в{nbsp}«Мой{nbsp}налог»</Title>
        <p>
          Мы отправили вам запрос на партнёрство в Мой налог.
        </p>
        <p>
          Важно выбрать Купер в качестве партнёра, чтобы получать выплаты и автоматически учитывать доходы.
        </p>
        <ul>
          <li>Откройте «Мой налог»{nbsp}— сайт или приложение</li>
          <li>Перейдите в меню, раздел «Партнёры»</li>
          <li>Нажмите «Разрешить» в появившемся запросе от Купера</li>
          <li><b>Вернитесь на этот экран и нажмите «Подтвердить партнёрство»</b></li>
        </ul>
        <div>
          <Button
            ghost
            size='large'
            type='primary'
            className='margin-top-30'
            onClick={handleMyTax}
          >
            Перейти в «Мой налог»
          </Button>
        </div>
        <div>
          <Button
            ghost
            size='large'
            type='primary'
            className='margin-top-30'
            onClick={handleConfirmBind}
            loading={isLoadingTaxBind}
          >
            Подтвердить партнёрство
          </Button>
        </div>
      </>
    )
  }

  const StopSvg = ({ height = 100, width = 100, color = 'red' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill={color} viewBox='0 0 256 256'><path d='M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z' opacity='0.2' /><path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm88,104a87.56,87.56,0,0,1-20.41,56.28L71.72,60.4A88,88,0,0,1,216,128ZM40,128A87.56,87.56,0,0,1,60.41,71.72L184.28,195.6A88,88,0,0,1,40,128Z' /></svg>
  )

  const ClockSvg = ({ height = 100, width = 100, color = 'red' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill={color} viewBox='0 0 256 256'><path d='M216,128a88,88,0,1,1-88-88A88,88,0,0,1,216,128Z' opacity='0.2' /><path d='M136,80v43.47l36.12,21.67a8,8,0,0,1-8.24,13.72l-40-24A8,8,0,0,1,120,128V80a8,8,0,0,1,16,0Zm88-24a8,8,0,0,0-8,8V82c-6.35-7.36-12.83-14.45-20.12-21.83a96,96,0,1,0-2,137.7,8,8,0,0,0-11-11.64A80,80,0,1,1,184.54,71.4C192.68,79.64,199.81,87.58,207,96H184a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V64A8,8,0,0,0,224,56Z' /></svg>
  )

  const getContentError = (errorCode) => {
    return (
      <>
        {
          errorCode === smzErrorCodes.fnsError
            ? (
              <>
                <StopSvg />
                <Title level={3}>Нет ответа ФНС</Title>
                <p>Иногда данные задерживаются<br />вернитесь чуть позже</p>
              </>
            )
            : (
              <>
                <ClockSvg />
                <Title level={3}>Ошибка</Title>
                <p>Налоговая не отвечает.<br />Попробуйте позже</p>
              </>
            )
        }
        <Button
          ghost
          size='large'
          type='primary'
          className='margin-top-30'
          loading={isLoadingCreateTaxStatus || isLoadingSendTaxBind}
          onClick={handleReload}
        >
          Обновить
        </Button>
      </>
    )
  }

  if (isLoadingTaxBind || isLoadingTaxStatus || isLoadingCreateTaxStatus || isLoadingSendTaxBind) {
    return <Spin style={{ marginTop: '1rem' }} size='large' />
  }

  if (profile?.smz?.errorCode && ![
    smzErrorCodes.partnerUnregistered,
    smzErrorCodes.requestNotProcessed
  ].includes(profile?.smz?.errorCode)) {
    return getContentError(profile?.smz?.errorCode)
  }

  return (
    <div className='myTaxApp'>
      {profile?.smz?.requiredAction === smzRequiredActions.ConfirmSmz && getContentSelfEmployedStatus()}
      {
        (
          profile?.smz?.statusCode === smzStatusCodes.bindingSending ||
          [smzRequiredActions.ReaffirmBind, smzRequiredActions.ConfirmBind].includes(profile?.smz?.requiredAction)
        ) && getContentActivate()
      }
    </div>
  )
}

export default MyTaxApp
