import React, { useCallback, useEffect, useReducer } from 'react'
import { Button, Col, Form, message, Row, Select, Typography, Divider } from 'antd'

import './Report.css'
import { simpleReducer, sortByName } from '../../../helper'
import FilterTitle from './FilterTitle'
import { AppConfig } from '../../../AppConfig'
import { useChains } from '../../../hooks'
import { useOrgTreeQuery } from '../../../api/react-query/org'
import ReportList from '../../../components/Admin/Reports/ReportList'
import { useGetReportsQueue, useMutateDispatchReportToQueue } from '../../../api/react-query/reports'

const { Title } = Typography
const { Option } = Select

const selectSearchFilter = (input, option) => {
  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export default function FireBriefingReport () {
  const [form] = Form.useForm()
  const initialState = {
    cities: [],
    chains: [],
    selectedCities: [],
    selectedChains: [],
    filteredChains: [],
    currentLoadingReportId: null
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    isSuccess: isSuccessOrgTree,
    isError: isErrorOrgTree,
    data: dataOrgTree,
    isLoading: isLoadingOrgTree
  } = useOrgTreeQuery({
    withEmptyCity: true,
    withClosed: true,
    withOpened: true
  })

  const {
    isError: isErrorReportsQueue,
    data: dataReportsQueue,
    isLoading: isLoadingReportsQueue,
    refetch: refetchReportsQueue
  } = useGetReportsQueue(AppConfig.report.types.fireBriefing)

  const {
    mutate: addFireBriefingReportToQueue,
    isError: isErrorAddFireBriefingReportToQueue,
    data: dataAddFireBriefingReportToQueue,
    isLoading: isLoadingAddFireBriefingReportToQueue
  } = useMutateDispatchReportToQueue()

  useEffect(() => {
    if (isSuccessOrgTree && dataOrgTree?.isSuccess) {
      const cities = sortByName(dataOrgTree?.cities)
      setState({ cities })
    }
    if (isErrorOrgTree || dataOrgTree?.errorMessage) {
      message.error(dataOrgTree?.errorMessage || dataOrgTree?.title || 'Ошибка доступа к данным')
    }
  }, [dataOrgTree, isSuccessOrgTree, isErrorOrgTree])

  useEffect(() => {
    if (isErrorAddFireBriefingReportToQueue || dataAddFireBriefingReportToQueue?.errorMessage) {
      message.error(dataAddFireBriefingReportToQueue?.errorMessage || 'Не удалось поставить отчет в очередь')
    }
  }, [isErrorAddFireBriefingReportToQueue, dataAddFireBriefingReportToQueue])

  useEffect(() => {
    if (isErrorReportsQueue || dataReportsQueue?.errorMessage) {
      message.error(dataReportsQueue?.errorMessage || 'Не удалось получить отчеты')
    }
  }, [isErrorReportsQueue, dataReportsQueue])

  const { data: chains } = useChains()

  useEffect(() => {
    if (chains) {
      setState({ chains, filteredChains: chains })
    }
  }, [chains])

  const handleChangeCities = useCallback(
    (value, items) => {
      setState({
        selectedCities: items.map(e => e.key),
        filteredChains: items.length
          ? state.cities
            .filter(city => items.map(e => e.key).includes(city.id))
            .reduce((prev, curr) => {
              const chainsIds = prev.map(chain => chain.id)
              curr.chains.map(chain => {
                if (!chainsIds.includes(chain.id)) {
                  prev.push(chain)
                }
              })
              return prev
            }, [])
          : state.chains,
        selectedChains: []
      })
      form.resetFields(['chains'])
    },
    [form, state]
  )
  const handleChangeChains = useCallback((value, items) => {
    setState({ selectedChains: items.map(e => e.key) })
  }, [])

  const getReportQueryData = (variant) => ({
    cityIds: state.selectedCities.length ? state.selectedCities : undefined,
    chainIds: state.selectedChains.length ? state.selectedChains : undefined,
    variant
  })

  const handleAddReportToQueue = (reportVersion) => {
    addFireBriefingReportToQueue({
      type: AppConfig.report.types.fireBriefing,
      data: getReportQueryData(reportVersion)
    })
  }

  return (
    <>
      <Title level={3} className='title-management'>Отчет пожарный инструктаж</Title>
      <Form
        form={form}
      >

        <FilterTitle title='Город'>
          <Form.Item name='cities'>
            <Select
              loading={isLoadingOrgTree}
              onChange={handleChangeCities}
              size='middle'
              placeholder='Выберите город'
              showSearch
              optionFilterProp='children'
              filterOption={selectSearchFilter}
              allowClear
              mode='multiple'
            >
              {state.cities?.map(city => (
                <Option key={city.id} value={city.name}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </FilterTitle>

        <FilterTitle title='Сеть'>
          <Form.Item name='chains'>
            <Select
              onChange={handleChangeChains}
              size='middle'
              placeholder='Выберите сеть'
              showSearch
              filterOption={selectSearchFilter}
              allowClear
              mode='multiple'
            >
              {state.filteredChains?.map(chain => (
                <Option key={chain.id} value={chain.name}>
                  {chain.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </FilterTitle>

        <Row>
          <Col className='mr-3'>
            <Button
              loading={isLoadingAddFireBriefingReportToQueue}
              type='primary'
              htmlType='button'
              onClick={() => handleAddReportToQueue('v2')}
            >
              Запросить отчет
            </Button>
          </Col>
        </Row>
      </Form>
      {
        dataReportsQueue?.items &&
          <>
            <Divider />
            <Row className='mt-3' gutter={15} align='middle'>
              <Col>
                <Title level={5}>Отчеты</Title>
              </Col>
              <Col>
                <Button onClick={() => refetchReportsQueue()}>Обновить</Button>
              </Col>
            </Row>
            <ReportList
              isLoadingReports={isLoadingReportsQueue}
              dataReports={dataReportsQueue?.items}
            />
          </>
      }
    </>
  )
}
