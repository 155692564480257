export const agreementScreen = {
  title: {
    personalDataPolicy: 'Согласие на обработку персональных данных',
    electronicSignaturePolicy: 'Соглашение об использовании простой электронной подписи',
    partnersSberMarketPolicy: 'Партнеры Купера',
    cyberSecurityPolicy: 'Обязательство о соблюдении требований кибербезопасности в ПАО Сбербанк',
    assemblyAndDeliveryPolicy: 'Корректировки при нарушении правил сборки и доставки'
  },
  button: {
    back: 'Назад',
    submit: 'Далее'
  },
  errors: {
    agreement: 'Не удалоось загрузить соглашение',
    agreementAccept: 'Не удалоось сохранить согласие'
  }
}
