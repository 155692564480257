import { Form } from 'antd'
import React, { useMemo } from 'react'
import { MaskedInput } from 'antd-masked-input'
import { getBirthDateRules } from '../../../helper'
import SberIcon from '../SberIcon'
import TinkoffIcon from '../TinkoffIcon'

const BirthDateFormItem = ({
  label = 'Дата рождения',
  rules = undefined,
  requiredRule = true,
  messageRule = 'Пожалуйста, введите дату в формате дд.мм.гггг',
  size = 'large',
  placeholder = '25.12.1990',
  validator,
  onBlur = () => {},
  showSberIcon = false,
  showTinkoffIcon = false
}) => {
  const labelParam = useMemo(() => {
    return showSberIcon || showTinkoffIcon
      ? (
        <>
          {label}
          {showSberIcon && <SberIcon style={{ marginLeft: '0.5rem' }} />}
          {showTinkoffIcon && <TinkoffIcon style={{ marginLeft: '0.5rem' }} />}
        </>
      )
      : label
  }, [label, showSberIcon, showTinkoffIcon])

  return (
    <Form.Item
      name={['personalData', 'birthDate']}
      label={labelParam}
      rules={rules ?? [
        { required: requiredRule, message: messageRule, ...(validator ? { validator } : {}) },
        ...getBirthDateRules()
      ]}
    >
      <MaskedInput
        size={size}
        placeholder={placeholder}
        onBlur={onBlur}
        mask='11.11.1111'
      />
    </Form.Item>
  )
}
export default BirthDateFormItem
