import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Avatar, Button, Space, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import env from '../../../env.json'
import { ADMIN_AUTH_PAGE } from '../../../routes'
import { ConfigContext } from '../../../context/configContext'
import { queryClient } from '../../../api/react-query/api'

const { Text } = Typography

const google = 'google'
const adfs = 'adal'
const adfsKey = 'adal.access.token.key'

function AdminLogin () {
  const [name, setName] = useState('')
  const [avatar, setAvatar] = useState('')
  const [authType, setAuthType] = useState('')

  const history = useHistory()
  const onInit = auth2 => {
    if (auth2?.isSignedIn?.get()) {
      setAuthType(google)
      setName(auth2?.currentUser?.get()?.getBasicProfile()?.getName())
      setAvatar(auth2?.currentUser?.get()?.getBasicProfile()?.getImageUrl())
    } else if (localStorage.getItem(adfsKey)) {
      setAuthType(adfs)
      setName('Sbermarket User')
      setAvatar('')
    } else {
      onError('Пользователь не авторизован')
    }
  }
  const onError = err => {
    console.log('error', err)
    history.push(ADMIN_AUTH_PAGE)
  }
  useEffect(() => {
    if (env.envName === 'dev') {
      window.gapi.load('auth2', () => {
        window.gapi.auth2
          .init({
            client_id: env.googleClientID
          })
          .then(onInit, onError)
      })
    } else {
      onInit()
    }
  }, [])

  const onLogout = (cb) => {
    console.log('User signed out.')
    queryClient.clear()
    cb?.()
  }

  const handleSignOut = (cb) => {
    if (authType === google) {
      const auth2 = window.gapi?.auth2?.getAuthInstance()
      auth2?.currentUser.get().disconnect()
        ?.then(auth2.signOut())
        ?.then(auth2.disconnect())
        ?.then(() => onLogout(cb))
    } else {
      for (const key in localStorage) {
        if (key.includes(adfs)) {
          localStorage.removeItem(key)
        }
      }
      onLogout(cb)
    }
  }

  return (
    <Space>
      <Avatar size={40} src={avatar} icon={avatar ? null : <UserOutlined />} />
      <ConfigContext.Consumer>
        {({ user }) => (
          <Text>{user?.name || name}</Text>
        )}
      </ConfigContext.Consumer>
      <Button onClick={() =>
        handleSignOut(() => {
          fetch(`${env.apiEndpoint}admin/auth/logout`, { method: 'POST' }).finally(() => history.push(ADMIN_AUTH_PAGE))
        })}
      >
        Выйти
      </Button>
    </Space>
  )
}

export default AdminLogin
