import React, { useReducer, useContext, useRef, useEffect } from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { makeFileUrl, simpleReducer, dragElement } from '../../../../helper'
import 'react-photo-view/dist/react-photo-view.css'
import './ViewImage.css'
import {
  ColumnWidthOutlined,
  MinusOutlined,
  PlusOutlined,
  RotateLeftOutlined,
  RotateRightOutlined
} from '@ant-design/icons'
import { Button, Row, Col, Spin } from 'antd'
import { ConfigContext } from '../../../../context/configContext'
import { getLogHeaders } from '../../../../api/react-query/api'

function ViewImage ({ image, title, onSave, isLoading, hideSave = false }) {
  const { isAdmin, isCoordinator } = useContext(ConfigContext)
  const toolbarRef = useRef(null)
  const initialState = {
    rotate: 0,
    flipped: false,
    isViewingMode: null,
    srcPreview: null
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const getActualAngle = (totalAngle) => {
    const clearAngle = totalAngle % 360
    return Math.abs(clearAngle < 0 ? 360 + clearAngle : clearAngle)
  }

  const handleFlip = () => {
    setState({ flipped: !state.flipped })
  }

  const handleSave = async ({ onClose }) => {
    const res = await onSave({
      angle: state.rotate,
      flip: state.flipped
    })
    if (res.isSuccess) {
      setState({
        ...initialState,
        srcPreview: state.srcPreview
      })
      onClose()
    }
  }

  useEffect(() => {
    if (state.isViewingMode) {
      // Через ref получить доступ к просмотрщику картинок не получилось. Берем напрямую.
      const imagePreview = document.getElementsByClassName('image-preview')[0]
      if (imagePreview) {
        /**
         * только таким образом получилось поставить фокус на просмотрщике картинок,
         * чтобы он стал активным элементом document.activeElement
         * если так не сделать, то активный элемент дровер партнера и keydown=Escape закрывает дровер партнера
         */
        imagePreview.tabIndex = '-1'
        imagePreview.focus()
        // Если не навесить напрямую событие закрытия по Escape на просмотрщик, то он не закрывается по клавише
        imagePreview.addEventListener('keydown', function (event) {
          if (event.key === 'Escape') {
            // прерываем цепочку события, чтобы не закрылся drawer партнера
            event.stopImmediatePropagation()
            toolbarRef.current?.onClose?.()
          }
        })
      }

      const imageDoc = document.getElementsByClassName('idViewPhotoWrap')[0]
      dragElement(imageDoc)

      const fetchData = async () => {
        const response = await fetch(makeFileUrl(image?.fileId), { headers: getLogHeaders('Partner document viewer') })
        const blob = await response.blob()
        const url = URL.createObjectURL(blob)
        setState({ srcPreview: url })
      }

      fetchData().catch(console.error)
    } else if (state.isViewingMode === false) {
      /**
       * После закрытия посмотрщика, нужно перевести фокус на drawer партнера, чтобы на нем сразу смогло отработать
       * событие keydown=Escape (оно зашито в компоненте дровера)
       */
      const drawer = document.getElementsByClassName('idPartnerDrawer')[0]
      drawer.tabIndex = '-1'
      drawer.focus()
      setState({
        ...initialState,
        srcPreview: state.srcPreview
      })
    }
  }, [state.isViewingMode])

  const renderToolbar = ({ rotate, onRotate, scale, onScale, onClose }) => {
    toolbarRef.current = { onClose }
    return (
      <Row className='actions-buttons'>
        {(isAdmin || isCoordinator) && !hideSave && (
          <Col>
            <Button
              disabled={Boolean(
                state.rotate === initialState.rotate && state.flipped === initialState.flipped
              )}
              onClick={() => handleSave({ onClose })}
              loading={isLoading}
            >Сохранить
            </Button>
          </Col>
        )}
        <Col>
          Отразить
          <Button
            icon={<ColumnWidthOutlined />}
            className='mr-3 ml-3'
            onClick={handleFlip}
            type={state.flipped ? 'primary' : 'default'}
          />
        </Col>
        <Col>
          Повернуть
          <Button
            icon={<RotateLeftOutlined />}
            className='mr-3 ml-3'
            onClick={() => {
              onRotate(rotate - 90)
              setState({ rotate: getActualAngle(rotate - 90) })
            }}
          />
          <Button
            icon={<RotateRightOutlined />}
            onClick={() => {
              onRotate(rotate + 90)
              setState({ rotate: getActualAngle(rotate + 90) })
            }}
          />
        </Col>
        <Col>
          Масштаб
          <Button
            icon={<MinusOutlined />}
            className='mr-3 ml-3'
            onClick={() => onScale(scale - 1)}
          />
          <Button
            icon={<PlusOutlined />}
            onClick={() => onScale(scale + 1)}
          />
        </Col>
      </Row>
    )
  }

  return (
    <PhotoProvider
      loadingElement={<Spin />}
      brokenElement={<Spin />}
      onVisibleChange={(visible) => setState({ isViewingMode: visible })}
      className='image-preview'
      photoClassName='idViewImage'
      speed={() => 300}
      maskOpacity={0.5}
      pullClosable={false}
      photoWrapClassName={`idViewPhotoWrap ${state.flipped ? 'flipped' : ''}`}
      toolbarRender={renderToolbar}
    >
      <PhotoView src={state.srcPreview}>
        <img src={makeFileUrl(image?.fileId)} alt={title} style={{ maxWidth: 700, maxHeight: 500 }} />
      </PhotoView>
    </PhotoProvider>
  )
}

export default ViewImage
