import React, { useEffect, useMemo, useReducer } from 'react'
import { Modal, Form, Input, Select, Spin, message } from 'antd'
import { getTargetsForDeclineReasons, simpleReducer } from '../../../helper'
import { useMutateDeclineReasonCreate, useMutateDeclineReasonEdit } from '../../../api/react-query/dict'
import { AppConfig } from '../../../AppConfig'

const layout = {
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 }
  },
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 }
  }
}

const { Option } = Select

const initialState = {
  currentTarget: null
}

function ReasonModal ({ visible, onClose, reasonData = false }) {
  const [state, setState] = useReducer(simpleReducer, initialState)
  const [form] = Form.useForm()
  const isEdit = useMemo(() => Boolean(reasonData?.id), [reasonData])
  const targets = useMemo(() => getTargetsForDeclineReasons(), [getTargetsForDeclineReasons])

  useEffect(() => {
    form.setFieldsValue(reasonData)
    setState({ currentTarget: reasonData?.target })
  }, [reasonData])

  const {
    mutate: createDeclineReason,
    data: dataCreate,
    isLoading: isLoadingCreate,
    isSuccess: isSuccessCreate,
    isError: isErrorCreate
  } = useMutateDeclineReasonCreate()

  useEffect(() => {
    if (isSuccessCreate && dataCreate?.isSuccess) {
      message.success('Шаблон причины успешно создан')
      onClose()
    } else if (isErrorCreate || dataCreate?.errorMessage) {
      message.error(dataCreate?.errorMessage || 'Ошибка сохранения шаблона')
    }
  }, [isSuccessCreate, isErrorCreate, dataCreate])

  const {
    mutate: editDeclineReason,
    data: dataEdit,
    isLoading: isLoadingEdit,
    isSuccess: isSuccessEdit,
    isError: isErrorEdit
  } = useMutateDeclineReasonEdit()

  useEffect(() => {
    if (isSuccessEdit && dataEdit?.isSuccess) {
      message.success('Шаблон причины успешно сохранен')
      onClose()
    } else if (isErrorEdit || dataEdit?.errorMessage) {
      message.error(dataEdit?.errorMessage || 'Ошибка сохранения шаблона')
    }
  }, [isSuccessEdit, isErrorEdit, dataEdit])

  const handleOnSave = () => {
    form.validateFields().then(fields => {
      if (isEdit) {
        editDeclineReason({
          id: reasonData.id,
          data: fields
        })
      } else {
        createDeclineReason(fields)
      }
    })
  }

  const onFieldsChange = (fields) => {
    if (fields[0]?.name.includes('target')) {
      setState({ currentTarget: fields[0]?.value })
    }
  }

  const okButtonProps = useMemo(
    () => ({ loading: isLoadingCreate || isLoadingEdit }),
    [isLoadingCreate, isLoadingEdit]
  )

  return (
    <Modal
      width={700}
      visible={visible}
      title={isEdit ? 'Редактироание шаблона отказа' : 'Создание шаблона отказа'}
      onCancel={onClose}
      okText='Сохранить'
      cancelText='Отменить'
      onOk={handleOnSave}
      okButtonProps={okButtonProps}
      destroyOnClose
    >
      <Spin spinning={isLoadingCreate || isLoadingEdit}>
        <Form form={form} {...layout} onFieldsChange={onFieldsChange}>
          <Form.Item
            name='target'
            label='Раздел'
            rules={[{ required: true, message: 'Пожалуйста, укажите раздел' }]}
          >
            <Select
              placeholder='Выберите раздел'
            >
              {Object.keys(targets || {}).map(item => <Option key={item} value={item}>{targets[item]}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            name='reason'
            label='Причина отказа'
            rules={[{ required: true, message: 'Пожалуйста, укажите причину отказа' }]}
          >
            <Input placeholder='Причина отказа' />
          </Form.Item>
          {
            state.currentTarget === AppConfig.reasonTargets.requisitesDetails && (
              <Form.Item
                name='description'
                label='Описание причины'
                rules={[{ required: false }]}
              >
                <Input placeholder='Описание причины' />
              </Form.Item>
            )
          }
        </Form>
      </Spin>
    </Modal>
  )
}

export default ReasonModal
