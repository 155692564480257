import React, { useEffect, useContext, useReducer } from 'react'
import { Button, Input, message, Radio, Space, AutoComplete, Typography } from 'antd'
import { AppConfig } from '../../../../AppConfig'
import { getShopperPath } from '../../../../Constants'
import { useMutateAdminUpdateShopperStatus } from '../../../../api/react-query/adminPartners'
import { ConfigContext } from '../../../../context/configContext'
import { useDeclineReasonsQuery } from '../../../../api/react-query/dict'
import { simpleReducer } from '../../../../helper'

const { Text } = Typography

const initialState = {
  status: null,
  declineReasons: [],
  comment: null,
  errorComment: false
}

function ShopperStatus ({ partnerId, profile, loading, setPartnerData }) {
  const { setConfig } = useContext(ConfigContext)
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    isLoading: isLoadingAdminUpdateShopperStatus,
    mutateAsync: mutateAdminUpdateShopperStatus
  } = useMutateAdminUpdateShopperStatus()

  useEffect(() => {
    setConfig({ loadingData: { isLoadingAdminUpdateShopperStatus } })
  }, [isLoadingAdminUpdateShopperStatus])

  const handleSubmitAccountStatus = async status => {
    setState({ errorComment: false })
    if (status === AppConfig.shopperStatuses.notfound && !state.comment) {
      setState({ errorComment: true })
      return
    }
    if (status) {
      try {
        const result = await mutateAdminUpdateShopperStatus({
          id: partnerId,
          status,
          ...(state.comment ? { data: { reason: state.comment } } : {})
        })
        if (result?.isSuccess) {
          setPartnerData({ ...result?.partner })
        } else {
          message.error(result?.errorMessage || 'Ошибка доступа к данным')
          setState(initialState)
        }
      } catch (error) {
        console.error(error)
        setState(initialState)
      }
    }
  }

  const {
    data: dataDeclineReasons,
    isSuccess: isSuccessReasons
  } = useDeclineReasonsQuery(AppConfig.reasonTargets.verification)

  useEffect(() => {
    if (isSuccessReasons && dataDeclineReasons?.isSuccess) {
      setState({
        declineReasons: dataDeclineReasons.result.map(item => ({ label: item.reason, value: item.reason }))
      })
    }
  }, [dataDeclineReasons, isSuccessReasons])

  const handleSave = () => handleSubmitAccountStatus(state.status)
  const handleStatusChange = e => setState({ status: e.target.value })
  const handleCommentChange = e => setState({ comment: e.target.value })
  const handleAutoCommentChange = (item) => {
    setState({
      comment: state.comment ? `${state.comment} ${item}` : item
    })
  }
  const handleAutoCommentClear = () => setState({ comment: null })

  const renderShopperNotRequired = () => {
    return (
      <Space>
        Аккаунт в Шоппере не требуется
        <Button
          type='primary'
          disabled={loading || isLoadingAdminUpdateShopperStatus}
          onClick={() => handleSubmitAccountStatus(AppConfig.shopperStatuses.verified)}
        >
          Верифицировать
        </Button>
      </Space>
    )
  }

  const renderShopperStatusSelector = () => {
    return (
      <>
        <Space>
          <Radio.Group onChange={handleStatusChange} value={state.status}>
            <Radio value={AppConfig.shopperStatuses.verified}>
              Аккаунт в шоппере верифицирован
            </Radio>
            <Radio value={AppConfig.shopperStatuses.notfound}>
              Ошибка в верификации
            </Radio>
          </Radio.Group>
          <Button type='primary' disabled={loading} onClick={handleSave}>Сохранить</Button>
        </Space>
        {state.status === AppConfig.shopperStatuses.notfound && (
          <>
            <Space style={{ width: '100%' }}>
              <AutoComplete
                options={state.declineReasons}
                style={{ width: '100%' }}
                onSelect={handleAutoCommentChange}
                allowClear
                onClear={handleAutoCommentClear}
                defaultValue={state.comment}
                value={state.comment}
                status='error'
              >
                <Input
                  style={{ width: 490, ...(state.errorComment && { borderColor: '#ff4d4f' }) }}
                  defaultValue={state.comment}
                  value={state.comment}
                  onChange={handleCommentChange}
                  placeholder='Введите причину'
                />
              </AutoComplete>
            </Space>
            {
              state.errorComment && (
                <div style={{ width: '100%' }}>
                  <Text type='danger'>Введите причину</Text>
                </div>
              )
            }
          </>
        )}
        <Space>
          <Button type='link' target='_blank' href={getShopperPath(profile?.personalData?.mobilePhone)}>Найти партнера в Шоппере</Button>
        </Space>
      </>
    )
  }

  if (!profile?.shopperAccount?.allowEdit) return null

  return AppConfig.partnerAdditionalRoles.includes(profile?.personalData?.roleCode)
    ? renderShopperNotRequired()
    : renderShopperStatusSelector()
}

export default ShopperStatus
