import React, { useEffect, useReducer } from 'react'
import { Input, Select } from 'antd'
import { simpleReducer } from '../../../helper'

const initialState = {
  reason: null,
  description: null
}

function ReasonSelectorWithAdditional ({
  options,
  descriptions,
  onSelect,
  onInput,
  multiline = false,
  multilineRows = 4
}) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleSelect = item => {
    onSelect?.(item)
    const description = descriptions?.find(i => i.reason === item)?.description
    setState({ reason: item, description })
    onInput?.(description)
  }

  const handleClearSelect = () => {
    onSelect?.(null)
    setState(initialState)
  }

  const handleInput = e => {
    onInput?.(e.target.value)
    setState({ description: e.target.value })
  }

  useEffect(() => {
    const reason = options?.[0].value
    const description = descriptions?.[0]?.description
    setState({ reason, description })
    onSelect?.(reason)
    onInput?.(description)
  }, [options, descriptions])

  return (
    <>
      <Select
        style={{ width: '100%', marginBottom: '1rem' }}
        onSelect={handleSelect}
        options={options}
        allowClear
        onClear={handleClearSelect}
        placeholder='Причина отклонения'
        value={state.reason}
      />
      {
        multiline
          ? (
            <Input.TextArea
              style={{ width: '100%', marginBottom: '1rem' }}
              rows={multilineRows}
              onChange={handleInput}
              placeholder='Введите уточнение'
              allowClear
              value={state.description}
            />
          )
          : (
            <Input
              style={{ width: '100%', marginBottom: '1rem' }}
              onChange={handleInput}
              placeholder='Введите уточнение'
              allowClear
              value={state.description}
            />
          )

      }
    </>
  )
}

export default ReasonSelectorWithAdditional
