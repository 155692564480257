import { Form, Input, PageHeader, Select } from 'antd'
import React, { useEffect, useMemo } from 'react'

import { AppConfig, selfEmployed } from '../../../AppConfig'
import { profileScreen } from '../../../translates'
import { useStores } from '../../../Store/Store'
import {
  BirthDateFormItem,
  CitizenshipFormItem,
  NameFormItem,
  EmailFormItem,
  PassportBlock
} from '../../Common'
import {
  createApiDataFromForm,
  trueFalseList,
  validateDate,
  validateName,
  validatePhone,
  getBirthDateRules
} from '../../../helper'
import {
  usePartnerRoles
} from '../../../hooks'
import { getHeaders } from '../../../api/react-query/api'
import MiddleNameFormItem from '../../Common/FormItems/MiddleNameFormItem'
import LegalAgeCapableItem from '../../Common/FormItems/LegalAgeCapableItem'
import { useMutateLog } from '../../../api/react-query/log'
import ModalChangePhone from './ModalChangePhone'

const { Option } = Select

const PersonalDataSelfEmployed = ({ form, doFetchProfileCheck, isLoading, setRole, trigger, hasNfc }) => {
  const {
    partnerProfile,
    partnerProfile: {
      visible,
      required,
      updateForm,
      profile: {
        personalData
      }
    } = {}
  } = useStores()
  const { data: roles, isLoading: isLoadingRoles } = usePartnerRoles()
  const { mutate: sendLog } = useMutateLog()

  const isSzContent = useMemo(() => {
    return personalData?.roleCode === AppConfig.partnerRoles.szContent
  }, [personalData?.roleCode])

  const handleUpdateForm = () => updateForm({ ...form.getFieldsValue() })

  const handleChangeLegal = () => {
    handleChangeFormAndCheck()
    sendLog({
      targetId: `partner/${partnerProfile?.id}`,
      action: 'ProfileFillingStarted'
    })
  }

  const handleChangeFormAndCheck = () => {
    const values = form.getFieldsValue()
    values.personalData = {
      ...values.personalData,
      ...(hasNfc !== null && { hasNfc })
    }
    const info = createApiDataFromForm(values)

    doFetchProfileCheck('partner/profile/check', {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        info
      })
    })
  }

  const handleChangePhone = (newPhone) => {
    form.setFieldsValue({
      personalData: {
        mobilePhone: newPhone
      }
    })
  }

  useEffect(() => {
    if (AppConfig.partnerAdditionalRoles.includes(personalData?.roleCode)) {
      setRole(personalData?.roleCode)
    }
  }, [personalData?.roleCode, setRole])

  const isShowLegalAgeCapable = useMemo(() => {
    return personalData?.isLegalAgeCapable === false || personalData?.isLegalAgeCapable === true
  }, [personalData?.isLegalAgeCapable])

  if (!visible.personalData) return null

  return (
    <>
      <PageHeader title={profileScreen.title} />

      <Form.Item
        name={['personalData', profileScreen.input.legalForm.name]}
        label={profileScreen.input.legalForm.label}
        rules={[{ required: required?.personalData, message: profileScreen.input.legalForm.message }]}
        hidden={isSzContent}
        initialValue={selfEmployed}
      >
        <Select
          size='large'
          placeholder={profileScreen.input.legalForm.placeholder}
          onChange={handleChangeLegal}
          loading={isLoading}
        >
          {
            AppConfig.legalForms.map(c => <Option key={c} value={c}>{c}</Option>)
          }
        </Select>
      </Form.Item>
      {/* <Form.Item */}
      {/*  className='text-align-left' */}
      {/* > */}
      {/*  <div className='ant-col ant-form-item-label'> */}
      {/*    <label className='ant-form-item-required'> */}
      {/*      {profileScreen.input.notFormerCivilEmployee.label} */}
      {/*      <Tooltip placement='top' title={profileScreen.input.notFormerCivilEmployee.hint} trigger='click' color='white' overlayInnerStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}> */}
      {/*        <QuestionCircleOutlined className='ml-2 mr-3' /> */}
      {/*      </Tooltip> */}
      {/*      <Form.Item */}
      {/*        noStyle */}
      {/*        name='notFormerCivilEmployee' */}
      {/*        valuePropName='checked' */}
      {/*        rules={[ */}
      {/*          { */}
      {/*            transform: value => (value || undefined), */}
      {/*            type: 'boolean' */}
      {/*          }, */}
      {/*          { */}
      {/*            required: true, */}
      {/*            validator: (rule, value, callback) => { */}
      {/*              if (value) { */}
      {/*                return callback() */}
      {/*              } */}
      {/*              return callback(profileScreen.input.notFormerCivilEmployee.message) */}
      {/*            } */}
      {/*          } */}
      {/*        ]} */}
      {/*      > */}
      {/*        <Checkbox /> */}
      {/*      </Form.Item> */}
      {/*    </label> */}
      {/*  </div> */}
      {/* </Form.Item> */}
      <NameFormItem
        name={['personalData', 'surname']}
        placeholder={profileScreen.input.surname.placeholder}
        rules={[{ required: true, message: profileScreen.input.surname.message, validator: validateName }]}
        label={profileScreen.input.surname.label}
      />
      <NameFormItem
        name={['personalData', 'firstName']}
        placeholder={profileScreen.input.firstName.placeholder}
        rules={[{ required: true, message: profileScreen.input.firstName.message, validator: validateName }]}
        label={profileScreen.input.firstName.label}
      />
      <MiddleNameFormItem form={form} onUpdate={handleUpdateForm} trigger={trigger} />

      <Form.Item
        name={['personalData', 'mobilePhone']}
        label={profileScreen.input.mobilePhone.label}
        rules={[{ required: required?.personalData, message: profileScreen.input.mobilePhone.message, validator: validatePhone }]}
        help={
          <ModalChangePhone onSuccess={handleChangePhone} />
        }
      >
        <Input
          size='large'
          disabled
          type='tel'
          placeholder={profileScreen.input.mobilePhone.placeholder}
        />
      </Form.Item>
      {
        personalData?.emailRequired && <EmailFormItem />
      }
      <Form.Item
        name={['personalData', 'role']}
        label={profileScreen.input.role.label}
        rules={[{ required: required?.personalData, message: profileScreen.input.role.message }]}
      >
        <Select
          size='large'
          placeholder={profileScreen.input.role.placeholder}
          onChange={(role, option) => {
            // TODO: Костыль пока не уберут роль курьера
            if (option.key === AppConfig.partnerRoles.courier) {
              setRole(AppConfig.partnerRoles.automotiveCourier)
            } else {
              setRole(option.key)
            }
            handleUpdateForm()
          }}
          loading={isLoadingRoles}
          disabled
        >
          {
            roles.map(role => <Option key={role.id} value={role.name}>{role.name}</Option>)
          }
        </Select>
      </Form.Item>

      <CitizenshipFormItem
        label={profileScreen.input.citizenship.label}
        placeholder={profileScreen.input.citizenship.placeholder}
        rules={[{ required: required?.personalData, message: profileScreen.input.citizenship.message }]}
        disabled
      />

      <BirthDateFormItem
        label={profileScreen.input.birthDate.label}
        placeholder={profileScreen.input.birthDate.placeholder}
        rules={[
          { required: required?.personalData, message: profileScreen.input.birthDate.message, validator: validateDate },
          ...getBirthDateRules()
        ]}
        onBlur={() => {
          form.validateFields([['personalData', 'birthDate']]).then(() => {
            handleChangeFormAndCheck()
          })
        }}
      />

      {isShowLegalAgeCapable && <LegalAgeCapableItem onChange={handleChangeFormAndCheck} />}

      <PassportBlock />

      {/* <InnFormItem */}
      {/*  label={profileScreen.input.inn.label} */}
      {/*  placeholder={profileScreen.input.inn.placeholder} */}
      {/*  rules={[ */}
      {/*    { required: required?.personalData, message: profileScreen.input.inn.message, validator: validateInn }, */}
      {/*    { message: errors.innCheckSum, validator: (rule, value) => validateFullInn({ rule, inn: value }) } */}
      {/*  ]} */}
      {/*  classNameInput='noScrollNumber' */}
      {/* /> */}

      <Form.Item
        name={['personalData', profileScreen.input.vaccinated.name]}
        label={profileScreen.input.vaccinated.label}
        rules={[{ required: required?.personalData, message: profileScreen.input.vaccinated.message }]}
        hidden
      >
        <Select
          size='large'
          placeholder={profileScreen.input.vaccinated.placeholder}
          onChange={handleChangeFormAndCheck}
        >
          {
            trueFalseList.map(c => <Option key={c.label} value={c.value}>{c.label}</Option>)
          }
        </Select>
      </Form.Item>
    </>
  )
}
export default PersonalDataSelfEmployed
