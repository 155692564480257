import React, { useReducer, useContext } from 'react'
import { Button } from 'antd'
import { simpleReducer } from '../../../helper'
import { ConfigContext } from '../../../context/configContext'
import UserCitiesModal from './UserCitiesModal'

const initialState = {
  isModalOpen: false
}

function UserCitiesButton () {
  const { isCoordinator } = useContext(ConfigContext)
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleClick = () => {
    setState({ isModalOpen: true })
  }

  const handleClose = () => {
    setState({ isModalOpen: false })
  }

  if (!isCoordinator) {
    return null
  }

  return (
    <>
      <Button
        onClick={handleClick}
      >
        Задать города в приоритете
      </Button>
      {state.isModalOpen && (
        <UserCitiesModal
          visible={state.isModalOpen}
          onCancel={handleClose}
          title='Приоритетные города для выбора анкет'
        />
      )}
    </>
  )
}

export default UserCitiesButton
