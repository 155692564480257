import { partnerStatusCodes } from '../../../AppConfig'
import { Alert } from 'antd'
import { errors } from '../../../translates'
import React from 'react'
import { useStores } from '../../../Store/Store'

const ErrorBlock = ({ title = errors.reasonsTitle }) => {
  const { partnerProfile } = useStores()

  if (partnerProfile?.profile?.statusCode !== partnerStatusCodes.correction) return null

  const description =
    <ul>
      {partnerProfile?.errors?.map((error, index) => (
        error && <li key={index + error}>{error}</li>
      ))}
    </ul>

  return (
    <Alert
      message={title}
      description={description}
      type='error'
    />
  )
}
export default ErrorBlock
