import React, { useContext, useReducer, useMemo } from 'react'
import './AdminPartnersStats.css'
import { ConfigContext } from '../../../context/configContext'
import { ADMIN_PARTNERS_PAGE } from '../../../routes'
import { simpleReducer } from '../../../helper'
import PartnersStatsList from './PartnersStatsList'
import { Row, Col } from 'antd'
import { AppConfig } from '../../../AppConfig'
import { useGetStatsListQuery } from '../../../api/react-query/stats'
import { oneMinute } from '../../../Constants'

const initialState = {
  isModalOpen: false,
  statType: null
}

function AdminPartnersStats () {
  const { isAdmin, isCoordinator, isPartnerMonitor } = useContext(ConfigContext)
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    data: dataPartnersStats
  } = useGetStatsListQuery({
    refetchInterval: oneMinute,
    enabled: isAdmin || isCoordinator || isPartnerMonitor
  })

  const partnersStats = useMemo(() => {
    return dataPartnersStats?.partnersListStats
  }, [dataPartnersStats])

  const handleClose = () => setState({ isModalOpen: false })
  const handleOpen = (statType) => setState({ isModalOpen: true, statType })

  const handleClickCoordinatorsOnline = () => handleOpen(AppConfig.partnerStatTypes.coordinatorsOnline)
  const handleClickCardForm = () => handleOpen(AppConfig.partnerStatTypes.bankRequisiteForms)
  const handleClickEmploymentType = () => handleOpen(AppConfig.partnerStatTypes.employmentTypeForms)
  const handleClickEditInfo = () => handleOpen(AppConfig.partnerStatTypes.profileForms)
  const handleClickNewPartners = () => handleOpen(AppConfig.partnerStatTypes.newPartners)

  if ((!isAdmin && !isCoordinator && !isPartnerMonitor) || location.pathname !== ADMIN_PARTNERS_PAGE) {
    return null
  }

  return (
    <>
      <div className='partners-stats'>
        <Row>
          <Col span={14}>Анкет ожидающих проверки</Col>
          <Col span={10}>Загружают документы: {partnersStats?.activePartners || 0}</Col>
        </Row>
        <Row>
          <Col span={7}>
            <a onClick={handleClickNewPartners}>Новые</a>: {partnersStats?.waitingPartners || 0}
          </Col>
          <Col span={7}>
            <a onClick={handleClickEditInfo}>Корректирующие</a>: {partnersStats?.waitingProfileForms || 0}
          </Col>
          <Col span={10}>Анкет в работе: {partnersStats?.takenPartners || 0}</Col>
        </Row>
        <Row>
          <Col span={7}>
            <a onClick={handleClickCardForm}>Банковские</a>: {partnersStats?.waitingBankRequisiteForms || 0}
          </Col>
          <Col span={7}>
            <a onClick={handleClickEmploymentType}>Смена занятости</a>: {partnersStats?.waitingEmploymentTypeForms || 0}
          </Col>
          <Col span={10}>
            {
              isAdmin
                ? (<a onClick={handleClickCoordinatorsOnline}>Координаторов на портале</a>)
                : (<>Координаторов на портале</>)
            }: {partnersStats?.coordinatorsOnline || 0}
          </Col>
        </Row>
      </div>
      {
        (isAdmin || isCoordinator) && state.isModalOpen &&
          <PartnersStatsList isModalOpen={state.isModalOpen} onClose={handleClose} statType={state.statType} />
      }
    </>
  )
}

export default AdminPartnersStats
