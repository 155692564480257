import { Modal, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

const { Paragraph } = Typography

const CreateActWithAssetsPreview = ({ preview, onClose, onSave, title }) => {
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)

  const handleCloseViewModal = () => {
    onClose()
    setIsViewModalOpen(false)
  }

  const handleSavePreview = () => onSave()

  useEffect(() => {
    if (preview) setIsViewModalOpen(true)
  }, [preview])

  return (
    <Modal
      width={850}
      style={{ top: 40 }}
      visible={isViewModalOpen}
      title={title}
      onCancel={handleCloseViewModal}
      okText='Ок'
      cancelButtonProps={{ className: 'hide' }}
      onOk={handleSavePreview}
      destroyOnClose
    >
      <Paragraph className='document'>{preview}</Paragraph>
    </Modal>
  )
}
export default CreateActWithAssetsPreview
