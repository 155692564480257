import { api, queryClient } from './api'
import { useQuery, useMutation } from 'react-query'
import { ADMIN_PARTNERS_KEY } from './adminPartners'

export const USERS_KEY = 'users'
export const USER_PROFILE_KEY = 'profile'
export const USER_GOUPS_KEY = 'groups'
const USER_SBIS_KEY = 'users_sbis'
export const USERS_ONLINE_KEY = 'usersOnline'
const USERS_PRIORITY_CITIES = 'usersPriorityCities'

const fetchProfile = () => {
  return api.get({ path: `${USERS_KEY}/me` })
}
const fetchUserGroups = () => {
  return api.get({ path: `${USERS_KEY}/${USER_GOUPS_KEY}` })
}
const fetchUserById = ({ queryKey }) => {
  const [/* key */, id] = queryKey
  return api.get({ path: `${USERS_KEY}/${id}` })
}
const fetchUsers = ({ queryKey }) => {
  const [/* key */, pagination] = queryKey
  return api.post({ path: `${USERS_KEY}/search`, data: pagination })
}
const fetchUsersOnline = () => {
  return api.get({ path: `${USERS_KEY}/online` })
}
const fetchPriorityCitiesByUser = ({ queryKey }) => {
  const [/* key */, /* key */, id] = queryKey
  return api.get({ path: `${USERS_KEY}/${id}/priorityCities` })
}
const fetchSetPriorityCitiesByUser = ({ id, data }) => {
  return api.post({ path: `${USERS_KEY}/${id}/priorityCities`, data })
}
const fetchUserBlock = id => {
  return api.post({ path: `${USERS_KEY}/${id}/block` })
}
const fetchUserUnblock = id => {
  return api.post({ path: `${USERS_KEY}/${id}/unblock` })
}
const fetchUserChangeScope = data => {
  return api.post({ path: `${USERS_KEY}/me/scope`, data })
}
const fetchUserStatus = ({ data }) => {
  return api.post({ path: `${USERS_KEY}/me/status`, data })
}
const fetchGetUserSbisDepartments = () => {
  return api.get({ path: `${USERS_KEY}/me/sbis/departments` })
}
const fetchGetUserPriorityCities = () => {
  return api.get({ path: `${USERS_KEY}/me/priorityCities` })
}
const fetchGetPriorityPartnerSelector = () => {
  return api.get({ path: `${USERS_KEY}/me/priorityPartnerSelector` })
}
const fetchGetUserRequisitePriority = () => {
  return api.get({ path: `${USERS_KEY}/me/bankRequisitePriority` })
}
const fetchSetUserPriorityCities = (data) => {
  return api.post({ path: `${USERS_KEY}/me/priorityCities`, data })
}
const fetchUserCreate = data => {
  return api.post({ path: `${USERS_KEY}/create`, data })
}
const fetchUserUpdate = data => {
  return api.post({ path: `${USERS_KEY}/update`, data })
}
const fetchUserPriorityPartnerSelector = ({ userId, selector }) => {
  return api.post({ path: `${USERS_KEY}/${userId}/priorityPartnerSelector`, data: { selector } })
}

export function useGetProfileQuery (options) {
  return useQuery([USER_PROFILE_KEY], fetchProfile, options)
}
export function useGetUserGroupsQuery (options) {
  return useQuery([USER_GOUPS_KEY], fetchUserGroups, options)
}
export function useGetUserById (id, options) {
  return useQuery([USERS_KEY, id], fetchUserById, options)
}
export function useUsersSearch (pagination, options) {
  return useQuery([USERS_KEY, pagination], fetchUsers, options)
}
export function useUsersOnline (options) {
  return useQuery([USERS_KEY, USERS_ONLINE_KEY], fetchUsersOnline, options)
}
export function useUserSbisDepartments (actType, options) {
  return useQuery([USER_SBIS_KEY, actType], fetchGetUserSbisDepartments, options)
}
export function useUserPriorityCities (options) {
  return useQuery([USERS_KEY, USERS_PRIORITY_CITIES], fetchGetUserPriorityCities, options)
}
export function usePriorityCitiesByUser (id, options) {
  return useQuery([USERS_KEY, USERS_PRIORITY_CITIES, id], fetchPriorityCitiesByUser, options)
}
export function useMutateUserBlock () {
  return useMutation(fetchUserBlock, {
    onSuccess: (data, id) => {
      queryClient.refetchQueries([USERS_KEY, id])
    }
  })
}
export function useMutateUserUnblock () {
  return useMutation(fetchUserUnblock, {
    onSuccess: (data, id) => {
      queryClient.refetchQueries([USERS_KEY, id])
    }
  })
}
export function useMutateUserChangeScope () {
  return useMutation(fetchUserChangeScope, {
    onSuccess: (data, id) => {
      queryClient.refetchQueries(USER_PROFILE_KEY)
      queryClient.refetchQueries(USERS_KEY)
    }
  })
}
export function useMutateUserStatus () {
  return useMutation(fetchUserStatus, {
    onSuccess: (responseData, payload) => {
      queryClient.refetchQueries(USER_PROFILE_KEY)
      queryClient.refetchQueries(USERS_KEY)
      if (payload?.isExternalCoordinator) {
        queryClient.refetchQueries(ADMIN_PARTNERS_KEY, { active: true })
      }
    }
  })
}
export function useMutateUserCreate () {
  return useMutation(fetchUserCreate, {
    onSuccess: (data, id) => {
      queryClient.refetchQueries(USERS_KEY, { active: true })
    }
  })
}
export function useMutateUserUpdate () {
  return useMutation(fetchUserUpdate, {
    onSuccess: (data, id) => {
      queryClient.refetchQueries(USERS_KEY, { active: true })
    }
  })
}
export function useMutateUserPriorityCities () {
  return useMutation(fetchSetUserPriorityCities)
}
export function useMutatePriorityCitiesByUser () {
  return useMutation(fetchSetPriorityCitiesByUser, {
    onSuccess: () => {
      queryClient.refetchQueries([USERS_KEY, USERS_ONLINE_KEY], { active: true })
    }
  })
}
export function useMutateUserRequisitePriority () {
  return useMutation(fetchGetUserRequisitePriority)
}
export function useMutateUserPriorityPartnerSelector () {
  return useMutation(fetchGetPriorityPartnerSelector)
}
export function useMutateUserSetPriorityPartnerSelector () {
  return useMutation(fetchUserPriorityPartnerSelector, {
    onSuccess: (data, variables) => {
      queryClient.resetQueries([USERS_KEY, USERS_ONLINE_KEY], { active: true })
    }
  })
}
