import React, { useEffect, useReducer } from 'react'
import { Checkbox, Form } from 'antd'
import { Link } from 'react-router-dom'
import { PROFILE_MAIN_PAGE } from '../../../../routes'
import { simpleReducer } from '../../../../helper'
import { offerScreen } from '../../../../translates'
import { policyTypes } from '../../../../Constants'
import { fromEditTypes } from '../../../Partner/PolicyAgreement/PolicyAgreement'
import { AppConfig } from '../../../../AppConfig'

const initialState = {
  fillErrorStatus: null
}

const messageErrors = {
  [fromEditTypes.editLmkWithoutPassport]: 'У вас не заполнены паспортные данные:',
  [fromEditTypes.editLmk]: 'Заполните необходимые поля:',
  [fromEditTypes.editInfo]: 'Заполните необходимые поля:'
}

const styleForDisabledWrapper = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: 20,
  height: '100%',
  // border: '1px solid red',
  zIndex: 1000
}

function MedicalAcceptOffer ({
  disabled = false,
  errors = [],
  onMedicalOfferShow,
  fromEdit = null
}) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    setState(initialState)
  }, [disabled])

  const getMessageError = () => {
    if (state.fillErrorStatus) {
      const message = (messageErrors[fromEdit] || messageErrors[fromEditTypes.editInfo])
      const errorList = errors?.join(', ')
      return (
        <>
          {message} {errorList}{fromEdit === fromEditTypes.editLmkWithoutPassport
            ? (
              <>
                . Подайте недостающие данные через
                <Link to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.editInfo}`}>Корректирующую анкету</Link>
              </>
            )
            : ''}
        </>
      )
    }
    return null
  }

  const handleClickLink = (e) => {
    if (disabled) {
      e.preventDefault()
      setState({ fillErrorStatus: 'error' })
    } else {
      onMedicalOfferShow?.()
    }
  }

  const handleClickWrapper = (e) => {
    if (disabled) {
      e.preventDefault()
      setState({ fillErrorStatus: 'error' })
    }
  }

  return (
    <Form.Item
      className='text-align-left'
      name='medicalAcceptOffer'
      valuePropName='checked'
      rules={[{
        required: true,
        transform: value => (value || undefined),
        type: 'boolean',
        message: offerScreen.lmkAgreement
      }]}
      validateStatus='error'
      help={getMessageError()}
    >
      <Checkbox disabled={disabled}>
        <div
          onClick={handleClickWrapper}
          style={styleForDisabledWrapper}
        />
        <Link
          to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.lmkAgreement}${fromEdit ? `&fromEdit=${fromEdit}` : ''}`}
          onClick={handleClickLink}
        >
          Согласие на обработку персональных данных (ЛМК)
        </Link>
      </Checkbox>
    </Form.Item>
  )
}

export default MedicalAcceptOffer
