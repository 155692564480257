import React from 'react'
import SberIcon from './SberIcon'
import { flattenObject2, formatDateForFrontend, isShownSberIcon } from '../../helper'

function CheckSberIcon ({ forceShow = false, profileAndSberIdProperty, profile, style }) {
  const getMultiProp = (profile, profileProp, propList) => {
    const flattenedObject = flattenObject2(profile)
    return propList.map(prop => {
      const val = flattenedObject[`${profileProp}.${prop}`]
      return prop === 'passport.issueDate' ? formatDateForFrontend(val) : val
    }).join('').trim()
  }

  if (forceShow) return <SberIcon style={style} />

  if (profileAndSberIdProperty === 'passport.series passport.number') {
    const propPassport = ['passport.series', 'passport.number']
    const personalDataPassport = getMultiProp(profile, 'personalData', propPassport)
    const sberIdDataPassport = getMultiProp(profile, 'sberIdData', propPassport)
    return personalDataPassport === sberIdDataPassport && personalDataPassport && sberIdDataPassport
      ? <SberIcon style={style} />
      : null
  }

  if (profileAndSberIdProperty === 'passport.issuerName passport.issuerCode passport.issueDate') {
    const propIssuer = [
      'passport.issuerName',
      'passport.issuerCode',
      'passport.issueDate'
    ]
    const personalDataIssuer = getMultiProp(profile, 'personalData', propIssuer)
    const sberIdDataIssuer = getMultiProp(profile, 'sberIdData', propIssuer)
    return personalDataIssuer === sberIdDataIssuer && personalDataIssuer && sberIdDataIssuer
      ? <SberIcon style={style} />
      : null
  }

  if (profileAndSberIdProperty === 'registrationAddress') {
    const propAddress = [
      'registrationAddress.zipCode',
      'registrationAddress.country',
      'registrationAddress.region',
      'registrationAddress.district',
      'registrationAddress.city',
      'registrationAddress.settlement',
      'registrationAddress.street',
      'registrationAddress.house',
      'registrationAddress.building',
      'registrationAddress.apartment'
    ]
    const personalDataAddress = getMultiProp(profile, 'personalData', propAddress)
    const sberIdDataAddress = getMultiProp(profile, 'sberIdData', propAddress)
    return personalDataAddress === sberIdDataAddress && personalDataAddress && sberIdDataAddress
      ? <SberIcon style={style} />
      : null
  }

  return isShownSberIcon(profileAndSberIdProperty, profile) ? <SberIcon style={style} /> : null
}

export default CheckSberIcon
