import React, { useCallback, useEffect, useMemo, useReducer, useContext } from 'react'
import { Button, Col, DatePicker, Form, message, Row, Select, Typography, Divider, Tag, Tooltip } from 'antd'
import moment from 'moment-timezone'

import './Report.css'
import { simpleReducer, sortByName } from '../../../helper'
import FilterTitle from './FilterTitle'
import { AppConfig } from '../../../AppConfig'
import { useChains } from '../../../hooks'
import { useOrgTreeQuery } from '../../../api/react-query/org'
import ReportList from '../../../components/Admin/Reports/ReportList'
import { useGetReportsQueue, useMutateDispatchReportToQueue } from '../../../api/react-query/reports'
import { ConfigContext } from '../../../context/configContext'
import { useGetAssetsAllListQuery } from '../../../api/react-query/assets'
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons'
import { useAssetStatusesQuery } from '../../../api/react-query/dict'

const { Title, Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const now = moment()
const initialStart = moment().subtract(6, 'days')
const selectSearchFilter = (input, option) => {
  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const selectTagsSearchFilter = (input, option) => {
  return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export default function AssetsReport () {
  const { isPersonalManager } = useContext(ConfigContext)
  const [form] = Form.useForm()
  const initialState = {
    start: initialStart,
    end: now,
    cities: [],
    chains: [],
    selectedCities: [],
    selectedChains: [],
    filteredChains: [],
    isLoadingOldReport: false,
    currentLoadingReportId: null
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    isSuccess: isSuccessOrgTree,
    isError: isErrorOrgTree,
    data: dataOrgTree,
    isLoading: isLoadingOrgTree
  } = useOrgTreeQuery({
    withEmptyCity: true,
    withClosed: true,
    withOpened: true
  }, {
    enabled: !isPersonalManager
  })

  const {
    isError: isErrorReportsQueue,
    data: dataReportsQueue,
    isLoading: isLoadingReportsQueue,
    refetch: refetchReportsQueue
  } = useGetReportsQueue(AppConfig.report.types.assets)

  const {
    mutate: addAssetReportToQueue,
    isError: isErrorAddAssetReportToQueue,
    data: dataAddAssetReportToQueue,
    isLoading: isLoadingAddAssetReportToQueue
  } = useMutateDispatchReportToQueue()

  useEffect(() => {
    if (isSuccessOrgTree && dataOrgTree?.isSuccess) {
      const cities = sortByName(dataOrgTree?.cities)
      setState({ cities })
    }
    if (isErrorOrgTree || dataOrgTree?.errorMessage) {
      message.error(dataOrgTree?.errorMessage || dataOrgTree?.title || 'Ошибка доступа к данным')
    }
  }, [dataOrgTree, isSuccessOrgTree, isErrorOrgTree])

  useEffect(() => {
    if (isErrorAddAssetReportToQueue || dataAddAssetReportToQueue?.errorMessage) {
      message.error(dataAddAssetReportToQueue?.errorMessage || 'Не удалось поставить отчет в очередь')
    }
  }, [isErrorAddAssetReportToQueue, dataAddAssetReportToQueue])

  useEffect(() => {
    if (isErrorReportsQueue || dataReportsQueue?.errorMessage) {
      message.error(dataReportsQueue?.errorMessage || 'Не удалось получить отчеты')
    }
  }, [isErrorReportsQueue, dataReportsQueue])

  const { data: chains } = useChains({}, { enabled: !isPersonalManager })
  useEffect(() => {
    if (chains) {
      setState({ chains, filteredChains: chains })
    }
  }, [chains])

  const handleChangeCities = useCallback(
    (value, items) => {
      setState({
        selectedCities: items.map(e => e.key),
        filteredChains: items.length
          ? state.cities
            .filter(city => items.map(e => e.key).includes(city.id))
            .reduce((prev, curr) => {
              const chainsIds = prev.map(chain => chain.id)
              curr.chains.map(chain => {
                if (!chainsIds.includes(chain.id)) {
                  prev.push(chain)
                }
              })
              return prev
            }, [])
          : state.chains,
        selectedChains: []
      })
      form.resetFields(['chains'])
    },
    [form, state]
  )
  const handleChangeChains = useCallback(
    (value, item) => setState({ selectedChains: item.map(e => e.key) }),
    []
  )

  const datePickerDefaultValue = useMemo(() => [state.start, state.end], [state.start, state.end])

  const getReportQueryData = (variant) => ({
    cityIds: state.selectedCities.length ? state.selectedCities : undefined,
    chainIds: state.selectedChains.length ? state.selectedChains : undefined,
    assetIds: form.getFieldValue('assets')?.length ? form.getFieldValue('assets') : undefined,
    minCreationDate: moment(state.start)
      .set({ hour: 0, minute: 0, second: 0 })
      .format(AppConfig.formats.shortDateAndTimeApi),
    maxCreationDate: moment(state.end)
      .set({ hour: 24, minute: 0, second: 0 })
      .format(AppConfig.formats.shortDateAndTimeApi),
    variant
  })

  const handleAddReportToQueue = (reportVersion) => {
    addAssetReportToQueue({
      type: AppConfig.report.types.assets,
      data: getReportQueryData(reportVersion)
    })
  }

  const handleChangeFilter = ([start, end]) => setState({ start, end })

  const {
    data: dataAllAssets,
    isError: isErrorAllAssets,
    isLoading: isLoadingAllAssets
  } = useGetAssetsAllListQuery()

  useEffect(() => {
    if (isErrorAllAssets) {
      console.error(dataAllAssets)
      message.error('Ошибка получения списка имущества')
    }
  }, [isErrorAllAssets, dataAllAssets])

  const allAssets = useMemo(() => (dataAllAssets?.result || []), [dataAllAssets?.result])

  const {
    data: assetsData,
    isError: isErrorAssets
  } = useAssetStatusesQuery({})

  useEffect(() => {
    if (isErrorAssets) {
      console.error(assetsData)
      message.error('Ошибка получения списка типов имущества')
    }
  }, [assetsData, isErrorAssets])
  const assetStatuses = useMemo(() => (assetsData?.result || []), [assetsData?.result])

  // const handleChange = (value) => {
  //   console.log(`selected ${value}`)
  // }

  return (
    <>
      <Title level={3} className='title-management'>Отчет по имуществу</Title>
      <Form
        form={form}
      >

        <FilterTitle title='Период'>
          <Form.Item>
            <RangePicker
              defaultValue={datePickerDefaultValue}
              onChange={handleChangeFilter}
            />
          </Form.Item>
        </FilterTitle>

        {!isPersonalManager && (
          <>
            <FilterTitle title='Город'>
              <Form.Item name='cities'>
                <Select
                  loading={isLoadingOrgTree}
                  onChange={handleChangeCities}
                  size='middle'
                  placeholder='Выберите город'
                  showSearch
                  optionFilterProp='children'
                  filterOption={selectSearchFilter}
                  allowClear
                  mode='multiple'
                >
                  {state.cities?.map(city => (
                    <Option key={city.id} value={city.name}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </FilterTitle>

            <FilterTitle title='Сеть'>
              <Form.Item name='chains'>
                <Select
                  onChange={handleChangeChains}
                  size='middle'
                  placeholder='Выберите сеть'
                  showSearch
                  filterOption={selectSearchFilter}
                  allowClear
                  mode='multiple'
                >
                  {state.filteredChains?.map(chain => (
                    <Option key={chain.id} value={chain.name}>
                      {chain.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </FilterTitle>
          </>
        )}

        <FilterTitle title='Имущество' spanInput={{ xl: 20, md: 18, xs: 12 }}>
          <Form.Item name='assets'>
            <Select
              mode='multiple'
              loading={isLoadingAllAssets}
              placeholder='Выберите имущество'
              // onChange={handleChange}
              filterOption={selectTagsSearchFilter}
              allowClear
              optionLabelProp='label'
              getPopupContainer={trigger => trigger.parentNode}

            >
              {allAssets?.map(asset => (
                <Option key={asset.id} value={asset.id} label={asset.name}>
                  <Row className='assets-selector-options'>
                    <Col span={18}>
                      <Text>{asset.name}</Text>
                    </Col>
                    <Col span={5}>
                      {asset.isSuit && <Tag key='suit' color='green'>Форма</Tag>}
                      {asset.hasSerial && <Tag key='hasSerial' color='blue'>С номером</Tag>}
                    </Col>
                    <Col span={1}>
                      <Tooltip title={`Статус ${assetStatuses.find(status => status.id === asset.status)?.name}`}>
                        {asset.status === 'active'
                          ? <CheckCircleOutlined style={{ color: 'green' }} />
                          : <StopOutlined style={{ color: 'red' }} />}
                      </Tooltip>
                    </Col>
                  </Row>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </FilterTitle>

        <Row>
          <Col className='mr-3'>
            <Button
              loading={isLoadingAddAssetReportToQueue}
              type='primary'
              htmlType='button'
              onClick={() => handleAddReportToQueue('v2')}
            >
              Запросить отчет
            </Button>
          </Col>
          <Col className='mr-3'>
            <Button
              loading={isLoadingAddAssetReportToQueue}
              htmlType='button'
              onClick={() => handleAddReportToQueue('v1')}
            >
              Отчет старого образца
            </Button>
          </Col>
        </Row>
      </Form>
      {
        dataReportsQueue?.items &&
          <>
            <Divider />
            <Row className='mt-3' gutter={15} align='middle'>
              <Col>
                <Title level={5}>Отчеты</Title>
              </Col>
              <Col>
                <Button onClick={() => refetchReportsQueue()}>Обновить</Button>
              </Col>
            </Row>
            <ReportList
              isLoadingReports={isLoadingReportsQueue}
              dataReports={dataReportsQueue?.items}
            />
          </>
      }
    </>
  )
}
