import React from 'react'
import { successScreen } from '../../../translates'
import { Alert, Typography } from 'antd'
import { useStores } from '../../../Store/Store'

const { Title, Text } = Typography

function NotFoundStep () {
  const { partnerProfile } = useStores()

  return (
    <div className='error-step'>
      <Title level={3}>{successScreen.loginNotFound}</Title>
      <Text>
        {
          partnerProfile?.profile?.declineReason && (
            <Alert
              description={partnerProfile?.profile?.declineReason}
              type='error'
            />
          )
        }
      </Text>
    </div>
  )
}

export default NotFoundStep
