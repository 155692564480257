import React from 'react'
import { useStores } from '../../../../Store/Store'
import { Button, Typography } from 'antd'
import './ErrorStep.css'
import { flowV2Steps } from '../ProfileV2'
import ErrorBlock from '../../Profile/ErrorBlock'
import { errors } from '../../../../translates'
import { getCorrectionStep } from '../../../../helper'

const { Title, Text } = Typography

export const errorStepLocalStorageName = 'errorStep'

function ErrorStep ({ setFlowStep }) {
  const { partnerProfile } = useStores()

  const handleClick = () => {
    const errorStep = getCorrectionStep(partnerProfile?.profile)
    localStorage.setItem(errorStepLocalStorageName, errorStep)
    return setFlowStep(errorStep)
  }

  return (
    <div className='error-step'>
      <Title level={3}>Ошибка данных</Title>
      <Text>
        Отредактируйте разделы:
        <ul className='error-step-section-list'>
          {getCorrectionStep(partnerProfile?.profile) === flowV2Steps.passportStep && (
            <li>&mdash; Паспортные данные</li>
          )}
          {getCorrectionStep(partnerProfile?.profile) === flowV2Steps.innStep && (
            <li>&mdash; Персональные данные</li>
          )}
        </ul>
      </Text>
      <ErrorBlock title={errors.commentTitle} />
      <br />
      <Button onClick={handleClick}>Исправить ошибки</Button>
    </div>
  )
}

export default ErrorStep
