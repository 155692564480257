import { Button, Typography, Modal, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'

import { useMutateAdminTerminatePartner } from '../../../../api/react-query/adminPartners'
import { useParams } from 'react-router-dom'

const { Text } = Typography

const ToBan = ({
  allowBan = false
}) => {
  const { id } = useParams()
  const [isShow, setIsShow] = useState(false)

  const {
    mutate: mutateTerminatePartner,
    data: dataTerminatePartner,
    isError: isErrorTerminatePartner,
    isLoading: isLoadingTerminatePartner,
    isSuccess: isSuccessTerminatePartner
  } = useMutateAdminTerminatePartner()

  useEffect(() => {
    if (isErrorTerminatePartner) {
      message.error(dataTerminatePartner?.errorMessage || 'Ошибка отправки Партнера в БАН.')
    }
  }, [dataTerminatePartner, isErrorTerminatePartner])
  useEffect(() => {
    if (isSuccessTerminatePartner) {
      if (dataTerminatePartner?.errorMessage) {
        return message.error(dataTerminatePartner?.errorMessage)
      }
      setIsShow(false)
      message.success('Партнер отправлен в БАН.')
    }
  }, [dataTerminatePartner, isSuccessTerminatePartner])

  const handleOnSave = () => mutateTerminatePartner({ id })
  const okButtonProps = useMemo(() => ({ className: 'green', loading: isLoadingTerminatePartner }), [isLoadingTerminatePartner])
  const cancelButtonProps = useMemo(() => ({ disabled: isLoadingTerminatePartner }), [isLoadingTerminatePartner])
  if (!allowBan) return null

  return (
    <>
      <Button onClick={() => setIsShow(true)} className='mb-3 ml-3' danger disabled={isLoadingTerminatePartner}>
        БАН
      </Button>
      <Modal
        width={700}
        visible={isShow}
        title='БАН Партнера'
        onCancel={() => setIsShow(false)}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
        onOk={handleOnSave}
      >
        <Text>Партнер не сможет больше оказывать услуги СМ и повторно зарегистрироваться на Портале</Text>
      </Modal>
    </>
  )
}
export default ToBan
