import React, { useEffect } from 'react'
import { Button, message } from 'antd'
import { useMutateSendErp } from '../../../../api/react-query/adminPartners'

function ErpButton ({ partnerId }) {
  const {
    data,
    isSuccess,
    isError,
    isLoading,
    mutate
  } = useMutateSendErp()

  const handleClick = () => {
    mutate({ id: partnerId })
  }

  useEffect(() => {
    if (isSuccess && !data?.errorMessage) {
      message.success('Запрос в ERP отправлен')
    } else if (isError || data?.errorMessage) {
      message.error(data?.errorMessage || 'Ошибка отправки данных в ERP')
    }
  }, [isSuccess, isError, data])

  return (
    <Button
      loading={isLoading}
      ghost
      type='primary'
      size='small'
      onClick={handleClick}
    >
      Отправить в ERP
    </Button>
  )
}

export default ErpButton
