import React from 'react'
import { PageHeader, Alert, Switch, Row, Col, Form, Input } from 'antd'
import { mdash, nbsp } from '../../../Constants'
import './ExistsNfc.css'

function ExistsNfc ({
  form,
  checked = false,
  onChange = () => {},
  required = false
}) {
  const handleChange = (checked) => {
    onChange(checked)
  }
  return (
    <>
      <PageHeader title='NFC' />
      <Alert
        style={{ marginBottom: '1rem' }}
        message='Как узнать, есть ли функция'
        description={
          <>
            Перейдите в настройки вашего телефона. В строке поиска введите «NFC». Если функция доступна{nbsp}{mdash}{' '}
            вы увидите её в списке
          </>
        }
        type='warning'
        showIcon
      />
      <Form.Item
        name='existsNfc'
        validateStatus={required && 'error'}
        help={required && 'Вам нужен модуль NFC на телефоне для получения виртуальной карты'}
      >
        <Row>
          <Col className='exists-nfc'>
            <span>На моем телефоне есть NFC</span>
            <Switch onChange={handleChange} checked={checked} />
          </Col>
        </Row>
        <Input type='hidden' />
      </Form.Item>
    </>
  )
}

export default ExistsNfc
