import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function LmkAllowancePhoto () {
  return (
    <>
      <Title level={3}>Страница с допуском</Title>
      <Text>
        Фотографии печатей и заключения терапевта. Оно может быть как на отдельной странице,
        так и в одном списке с остальными врачами
      </Text>
      <br />
      <br />
      <img src='/img/photo-doc/medical-doc-1.png' className='img-doc' />
      <br />
      <img src='/img/photo-doc/medical-doc-2.png' className='img-doc' />
    </>
  )
}

export default LmkAllowancePhoto
