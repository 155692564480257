import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'

export const ORG_KEY = 'org'
export const CHAINS_KEY = 'chains'
export const STORES_KEY = 'stores'
export const CITIES_KEY = 'cities'

const getOrgTree = ({ queryKey }) => {
  const [/* key */, data] = queryKey
  return api.post({ path: `${ORG_KEY}/tree`, data })
}
const fetchUserCloseStore = id => {
  return api.post({ path: `${ORG_KEY}/${STORES_KEY}/${id}/close` })
}
const fetchUserOpenStore = id => {
  return api.post({ path: `${ORG_KEY}/${STORES_KEY}/${id}/open` })
}
const fetchCreateOrUpdateOrgElement = ({ method, name, body }) => {
  return api[method]({ path: `${ORG_KEY}/${name}`, data: body })
}

const getChains = ({ queryKey }) => {
  const [/* key */, data] = queryKey
  return api.post({ path: `${ORG_KEY}/${CHAINS_KEY}`, data })
}
const getStores = ({ queryKey }) => {
  const [/* key */, data = {}] = queryKey
  return api.post({ path: `${ORG_KEY}/${STORES_KEY}`, data })
}
const getCities = ({ queryKey }) => {
  const [/* key */, data] = queryKey
  return api.post({ path: `${ORG_KEY}/${CITIES_KEY}`, data })
}
const getAllCities = () => {
  return api.post({ path: `${ORG_KEY}/${CITIES_KEY}/all` })
}

export function useOrgTreeQuery (data, options) {
  return useQuery([ORG_KEY, data], getOrgTree, options)
}
export function useMutateUserCloseStore () {
  return useMutation(fetchUserCloseStore, {
    onSuccess: (data, id) => {
      queryClient.refetchQueries(ORG_KEY)
    }
  })
}
export function useMutateUserOpenStore () {
  return useMutation(fetchUserOpenStore, {
    onSuccess: (data, id) => {
      queryClient.refetchQueries(ORG_KEY)
    }
  })
}
export function useMutateCreateOrUpdateOrgElement () {
  return useMutation(fetchCreateOrUpdateOrgElement, {
    onSuccess: (data, variables) => {
      if (variables?.name === 'store') {
        queryClient.refetchQueries(CHAINS_KEY)
        queryClient.refetchQueries(ORG_KEY)
      }
      if (variables?.name === 'city') {
        queryClient.refetchQueries(CITIES_KEY)
        queryClient.refetchQueries(ORG_KEY)
      }
    }
  })
}

export function useOrgChainsQuery (data, options) {
  return useQuery([CHAINS_KEY, data], getChains, options)
}
export function useOrgStoresQuery (data, options) {
  return useQuery([STORES_KEY, data], getStores, options)
}
export function useOrgCitiesQuery (data, options) {
  return useQuery([CITIES_KEY, data], getCities, options)
}
export function useAllOrgCitiesQuery (options) {
  return useQuery([CITIES_KEY, 'all'], getAllCities, options)
}
