import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function InnPhoto () {
  return (
    <>
      <Title level={3}>Госуслуги</Title>
      <Text>Выберите пункт «Мои документы» → «Личные документы» → «ИНН» и сделайте снимок экрана.</Text>
      <br />
      <br />
      <Text>Экран может отличаться, но на нём должны быть видны ФИО и номер</Text>
      <br />
      <img src='/img/photo-doc/inn-doc-1.png' className='img-doc' />
    </>
  )
}

export default InnPhoto
