import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function LegalCapacityConfirmation () {
  return (
    <>
      <Title level={3}>Какие документы подходят</Title>
      <Text>1. Решения об объявлении несовершеннолетнего дееспособным (оформляют родители или опекуны)</Text>
      <br />
      <Text>2. Свидетельство о браке</Text>
      <br />
    </>
  )
}

export default LegalCapacityConfirmation
