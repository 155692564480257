import React, { useState } from 'react'
import { WindowsOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ADALAuthContext } from '../../../../adalConfig'

function ADALAuth () {
  const [isLoading, setIsLoading] = useState(false)

  const handleLogin = () => {
    setIsLoading(true)
    ADALAuthContext.login()
  }

  return (
    <Button icon={<WindowsOutlined />} type='primary' loading={isLoading} onClick={handleLogin}>Войти c Microsoft</Button>
  )
}

export default ADALAuth
