import React, { useContext } from 'react'
import { Layout, Spin } from 'antd'
import './ProfileMainScreenV2Style.css'
import ProfileV2 from '../../../components/Partner/V2/ProfileV2'
import { ConfigContext } from '../../../context/configContext'

function ProfileMainScreenV2 ({
  isLoading
}) {
  const { loadingData } = useContext(ConfigContext)
  return (
    <Spin spinning={Boolean(isLoading || loadingData?.isLoadingFlowV2)} tip={loadingData?.loadingText} size='large' className='flowV2'>
      <Layout className='ProfileWrapper ProfileWrapperV2'>
        <ProfileV2 />
      </Layout>
    </Spin>
  )
}

export default ProfileMainScreenV2
