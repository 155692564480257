import React, { useEffect, useReducer } from 'react'
import { Checkbox, Col, Form, Input, Row, Typography } from 'antd'
import { profileScreen } from '../../../translates'
import InputMask from 'react-input-mask'
import { arrayUniqValues, simpleReducer } from '../../../helper'

const { Text } = Typography

const validLetters = 'АВЕКМНОРСТУХавекмнорстух'
const maskChar = '_'
export const patternVehicleNumber = new RegExp(`^([${validLetters}]){1} ([0-9]){3} ([${validLetters}]){2} ([0-9]){2,3}$`)

export const formatForFrontVehicleNumber = (str) => {
  const number = str?.replaceAll(' ', '')
  const formatted = `${number?.substring(0, 1)} ${number?.substring(1, 4)} ${number?.substring(4, 6)} ${number?.substring(6, number?.length)}`
  if (patternVehicleNumber.test(formatted)) {
    return formatted
  }
  return str
}

export const formatForApiVehicleNumber = (str, isForeign = false) => {
  if (isForeign) {
    return str
  }
  return str?.replaceAll(' ', '').replaceAll(maskChar, '').toUpperCase()
}

const sizes = {
  large: 'lg',
  middle: 'md'
}

const initialState = {
  value: '',
  isForeignVehicleNumber: false,
  isAttention: null
}

function VehicleNumberFormItem ({
  form,
  size = 'large',
  label = profileScreen.input.carLicencePlate.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.carLicencePlate.message,
  placeholder = profileScreen.input.carLicencePlate.placeholder,
  initValues = null,
  hideForeignFormat = false
}) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const validateValueFormat = (rule, value) => {
    const val = value?.replaceAll(maskChar, '').trim()
    if (!val) return Promise.resolve()
    return patternVehicleNumber.test(val) ? Promise.resolve() : Promise.reject(rule.message)
  }

  const onChange = (e) => {
    const value = e.target.value.toUpperCase()
    const attention = value?.replaceAll(' ', '').replaceAll('_', '').substring(0, 6).trim().toUpperCase()
    const attentionLetters = arrayUniqValues([...attention])
    const isAttention = (attentionLetters.length === 2 && attention.length === 6) || null
    setState({ value, isAttention })
    form.setFieldsValue({
      personalData: {
        [profileScreen.input.carLicencePlate.name]: value
      }
    })
  }

  const handleChangeIsForeignNumber = (e) => {
    const checked = e.target.checked
    setState({
      isForeignVehicleNumber: checked,
      value: checked
        ? state.value.replaceAll('_', '').toUpperCase()
        : state.value.toUpperCase()
    })
    form.setFieldsValue({
      personalData: {
        [profileScreen.input.isForeignVehicleNumber.name]: checked,
        [profileScreen.input.carLicencePlate.name]: checked
          ? state.value.replaceAll('_', '').toUpperCase()
          : state.value.toUpperCase()
      }
    })
  }

  const getLabelComponent = () => {
    return (
      <Row justify='space-between' className='full-width'>
        <Col><Text>{label}</Text></Col>
        <Col>
          <Text>{profileScreen.input.isForeignVehicleNumber.label}</Text>
          <Form.Item
            name={['personalData', profileScreen.input.isForeignVehicleNumber.name]}
            valuePropName='checked'
            noStyle
            rules={[{
              transform: value => (value || undefined),
              type: 'boolean'
            }]}
          >
            <Checkbox
              className='ml-3'
              onChange={handleChangeIsForeignNumber}
            />
          </Form.Item>
        </Col>
      </Row>
    )
  }

  useEffect(() => {
    if (!state.value?.length) {
      setState({ isAttention: null })
    }
  }, [state.value])

  useEffect(() => {
    if (initValues) {
      setState({
        ...(
          initValues[profileScreen.input.carLicencePlate.name] !== undefined
            ? { value: initValues[profileScreen.input.carLicencePlate.name] }
            : {}
        ),
        ...(
          initValues[profileScreen.input.isForeignVehicleNumber.name] !== undefined
            ? { isForeignVehicleNumber: initValues[profileScreen.input.isForeignVehicleNumber.name] }
            : {}
        )
      })
    }
  }, [initValues])

  return (
    <div className='label-row'>
      <Form.Item
        name={['personalData', profileScreen.input.carLicencePlate.name]}
        label={hideForeignFormat ? label : getLabelComponent()}
        rules={rules ?? [
          { required: requiredRule, message: messageRule },
          ...(
            state.isForeignVehicleNumber
              ? []
              : [{ message: profileScreen.input.carLicencePlate.messageValidation, validator: validateValueFormat }]
          )
        ]}
        help={state.isAttention && (<div style={{ color: 'red' }}>{profileScreen.input.carLicencePlate.messageAttention}</div>)}
      >
        {
          state.isForeignVehicleNumber
            ? (
              <Input
                type='text'
                className={`ant-input ant-input-${sizes[size]}`}
                style={{ textTransform: 'uppercase' }}
                value={state.value}
                onChange={onChange}
                placeholder='Произвольный формат'
              />
            )
            : (
              <InputMask
                className={`ant-input ant-input-${sizes[size]}`}
                style={{ textTransform: 'uppercase' }}
                mask='s 999 ss 999'
                maskChar={maskChar}
                value={state.value}
                onChange={onChange}
                formatChars={{
                  9: '[0-9]',
                  s: `[${validLetters}]`
                }}
                placeholder={placeholder}
              />
            )
        }
      </Form.Item>
    </div>
  )
}

export default VehicleNumberFormItem
