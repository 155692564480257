import React, { useEffect, useState } from 'react'
import { profileScreen } from '../../../translates'
import { validateName } from '../../../helper'
import { Checkbox, Col, Form, Row, Typography } from 'antd'
import { NameFormItem } from '../index'

const { Text } = Typography
const fieldNames = {
  middleName: ['personalData', 'middleName'],
  withoutMiddleName: ['personalData', 'withoutMiddleName']
}
const MiddleNameHideableFormItem = ({
  form,
  size = 'large',
  onUpdate = () => {},
  trigger
}) => {
  const [without, setWithout] = useState(false)
  const handleChangeWithoutMiddleName = () => {
    form.validateFields(fieldNames.withoutMiddleName).then((values) => {
      setWithout(values?.personalData?.withoutMiddleName)
      if (values?.personalData?.withoutMiddleName) {
        form.setFields([
          {
            name: fieldNames.middleName,
            errors: [],
            value: ''
          }
        ])
      }
      onUpdate()
    })
  }

  useEffect(() => {
    if (trigger) {
      handleChangeWithoutMiddleName()
    }
  }, [trigger])

  return (
    <>
      {!without && (
        <NameFormItem
          name={fieldNames.middleName}
          placeholder={profileScreen.input.middleName.placeholder}
          rules={[{
            required: true,
            message: profileScreen.input.middleName.message,
            validator: validateName
          }]}
          shouldUpdate
          requiredRule={false}
          size={size}
          label={profileScreen.input.middleName.label}
        />
      )}
      <Row justify='space-between' className='full-width' style={{ marginBottom: 24 }}>
        <Col>
          <Form.Item
            name={fieldNames.withoutMiddleName}
            valuePropName='checked'
            noStyle
            rules={[{
              transform: value => (value || undefined),
              type: 'boolean'
            }]}
          >
            <Checkbox
              onChange={handleChangeWithoutMiddleName}
            >
              <Text>{profileScreen.input.middleName.without}</Text>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default MiddleNameHideableFormItem
