import React, { useEffect, useState, useContext, useReducer } from 'react'
import { Button, Col, List, message, Row, Spin, Typography, Modal } from 'antd'
import moment from 'moment-timezone'

import {
  useMutateAdminPartnerActForce, useMutateAdminPartnerActText,
  useMutateAdminPartnerSendAct,
  useQueryAdminPartnerActs
} from '../../../../api/react-query/adminPartners'
import { ruCurrency, simpleReducer } from '../../../../helper'
import { AppConfig } from '../../../../AppConfig'
import { useActTypesQuery } from '../../../../api/react-query/dict'
import { ConfigContext } from '../../../../context/configContext'
import { CreateActWithAssetsPreview } from './index'

const { Text, Title } = Typography
const { confirm } = Modal

const initialState = {
  actData: null
}

export default function PartnerActs ({ partner }) {
  const { isPersonalManager } = useContext(ConfigContext)
  const [acts, setActs] = useState([])
  const [actTemplatesTypes, setActTemplatesTypes] = useState([])
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    data: dataPartnerActs,
    isError: isErrorPartnerActs,
    isLoading: isLoadingPartnerActs,
    isSuccess: isSuccessPartnerActs
  } = useQueryAdminPartnerActs({
    id: partner.id,
    params: { status: 'created' }
  }, { enabled: !!partner.id })

  useEffect(() => {
    if (isSuccessPartnerActs) {
      setActs(dataPartnerActs?.acts || [])
    } else if (isErrorPartnerActs) {
      console.error(dataPartnerActs)
      message.error('Ошибка получения актов')
    }
  }, [isErrorPartnerActs, isSuccessPartnerActs, dataPartnerActs])

  const {
    mutate: mutatePartnerSendAct,
    isError: isErrorPartnerSendAct,
    isLoading: isLoadingPartnerSendAct,
    isSuccess: isSuccessPartnerSendAct
  } = useMutateAdminPartnerSendAct()

  useEffect(() => {
    if (isSuccessPartnerSendAct) {
      message.success('Сообщение отправлено')
    } else if (isErrorPartnerSendAct) {
      message.error('Ошибка отправки сообщения')
    }
  }, [isErrorPartnerSendAct, isSuccessPartnerSendAct])

  const {
    mutate: mutatePartnerActForce,
    isError: isErrorPartnerActForce,
    isLoading: isLoadingPartnerActForce,
    isSuccess: isSuccessPartnerActForce
  } = useMutateAdminPartnerActForce()

  useEffect(() => {
    if (isSuccessPartnerActForce) {
      message.success('Сообщение отправлено')
    } else if (isErrorPartnerActForce) {
      message.error('Ошибка отправки сообщения')
    }
  }, [isErrorPartnerActForce, isSuccessPartnerActForce])

  const {
    mutate: getPartnerActText,
    isSuccess: isSuccessActText,
    isError: isErrorActText,
    isLoading: isLoadingActText,
    data: dataActText
  } = useMutateAdminPartnerActText()

  useEffect(() => {
    if (isSuccessActText && dataActText?.isSuccess) {
      setState({ actData: dataActText })
    } else if (isErrorActText || dataActText?.errorMessage) {
      message.error(dataActText?.errorMessage || 'Ошибка получения данных об Акте')
    }
  }, [isSuccessActText, isErrorActText, dataActText])

  const {
    data: actTemplatesTypesData,
    isError: isErrorActTemplatesTypes,
    isSuccess: isSuccessActTemplatesTypes
  } = useActTypesQuery()

  useEffect(() => {
    if (isSuccessActTemplatesTypes) {
      setActTemplatesTypes(actTemplatesTypesData?.result)
    } else if (isErrorActTemplatesTypes) {
      console.error(actTemplatesTypesData)
      message.error('Ошибка получения списка типов актов')
    }
  }, [actTemplatesTypesData, isErrorActTemplatesTypes, isSuccessActTemplatesTypes])

  const handleSendAct = (actId) => {
    confirm({
      title: 'Отправка СМС',
      content: 'Повторно отправить СМС партнеру?',
      okText: 'Да',
      cancelText: 'Нет',
      onOk: () => {
        mutatePartnerSendAct({ data: { actId }, id: partner.id })
      },
      onCancel: () => {}
    })
  }

  const handleActForce = (actId) => {
    confirm({
      title: 'Отправка в СБИС',
      content: 'Отправить акт в СБИС?',
      okText: 'Да',
      cancelText: 'Нет',
      onOk: () => {
        mutatePartnerActForce({ actId, id: partner.id })
      },
      onCancel: () => {}
    })
  }

  const handleActTextShow = (actId) => {
    getPartnerActText({ id: partner?.id, actId })
  }

  const handleActTextShowOk = () => {
    setState(initialState)
  }

  const handleActTextShowCancel = () => {
    setState(initialState)
  }

  return (
    <>
      <Title className='mb-3 mt-4' level={5}>Перечень актов, по которым не был получен ответ от партнера:</Title>
      <Spin spinning={isLoadingPartnerActs}>
        {acts.length > 0 ? (
          <>
            <List
              bordered
              className='list'
              itemLayout='horizontal'
              dataSource={acts}
              header={(
                <Row gutter={[12, 6]}>
                  <Col span={4}>Тип акта</Col>
                  <Col span={14}>Имущество <Text type='secondary'>(Название, Цена, Кол-во, Стоимость)</Text></Col>
                  <Col span={3}>Автор акта</Col>
                  <Col span={3} />
                </Row>
              )}
              renderItem={item => (
                <List.Item>
                  <Row gutter={[12, 6]} className='fill-content'>
                    <Col
                      span={4}
                      className={`act-type-${actTemplatesTypes.find(type => type.id === item.type)?.id}`}
                    >
                      <Button
                        style={{ whiteSpace: 'normal', textAlign: 'left', padding: 0 }}
                        disabled={isLoadingActText}
                        type='link'
                        onClick={() => handleActTextShow(item.id)}
                        size='small'
                      >
                        {`${actTemplatesTypes.find(type => type.id === item.type)?.name} от ${moment(item.createdAt).format(AppConfig.formats.shortDate)}`}
                      </Button>
                    </Col>
                    <Col span={14}>{item.lines.map(line => (
                      <Row key={line.assetId} gutter={[12, 6]}>
                        <Col span={16}>{line.assetName}</Col>
                        <Col span={3}>{line.price.toFixed(2)}<small><Text type='secondary'>{ruCurrency}</Text></small></Col>
                        <Col span={2}>{line.quantity}шт</Col>
                        <Col span={3}>{line.amount.toFixed(2)}<small><Text type='secondary'>{ruCurrency}</Text></small></Col>
                      </Row>
                    ))}
                    </Col>
                    <Col span={3}>{item.createdBy}</Col>
                    <Col span={3}>
                      {
                        !isPersonalManager &&
                          <Button
                            disabled={isLoadingPartnerSendAct}
                            type='link'
                            onClick={() => handleSendAct(item.id)}
                            size='small'
                          >
                            Отправить СМС
                          </Button>
                      }
                      {
                        item.canForce && (
                          <Button
                            disabled={isLoadingPartnerActForce}
                            type='link'
                            onClick={() => handleActForce(item.id)}
                            size='small'
                          >
                            Отправить в СБИС
                          </Button>
                        )
                      }
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </>
        ) : (
          <Text className='ml-3' type='secondary'>По всем актам был получен ответ от партнера.</Text>
        )}
        <Row justify='end'>
          <Title level={5} className='mt-3 mr-3'>
            Итоговая разница: {dataPartnerActs?.debt}
            <small><Text type='secondary'>{ruCurrency}</Text></small>
          </Title>
        </Row>
        {state.actData && (
          <CreateActWithAssetsPreview
            preview={state.actData?.text}
            onClose={handleActTextShowCancel}
            onSave={handleActTextShowOk}
            title={state.actData?.templateData?.act?.type}
          />
        )}
      </Spin>
    </>
  )
}
