import React, { useMemo } from 'react'
import { Col, List } from 'antd'
import moment from 'moment-timezone'
import { AppConfig } from '../../../AppConfig'
import { generatePath, useHistory } from 'react-router-dom'
import { ADMIN_PARTNER_PAGE } from '../../../routes'

function PartnerItem ({ item, statType }) {
  const history = useHistory()

  const span = useMemo(() => {
    return statType === AppConfig.partnerStatTypes.newPartners ? 6 : 12
  }, [statType])

  const handleClick = () => {
    if (item?.partnerId) {
      history.replace(generatePath(ADMIN_PARTNER_PAGE, { id: item.partnerId }))
    }
  }

  return (
    <List.Item>
      <Col span={span}>
        <a onClick={handleClick}>{item.partnerName}</a>
      </Col>
      {statType === AppConfig.partnerStatTypes.newPartners && (
        <Col span={span}>
          {item.status}
        </Col>
      )}
      <Col span={span}>
        {
          item.statusTimestamp
            ? moment(item.statusTimestamp).format(AppConfig.formats.shortDateAndTime)
            : ''
        }
      </Col>
      {statType === AppConfig.partnerStatTypes.newPartners && (
        <Col span={span}>
          {item.userName}
        </Col>
      )}
    </List.Item>
  )
}

export default PartnerItem
