import React from 'react'
import { isShownSberIcon } from '../../../../helper'
import PassportNumberFormItem from './PassportNumberFormItem'
import PassportSeriesFormItem from './PassportSeriesFormItem'
import PassportIssuerNameFormItem from './PassportIssuerNameFormItem'
import PassportIssuerCodeFormItem from './PassportIssuerCodeFormItem'
import PassportIssueDateFormItem from './PassportIssueDateFormItem'
import { Divider } from 'antd'

const PassportBlock = ({ size = 'large', headerOrientation = 'left', hideBottomDivider = false, profile }) => (
  <>
    <Divider orientation={headerOrientation}>Паспорт</Divider>
    <PassportSeriesFormItem size={size} classNameInput='noScrollNumber' showCheckIcon={isShownSberIcon('passport.series', profile)} />
    <PassportNumberFormItem size={size} classNameInput='noScrollNumber' showCheckIcon={isShownSberIcon('passport.number', profile)} />
    <PassportIssuerNameFormItem size={size} showCheckIcon={isShownSberIcon('passport.issuerName', profile)} />
    <PassportIssuerCodeFormItem size={size} showCheckIcon={isShownSberIcon('passport.issuerCode', profile)} />
    <PassportIssueDateFormItem size={size} showCheckIcon={isShownSberIcon('passport.issueDate', profile)} />
    {!hideBottomDivider && <Divider />}
  </>
)

export default PassportBlock
