import React, { useState } from 'react'
import { Button, message } from 'antd'
import { api } from '../../../../api/react-query/api'

function DownloadContract ({ partnerId }) {
  const [isLoading, setIsLoading] = useState(false)

  const downloadContract = async () => {
    setIsLoading(true)
    await api.downloadFile({
      path: `admin/partners/${partnerId}/contract`,
      filename: `contract-${partnerId}.pdf`,
      onSuccess: () => {
        setIsLoading(false)
        message.success('Договор успешно загружен')
      },
      onError: () => {
        setIsLoading(false)
        message.error('Не удалось скачать договор')
      }
    })
  }

  return (
    <div>
      <Button
        loading={isLoading}
        onClick={downloadContract}
        type='primary'
      >
        Скачать договор
      </Button>
    </div>
  )
}

export default DownloadContract
