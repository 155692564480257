import React, { useEffect, useReducer } from 'react'
import { Alert, Button, Form, message, Spin, Layout, Steps } from 'antd'
import './BankAccept.css'
import { BankAccountFormItem, BankBikFormItem, BankNameFormItem } from '../../Common'
import { useMutateConsentTinkoffBankDetails } from '../../../api/react-query/partners'
import { useStores } from '../../../Store/Store'
import { AppConfig } from '../../../AppConfig'
import MobileHeader from '../MobileHeader/MobileHeader'
import { simpleReducer } from '../../../helper'

const { Content } = Layout
const { Step } = Steps

const initialState = {
  isSending: false
}

function BankAccept ({ profile, setStep = () => {} }) {
  const [state, setState] = useReducer(simpleReducer, initialState)
  const [form] = Form.useForm()
  const { partnerProfile } = useStores()

  useEffect(() => {
    form.setFieldsValue({
      bankDetails: profile?.tinkoffData?.bankDetails
    })
  }, [profile])

  const {
    mutate: consentTinkoffBankDetails,
    data: dataTinkoffBankDetails,
    isSuccess: isSuccessTinkoffBankDetails,
    isError: isErrorTinkoffBankDetails,
    isLoading: isLoadingTinkoffBankDetails
  } = useMutateConsentTinkoffBankDetails()

  useEffect(() => {
    if (isSuccessTinkoffBankDetails && dataTinkoffBankDetails?.isSuccess) {
      message.success('Данные успешно сохранены')
      partnerProfile.setPartnerProfile({
        ...dataTinkoffBankDetails,
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
      setStep(AppConfig.partnerSteps.stepSuccess)
      setState({ isSending: false })
    } else if (isErrorTinkoffBankDetails || dataTinkoffBankDetails?.errorMessage) {
      message.error(dataTinkoffBankDetails?.errorMessage || 'Ошибка передачи данных БР')
      setState({ isSending: false })
    }
  }, [dataTinkoffBankDetails, isSuccessTinkoffBankDetails, isErrorTinkoffBankDetails])

  const handleAccept = () => {
    sendData(true)
  }

  const handleDecline = () => {
    sendData(false)
  }

  const sendData = (isAccepted) => {
    setState({ isSending: true })
    consentTinkoffBankDetails({
      isAccepted,
      ...(isAccepted
        ? {
          bankInfo: {
            bankName: profile?.tinkoffData?.bankDetails.bankName,
            bankAccount: profile?.tinkoffData?.bankDetails.bankAccount,
            bankBik: profile?.tinkoffData?.bankDetails.bankBik,
            name: profile?.tinkoffData?.bankDetails.name
          }
        }
        : {}
      )
    })
  }

  return (
    <Layout className='ProfileWrapper'>
      <MobileHeader />
      <Content>

        <Steps current={AppConfig.partnerSteps.stepSuccess}>
          {Object.keys(AppConfig.partnerSteps).map(item => (
            <Step key={AppConfig.partnerSteps[item]} />
          ))}
        </Steps>

        <div className='steps-content'>
          <div className='bankAccept'>
            <Spin spinning={isLoadingTinkoffBankDetails || state.isSending}>
              <Alert
                message={<b>Используйте реквизиты из Tinkoff ID</b>}
                description={
                  <>
                    На этот счёт будет приходить еженедельная выплата.
                    Изменить его или указать реквизиты другого банка сможете в любой момент в Шоппере
                  </>
                }
                type='warning'
                showIcon
              />
              <Form
                className='bankAcceptForm'
                form={form}
              >
                <BankNameFormItem disabled />
                <BankAccountFormItem disabled />
                <BankBikFormItem disabled />
                <br />
                <Form.Item>
                  <Button
                    block
                    size='large'
                    type='primary'
                    onClick={handleAccept}
                  >
                    Использовать эти реквизиты
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    block
                    size='large'
                    type='default'
                    onClick={handleDecline}
                  >
                    Заполнить позже вручную
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default BankAccept
