import React, { useEffect } from 'react'
import { message, Spin } from 'antd'
import { useMutateUnbanSmsPartner } from '../../../../api/react-query/sa'

function PartnerSmsBan ({ partnerId }) {
  const {
    mutate: unbanSms,
    data: dataUnban,
    isSuccess: isSuccessUnban,
    isError: isErrorUnban,
    isLoading: isLoadingUnban
  } = useMutateUnbanSmsPartner()

  useEffect(() => {
    if (isSuccessUnban && dataUnban?.isSuccess) {
      message.success('Снят бан с отправки смс с кодом авторизации партнеру')
    } else if (isErrorUnban || dataUnban?.errorMessage) {
      message.error(dataUnban?.errorMessage || 'Ошибка отправки данных о разбане смс для партнера')
    }
  }, [isSuccessUnban, isErrorUnban, dataUnban])

  const handleClick = (event) => {
    event.preventDefault()
    unbanSms({ id: partnerId })
  }

  return (
    <Spin spinning={isLoadingUnban}>
      <a href='' onClick={handleClick}>Разбанить отправку смс</a>
    </Spin>
  )
}

export default PartnerSmsBan
