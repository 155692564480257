import React, { useContext, useEffect } from 'react'
import { ConfigContext } from '../../../context/configContext'
import { message, Tooltip } from 'antd'
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { useMutateAdminPartnerVerificationFree } from '../../../api/react-query/adminPartners'

function UnlinkPartnerButton ({ partnerId }) {
  const { isAdmin } = useContext(ConfigContext)
  const {
    mutate: unlinkPartner,
    data: dataUnlink,
    isLoading: isLoadingUnlink,
    isError: isErrorUnlink
  } = useMutateAdminPartnerVerificationFree()

  useEffect(() => {
    if (isErrorUnlink && !dataUnlink?.isSuccess) {
      message.error(dataUnlink?.errorMessage || 'Ошибка сохранения данных')
    }
  }, [isErrorUnlink, dataUnlink])

  const handleClick = () => {
    unlinkPartner({ id: partnerId })
  }

  if (!isAdmin || !partnerId) {
    return null
  }
  return (
    <Tooltip placement='bottom' title='Освободить анкету'>
      {isLoadingUnlink
        ? <LoadingOutlined />
        : <CloseCircleOutlined onClick={handleClick} style={{ marginLeft: 8, cursor: 'pointer' }} />}
    </Tooltip>
  )
}

export default UnlinkPartnerButton
