import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { ConfigContext } from '../../../context/configContext'
import { Button, Modal, Spin, Space, Form, Input, Drawer } from 'antd'
import { simpleReducer, validatePhone } from '../../../helper'
import { profileScreen } from '../../../translates'
import Partner from '../../../Screens/Admin/Partners/Partner'
import { useMutateLog } from '../../../api/react-query/log'

const initialState = {
  isModalOpen: false
}

function SearchByPhone () {
  const { isDivisionalInstructor } = useContext(ConfigContext)
  const [form] = Form.useForm()
  const [state, setState] = useReducer(simpleReducer, initialState)
  const [phone, setPhone] = useState()
  const inputRef = useRef()
  const { mutate: sendLog } = useMutateLog()

  const handleClick = () => {
    setState({ isModalOpen: true })
  }

  const handleClose = () => {
    setState({ isModalOpen: false })
  }
  const handleCloseDrawer = () => {
    setPhone(null)
    setState({ isModalOpen: false })
    sendLog({
      targetId: `partner/${phone}`,
      action: 'ClosePartnerModal'
    })
  }

  const handleSave = () => {
    form.validateFields().then(fields => {
      setPhone(fields.phone)
    })
  }

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue({ phone: '+7' })
    setTimeout(() => inputRef.current?.focus(), 300)
  }, [state.isModalOpen, form])

  if (!isDivisionalInstructor) {
    return null
  }

  return (
    <Space style={{ marginBottom: 0 }}>
      <Button
        // loading={isLoading}
        onClick={handleClick}
      >
        Найти партнера
      </Button>
      {
        state.isModalOpen && (
          <Modal
            visible={state.isModalOpen}
            onCancel={handleClose}
            onOk={handleSave}
            okText='Найти'
            title='Поиск партнера по номеру телефона'
            destroyOnClose
          >
            <Spin spinning={false}>
              <Form
                form={form}
              >
                <Form.Item
                  label='Номер телефона'
                  name={['phone']}
                  rules={[
                    { required: true, message: profileScreen.input.mobilePhone.message },
                    { validator: validatePhone, message: profileScreen.input.mobilePhone.message }
                  ]}
                >
                  <Input placeholder={profileScreen.input.mobilePhone.placeholder} ref={inputRef} />
                </Form.Item>
              </Form>
            </Spin>
          </Modal>
        )
      }
      {
        !!phone &&
          <Drawer
            className='idPartnerDrawer'
            width={800}
            placement='right'
            closable={false}
            onClose={handleCloseDrawer}
            visible={!!phone}
            destroyOnClose
          >
            <Partner partnerPhone={phone} />
          </Drawer>
      }
    </Space>
  )
}

export default SearchByPhone
