import React from 'react'
import { Button } from 'antd'
import { legalAgeTemplateDocLink } from '../../../../Constants'

const btnStyle = {
  borderRadius: '7px'
}

function LegalAgeTemplateButton () {
  const handleClick = () => {
    window.open(legalAgeTemplateDocLink, '_blank', 'noreferrer')
  }

  return (
    <Button
      block
      size='large'
      style={btnStyle}
      onClick={handleClick}
    >
      <img src='/img/download-icon.png' style={{ marginRight: '0.5rem' }} />
      Скачать шаблон согласия
    </Button>
  )
}

export default LegalAgeTemplateButton
