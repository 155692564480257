import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Divider, message, Modal, Row, Spin, Table, Typography } from 'antd'
import Approval from '../../Approval/Approval'
import PartnerDocuments from '../Sections/PartnerDocuments'
import EditableTable from '../../../Common/EditableTable/EditableTable'
import { profileScreen } from '../../../../translates'
import {
  useMutateAdminAcceptCardForm,
  useMutateAdminDeclineCardForm,
  useMutateQueryAdminCardForm
} from '../../../../api/react-query/adminPartners'
import { ACCEPT, ACCEPT_CONDITION, ACCEPTED, DELETED, NONE } from '../../../../Constants'
import {
  arraySortByArray,
  createApiDataFromForm,
  createFormDataFromApi,
  flattenObject,
  getBirthDateRules,
  getOnlyInputFieldInPreparedData,
  getPassportIssueDateRules,
  restoreFlattenObject
} from '../../../../helper'
import CityChainStoreItem from './CityChainStoreItem'
import { AppConfig } from '../../../../AppConfig'
import './EditModals.css'
import RegistrationKindItem from './RegistrationKindItem'

const { Text } = Typography
const columns = [
  {
    title: 'Название поля',
    dataIndex: 'fieldName'
  },
  {
    title: 'Значение',
    dataIndex: 'newValue',
    editable: true,
    width: '55%'
  }
]
const documents = ['passportPhoto1', 'passportPhoto2', 'passportPhotoExtraReg', 'passportPhotoTmpReg', 'passportPhoto3']

function EditCardRequest ({ profile, setPartner, disabled, partnerId, viewOnly = false }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [profileData, setProfileData] = useState([])
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [fieldsHasErrors, setFieldsHasErrors] = useState([])
  const [closeModalAfterAccept, setCloseModalAfterAccept] = useState(false)
  const [cardFormData, setCardFormData] = useState()

  const {
    data: fetchedCardFormData,
    mutate: refetchCardFormData,
    isSuccess: isSuccessCardFormData,
    isLoading: isLoadingCardFormData
  } = useMutateQueryAdminCardForm()

  useEffect(() => {
    if (isSuccessCardFormData && fetchedCardFormData?.isSuccess) {
      setCardFormData(fetchedCardFormData)
    }
  }, [fetchedCardFormData, isSuccessCardFormData])

  useEffect(() => {
    if (isModalVisible && partnerId) {
      refetchCardFormData({ partnerId })
    }
  }, [isModalVisible, partnerId])

  const {
    mutate: acceptRequest,
    data: acceptRequestData,
    isLoading: acceptRequestLoading,
    isSuccess: acceptRequestSuccess,
    isError: acceptRequestError
  } = useMutateAdminAcceptCardForm()

  const {
    mutate: declineRequest,
    data: declineRequestData,
    isLoading: declineRequestLoading,
    isSuccess: declineRequestSuccess,
    isError: declineRequestError
  } = useMutateAdminDeclineCardForm()

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  const prepareAcceptRequestData = (newValues, condition) => {
    newValues = restoreFlattenObject(newValues)
    return ({
      data: createApiDataFromForm({
        personalData: Object.fromEntries(Object.entries({
          ...newValues,
          withoutMiddleName: newValues.middleName !== undefined ? !newValues.middleName : undefined,
          registrationAddress: Object.fromEntries(Object.entries({
            ...newValues?.registrationAddress
          }).map(([key, value]) => value === null ? [key, ''] : [key, value]))
        }).filter(([, value]) => value !== undefined))
      })?.personalData,
      ...(condition && { condition })
    })
  }

  const handleCommit = ({ outcome, comment: reason, condition }) => {
    setBackendFieldsErrors({})
    if ([ACCEPT, ACCEPT_CONDITION].includes(outcome)) {
      const newValues = Object.fromEntries(profileData.map(item => [item.key, item.newValue]))
      setCloseModalAfterAccept(true)
      acceptRequest({
        id: partnerId,
        data: prepareAcceptRequestData({
          ...newValues,
          passportPhoto1: cardFormData?.form?.passportPhoto1?.fileId,
          passportPhoto2: cardFormData?.form?.passportPhoto2?.fileId,
          passportPhotoExtraReg: cardFormData?.form?.passportPhotoExtraReg?.fileId,
          passportPhotoTmpReg: cardFormData?.form?.passportPhotoTmpReg?.fileId,
          passportPhoto3: cardFormData?.form?.passportPhoto3?.fileId,
          ...(cardFormData?.form?.store?.id && { storeId: cardFormData?.form?.store?.id }),
          ...(cardFormData?.form?.hasNfc && { hasNfc: cardFormData?.form?.hasNfc }),
          ...(cardFormData?.form?.registrationKindCode && { registrationKind: cardFormData?.form?.registrationKindCode })
        }, condition)
      })
    } else {
      declineRequest({ id: partnerId, reason })
    }
  }
  const flattenProfileData = useMemo(() => flattenObject(createFormDataFromApi({ personalData: profile?.personalData })?.personalData), [profile])
  useEffect(() => {
    const data = []
    const formData = { ...cardFormData?.form || {} }
    delete formData.passportPhoto1
    delete formData.passportPhoto2
    delete formData.passportPhotoExtraReg
    delete formData.passportPhotoTmpReg
    delete formData.passportPhoto3
    delete formData.registrationKindCode
    delete formData.status
    delete formData.statusCode
    delete formData.statusReason
    delete formData.store
    delete formData.hasNfc
    delete formData.condition
    const personalData = createFormDataFromApi({ personalData: formData })?.personalData
    delete personalData?.examinationDate
    delete personalData?.attestationDate
    const flattenData = flattenObject(personalData)

    const keys = arraySortByArray(Object.keys(flattenData), [
      profileScreen.input.surname.name,
      profileScreen.input.firstName.name,
      profileScreen.input.middleName.name
    ])

    const notRequiredKeys = [
      profileScreen.input.middleName.name,
      `registrationAddress.${profileScreen.input.registrationAddressdistrict.name}`,
      `registrationAddress.${profileScreen.input.registrationAddresscity.name}`,
      `registrationAddress.${profileScreen.input.registrationAddresssettlement.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressbuilding.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressapartment.name}`
    ]

    keys?.map(key => {
      if (key === profileScreen.input.withoutMiddleName.name) {
        return
      }
      data.push({
        key,
        required: !notRequiredKeys.includes(key),
        fieldName: profileScreen.input[key.replace('.', '')]?.label,
        newValue: flattenData?.[key],
        isDifferentFromProfile: flattenProfileData?.[key] !== flattenData?.[key],
        specialValidation: [
          ...(key === profileScreen.input.birthDate.name ? getBirthDateRules() : []),
          ...(key === `passport.${profileScreen.input.passportissueDate.name}` ? getPassportIssueDateRules() : [])
        ]
      })
    })
    setProfileData(data)
  }, [cardFormData?.form, flattenProfileData, profile])

  useEffect(() => {
    if (acceptRequestError) {
      message.error('Не удалось принять запрос.')
    }
  }, [acceptRequestError])

  useEffect(() => {
    if (acceptRequestSuccess) {
      if (acceptRequestData?.errorMessage) {
        return message.error(acceptRequestData?.errorMessage || 'Не удалось принять запрос.')
      }
      if (closeModalAfterAccept) {
        handleCancel()
        setCloseModalAfterAccept(false)
      }
      message.success('Запрос на изменение данных принят.')
      refetchCardFormData({ partnerId, isRefetching: true })
    }
  }, [acceptRequestData, acceptRequestSuccess])

  useEffect(() => {
    if (declineRequestError) {
      message.error('Не удалось отклонить запрос.')
    }
  }, [declineRequestError])

  useEffect(() => {
    if (declineRequestSuccess) {
      if (declineRequestData?.errorMessage) {
        return message.error(declineRequestData?.errorMessage || 'Не удалось отклонить запрос.')
      }
      handleCancel()
      message.success('Запрос на изменение данных отклонен.')
    }
  }, [declineRequestData, declineRequestSuccess])

  const getAcceptButton = useCallback((requestData, fieldName) => {
    return (
      <Button
        size='small' type='primary' onClick={() => {
          const data = getOnlyInputFieldInPreparedData(requestData, prepareAcceptRequestData(requestData))
          acceptRequest({
            id: partnerId,
            data: {
              ...data,
              data: {
                ...data.data,
                ...(cardFormData?.form?.hasNfc && { hasNfc: cardFormData?.form?.hasNfc }),
                ...(cardFormData?.form?.registrationKindCode && { registrationKind: cardFormData?.form?.registrationKindCode })
              }
            }
          })
        }}
        disabled={fieldsHasErrors?.includes(fieldName)}
      >
        Принять
      </Button>
    )
  }, [acceptRequest, partnerId, fieldsHasErrors, cardFormData])

  const tableProps = useMemo(() => ({
    backendFieldsErrors,
    setHasErrors: setFieldsHasErrors,
    columns: [...columns,
      ...!viewOnly ? [{
        title: '',
        dataIndex: 'action',
        width: 105,
        render: (value, record) => {
          return (!(!flattenProfileData[record.key] && !record.newValue) && record.newValue !== flattenProfileData[record.key]) || record.isEdited
            ? getAcceptButton(
              restoreFlattenObject({ [record.key]: record.newValue }),
              record.key
            )
            : <>&nbsp;</>
        }
      }] : []],
    dataSource: profileData,
    setDataSource: setProfileData,
    pagination: false
  }), [backendFieldsErrors, getAcceptButton, profileData, viewOnly])

  const profilePhotos = useMemo(() => {
    const list = documents.reduce((photos, doc) => {
      if (profile?.[doc]?.visible) {
        return {
          ...photos,
          ...(
            cardFormData?.form?.[doc]?.fileId
              ? { [doc]: cardFormData?.form?.[doc] }
              : { [doc]: profile?.[doc] }
          )
        }
      }
      return photos
    }, {})
    return list
  }, [profile, documents, cardFormData])

  const getDocumentStatus = useCallback((doc) => {
    return profile?.[doc]?.fileId && !cardFormData?.form?.[doc]?.fileId
      ? DELETED
      : profile?.[doc]?.fileId === cardFormData?.form?.[doc]?.fileId
        ? ACCEPTED
        : NONE
  }, [profile, cardFormData])

  const getDocumentButton = useCallback((doc) => {
    return viewOnly || profile?.[doc]?.fileId === cardFormData?.form?.[doc]?.fileId
      ? null
      : profile?.[doc]?.fileId && !cardFormData?.form?.[doc]?.fileId
        ? <Text type='danger'>Удален</Text>
        : getAcceptButton({ [doc]: cardFormData?.form?.[doc]?.fileId })
  }, [viewOnly, profile, getAcceptButton, cardFormData])

  return (
    <div>
      <Button
        ghost
        type='primary'
        className='w-150'
        disabled={disabled}
        onClick={showModal}
      >
        {viewOnly ? 'Посмотреть' : 'Проверить'}
      </Button>
      <Modal
        width={1200}
        style={{ top: 20 }}
        title={
          <Row gutter={[12, 6]} align='middle'>
            <Col className='mr-3'><b>Анкета на получение банковской карты</b></Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className='assetsModalForm'
      >
        <Row className='mb-3'>
          <Text underline>Тип занятости: <b>{profile?.personalData?.legalForm}</b></Text>
        </Row>
        {profile?.registrationFlow && <RegistrationKindItem form={cardFormData?.form} />}
        <Spin spinning={isLoadingCardFormData || acceptRequestLoading || declineRequestLoading}>
          {
            cardFormData?.form?.statusReason &&
              <Alert
                className='mb-3'
                message={<Text type='secondary'>Причины отказа</Text>}
                description={cardFormData?.form.statusReason}
                type='error'
              />
          }
          {
            viewOnly ? <Table {...tableProps} /> : <EditableTable {...tableProps} />
          }
          <CityChainStoreItem
            data={cardFormData?.form}
            acceptButton={
              viewOnly || profile?.personalData?.storeId === cardFormData?.form?.store?.id
                ? null
                : getAcceptButton({ storeId: cardFormData?.form?.store?.id })
            }
          />
          <Divider style={{ border: 0 }} />
          <PartnerDocuments
            partnerId={partnerId}
            documents={documents}
            setPartner={setPartner}
            profile={{ ...cardFormData?.form, ...profilePhotos }}
            getDocumentStatus={getDocumentStatus}
            getDocumentButton={getDocumentButton}
            correctForm={AppConfig.updateDocFormNames.cardForm}
          />
          <Divider />
          {
            viewOnly
              ? <Button type='primary' onClick={handleCancel}>Закрыть</Button>
              : (
                <Approval
                  onCommit={handleCommit}
                  onCancel={handleCancel}
                  multiline
                  multilineRows={2}
                  disabled={fieldsHasErrors?.length}
                  autocomplete
                  targetType={AppConfig.reasonTargets.cardForm}
                  isAbleCondition
                />
              )
          }
        </Spin>
      </Modal>
    </div>
  )
}

export default EditCardRequest
