import React, { useEffect, useMemo, useReducer } from 'react'
import { Button, Checkbox, Col, Input } from 'antd'

import './AutoCompleteFilterStyle.css'
import { simpleReducer } from '../../helper'

function AutoCompleteFilter ({ values, setSelectedKeys, selectedKeys = [], confirm, clearFilters }) {
  const initialState = useMemo(
    () => ({
      filteredValues: values,
      searchText: ''
    }),
    [values]
  )

  const [state, dispatch] = useReducer(simpleReducer, initialState)

  const search = e => {
    dispatch({
      searchText: e.target?.value,
      filteredValues: values.filter(
        // фильтруем по полю item.text так как в поле item.value может быть id
        item => !!~item.text?.toLowerCase()?.indexOf(e.target?.value?.toLowerCase())
      )
    })
  }

  const reset = () => {
    dispatch(initialState)
    clearFilters()
  }

  const handleChange = e => {
    const keys = [...selectedKeys]
    if (e.target.checked) {
      keys.push(e.target.value)
    } else {
      const index = selectedKeys.indexOf(e.target.value)
      if (index >= 0) {
        keys.splice(index, 1)
      }
    }
    setSelectedKeys(keys)
  }

  // External filters clear
  useEffect(() => {
    if (selectedKeys.length === 0) {
      dispatch(initialState)
    }
  }, [selectedKeys, dispatch, initialState])

  return (
    <div className='ant-table-filter-dropdown autocomplete-filter'>
      <Col span={24} className='tooltip-container'>
        <Input
          placeholder='Начните вводить название...'
          onChange={search}
          value={state.searchText}
          allowClear
        />
      </Col>
      <div className='checkbox-group'>
        {state.filteredValues?.map(item => (
          <Checkbox
            key={item.value}
            value={item.value}
            checked={!!~selectedKeys.indexOf(item.value)}
            onChange={handleChange}
          >
            {item.text}
          </Checkbox>
        ))}
      </div>
      <div className='ant-table-filter-dropdown-btns'>
        <Button type='link' onClick={reset} className='ant-table-filter-dropdown-link clear'>
          Сбросить
        </Button>
        <Button
          type='primary'
          onClick={() => confirm()}
          className='ant-table-filter-dropdown-link confirm'
        >
          OK
        </Button>
      </div>
    </div>
  )
}

export default AutoCompleteFilter
