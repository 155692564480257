import React, { useReducer } from 'react'
import { Form, Input } from 'antd'
import { profileScreen } from '../../../../translates'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { simpleReducer } from '../../../../helper'
import MedicalDocModal, { medicalDocTypes } from '../../../Partner/PhotoDoc/MedicalDocModal'

const initialState = {
  isOpenLabelModal: false
}

function MedicalBookNumberFormItem ({
  label = profileScreen.input.medicalBookNumber.label,
  rules = undefined,
  required = false,
  messageRule = profileScreen.input.medicalBookNumber.message,
  size = 'large',
  placeholder = profileScreen.input.medicalBookNumber.placeholder
}) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleIconQuestionClick = (event) => {
    event.preventDefault()
    setState({ isOpenLabelModal: true })
  }

  const handleCloseLabelModal = () => {
    setState({ isOpenLabelModal: false })
  }

  return (
    <>
      <Form.Item
        name={['lmkDetails', profileScreen.input.medicalBookNumber.name]}
        label={<>{label} <QuestionCircleOutlined onClick={handleIconQuestionClick} style={{ marginLeft: 4 }} /></>}
        rules={rules ?? [{ required: required, message: messageRule }]}
      >
        <Input
          className='noScrollNumber'
          size={size}
          type='number'
          placeholder={placeholder}
        />
      </Form.Item>
      <MedicalDocModal
        type={medicalDocTypes.medicalBookNumber}
        visible={state.isOpenLabelModal}
        onCancel={handleCloseLabelModal}
      />
    </>
  )
}

export default MedicalBookNumberFormItem
