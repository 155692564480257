import React, { useEffect, useState, useReducer } from 'react'
import { Button, Col, List, message, Row, Spin, Typography } from 'antd'
import moment from 'moment-timezone'

import { useMutateAdminPartnerActText, useQueryAdminPartnerActs } from '../../../../api/react-query/adminPartners'
import { ruCurrency, simpleReducer } from '../../../../helper'
import { AppConfig } from '../../../../AppConfig'
import { useActTypesQuery } from '../../../../api/react-query/dict'
import AssetStatus from './AssetStatus'
import { CreateActWithAssetsPreview } from './index'

const { Text, Title } = Typography

const initialState = {
  actData: null
}

export default function PartnerAssetsHistory ({ partner }) {
  const [acts, setActs] = useState([])
  const [actTemplatesTypes, setActTemplatesTypes] = useState([])
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    data: dataPartnerActs,
    isError: isErrorPartnerActs,
    isLoading: isLoadingPartnerActs,
    isSuccess: isSuccessPartnerActs
  } = useQueryAdminPartnerActs({
    id: partner.id
  }, { enabled: !!partner.id })

  const {
    mutate: getPartnerActText,
    isSuccess: isSuccessActText,
    isError: isErrorActText,
    isLoading: isLoadingActText,
    data: dataActText
  } = useMutateAdminPartnerActText()

  useEffect(() => {
    if (isSuccessActText && dataActText?.isSuccess) {
      setState({ actData: dataActText })
    } else if (isErrorActText || dataActText?.errorMessage) {
      message.error(dataActText?.errorMessage || 'Ошибка получения данных об Акте')
    }
  }, [isSuccessActText, isErrorActText, dataActText])

  useEffect(() => {
    if (isSuccessPartnerActs) {
      const items = dataPartnerActs?.acts?.reverse().reduce((prev, { lines, ...act }) => {
        const fullLines = lines.map(line => ({ ...line, ...act }))
        return [...prev, ...fullLines]
      }, [])
      setActs(items || [])
    } else if (isErrorPartnerActs) {
      console.error(dataPartnerActs)
      message.error('Ошибка получения актов')
    }
  }, [isErrorPartnerActs, isSuccessPartnerActs, dataPartnerActs])

  const {
    data: actTemplatesTypesData,
    isError: isErrorActTemplatesTypes,
    isSuccess: isSuccessActTemplatesTypes
  } = useActTypesQuery()

  useEffect(() => {
    if (isSuccessActTemplatesTypes) {
      setActTemplatesTypes(actTemplatesTypesData?.result)
    } else if (isErrorActTemplatesTypes) {
      console.error(actTemplatesTypesData)
      message.error('Ошибка получения списка типов актов')
    }
  }, [actTemplatesTypesData, isErrorActTemplatesTypes, isSuccessActTemplatesTypes])

  const handleActTextShow = (actId) => {
    getPartnerActText({ id: partner?.id, actId })
  }

  const handleActTextShowOk = () => {
    setState(initialState)
  }

  const handleActTextShowCancel = () => {
    setState(initialState)
  }

  return (
    <>
      <Title className='mb-3 mt-3' level={5}>Хронологический отчет о переданном, возвращенном и списанном имуществе:</Title>
      <Spin spinning={isLoadingPartnerActs}>
        {acts.length > 0 ? (
          <>
            <List
              bordered
              className='list'
              itemLayout='horizontal'
              dataSource={acts}
              header={(
                <Row gutter={[12, 6]}>
                  <Col span={4}>Тип акта</Col>
                  <Col span={8}>Имущество</Col>
                  <Col span={2}>Цена</Col>
                  <Col span={2}>Кол-во</Col>
                  <Col span={2}>Стоимость</Col>
                  <Col span={3}>Автор акта</Col>
                  <Col span={3}>Статус</Col>
                </Row>
              )}
              renderItem={item => (
                <List.Item>
                  <Row gutter={[12, 6]} className='fill-content'>
                    <Col
                      span={4}
                      className={`act-type-${actTemplatesTypes.find(type => type.id === item.type)?.id}`}
                    >
                      <Button
                        className={`act-type-${actTemplatesTypes.find(type => type.id === item.type)?.id}`}
                        style={{ whiteSpace: 'normal', textAlign: 'left', padding: 0 }}
                        disabled={isLoadingActText}
                        type='link'
                        onClick={() => handleActTextShow(item.id)}
                        size='small'
                      >
                        {`${actTemplatesTypes.find(type => type.id === item.type)?.name} от ${moment(item.createdAt).format(AppConfig.formats.shortDateAndTime)}`}
                      </Button>
                    </Col>
                    <Col span={8}>{item.assetName}</Col>
                    <Col span={2}>{item.price.toFixed(2)}<small><Text type='secondary'>{ruCurrency}</Text></small></Col>
                    <Col span={2}>{item.quantity}шт</Col>
                    <Col span={2}>{item.amount.toFixed(2)}<small><Text type='secondary'>{ruCurrency}</Text></small></Col>
                    <Col span={3}>{item.createdBy}</Col>
                    <Col span={3}>
                      <AssetStatus item={item} />
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </>
        ) : (
          <Text className='ml-3' type='secondary'>Имущество не найдено.</Text>
        )}
        {state.actData && (
          <CreateActWithAssetsPreview
            preview={state.actData?.text}
            onClose={handleActTextShowCancel}
            onSave={handleActTextShowOk}
            title={state.actData?.templateData?.act?.type}
          />
        )}
      </Spin>
    </>
  )
}
