import { Form } from 'antd'
import React from 'react'
import { MaskedInput } from 'antd-masked-input'
import { profileScreen, errors } from '../../../../translates'
import { validateDate, validateDate1BeforeDate2, validateDateNotFuture } from '../../../../helper'
import moment from 'moment-timezone'
import { AppConfig } from '../../../../AppConfig'

const MedicalExamDateFormItem = ({
  label = profileScreen.input.medicalexaminationDate.label,
  rules = undefined,
  required = false,
  messageRule = profileScreen.input.medicalexaminationDate.message,
  size = 'large',
  placeholder = profileScreen.input.medicalexaminationDate.placeholder
}) => {
  return (
    <Form.Item
      name={['lmkDetails', profileScreen.input.medicalexaminationDate.name]}
      label={label}
      rules={rules ?? [
        { required: required, message: messageRule, validator: validateDate },
        {
          message: errors.oldDate,
          validator: (rule, value) => validateDate1BeforeDate2({
            rule,
            date1: moment().subtract(profileScreen.input.medicalexaminationDate.yearExpire, 'year').format(AppConfig.formats.shortDate),
            date2: value
          })
        },
        {
          message: errors.futureDate,
          validator: (rule, value) => validateDateNotFuture({ rule, date: value })
        }
      ]}
    >
      <MaskedInput
        size={size}
        placeholder={placeholder}
        mask='11.11.1111'
      />
    </Form.Item>
  )
}
export default MedicalExamDateFormItem
