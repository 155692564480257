import { Checkbox, Form, Input, message, Modal } from 'antd'
import React, { useEffect, useMemo } from 'react'

import { useMutateAssetCreate } from '../../../api/react-query/assets'
import { AppConfig } from '../../../AppConfig'

const layout = {
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  },
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  }
}

const NewAsset = ({
  visible = false,
  onClose = () => {}
}) => {
  const [form] = Form.useForm()

  const {
    mutate: mutateAssetCreate,
    data: dataAssetCreate,
    isError: isErrorAssetCreate,
    isLoading: isLoadingAssetCreate
  } = useMutateAssetCreate()

  useEffect(() => {
    if (isErrorAssetCreate || dataAssetCreate?.errorMessage) {
      message.error(dataAssetCreate?.errorMessage || 'Ошибка добавления имущества')
    } else if (dataAssetCreate?.isSuccess) {
      message.success('Имущество успешно добавлено')
      onClose()
    }
  }, [dataAssetCreate, isErrorAssetCreate, onClose])

  const handleOnSave = () => {
    form.validateFields()
      .then(fields => {
        if (!form.isFieldsTouched()) return onClose()
        mutateAssetCreate(fields)
      })
      .catch(error => console.log(error))
  }
  const okButtonProps = useMemo(
    () => ({ className: 'green', loading: isLoadingAssetCreate }),
    [isLoadingAssetCreate]
  )
  const validatorPrice = (rule, value) => {
    const decimal = '' + ((value + '').split('.')[1] || 0)
    return value > 0 && decimal.length < 3 ? Promise.resolve() : Promise.reject(rule?.message)
  }

  return (
    <Modal
      width={700}
      visible={visible}
      title='Добавление имущества'
      onCancel={onClose}
      okText='Сохранить'
      cancelText='Отменить'
      okButtonProps={okButtonProps}
      onOk={handleOnSave}
      destroyOnClose
    >
      <Form
        {...layout}
        name='newAsset'
        form={form}
        scrollToFirstError
        initialValues={{
          isSuit: false,
          hasSerial: false
        }}
      >
        <Form.Item
          name='name'
          label='Наименование'
          rules={[{ required: true, message: 'Пожалуйста, укажите наименование' }]}
        >
          <Input
            size='middle'
            placeholder='Наименование'
          />
        </Form.Item>
        <Form.Item
          name={['inventory', AppConfig.inventoryTypes.new]}
          label={`Код СБИС ${AppConfig.inventoryTypeLabels.new}`}
          rules={[
            { required: true, message: 'Пожалуйста, укажите код СБИС новый' }
            // {
            //   message: adminValidationErrors.uid,
            //   validator: (rule, value) => validator({
            //     rule, value, name: 'uid'
            //   })
            // }
          ]}
        >
          <Input size='middle' />
        </Form.Item>

        <Form.Item
          name={['inventory', 'usedName']}
          label={`${AppConfig.inventoryTypeLabels.used} наименование`}
          rules={[
            // {
            //   message: adminValidationErrors.uid,
            //   validator: (rule, value) => validator({
            //     rule, value, name: 'letterDigit'
            //   })
            // },
            (form) => ({
              message: `Пожалуйста, укажите ${AppConfig.inventoryTypeLabels.used} наименование`,
              validator: (rule, value) => {
                const isSuit = form.getFieldValue('isSuit')
                const fields = form.getFieldsValue('inventory')
                const used = fields.inventory[AppConfig.inventoryTypes.used]
                if (isSuit && used && !value) {
                  return Promise.reject(rule?.message)
                }

                return Promise.resolve()
              }
            })
          ]}
        >
          <Input
            size='middle'
            placeholder={`${AppConfig.inventoryTypeLabels.used} наименование`}
          />
        </Form.Item>
        <Form.Item
          name={['inventory', AppConfig.inventoryTypes.used]}
          label={`Код СБИС ${AppConfig.inventoryTypeLabels.used}`}
          rules={[
            // {
            //   message: adminValidationErrors.uid,
            //   validator: (rule, value) => validator({
            //     rule, value, name: 'uid'
            //   })
            // },
            (form) => ({
              message: 'Не указан код',
              validator: (rule, value) => {
                const isSuit = form.getFieldValue('isSuit')
                const inventoryFields = form.getFieldsValue('inventory')
                const { usedName } = inventoryFields.inventory
                // const disposal = inventoryFields.inventory[AppConfig.inventoryTypes.disposal]
                if (isSuit && usedName && !value) {
                  return Promise.reject(rule?.message)
                }

                return Promise.resolve()
              }
            })
          ]}
        >
          <Input size='middle' />
        </Form.Item>

        <Form.Item
          name={['inventory', 'disposalName']}
          label={`${AppConfig.inventoryTypeLabels.disposal} наименование`}
          rules={[
            // {
            //   message: adminValidationErrors.uid,
            //   validator: (rule, value) => validator({
            //     rule, value, name: 'letterDigit'
            //   })
            // },
            (form) => ({
              message: `Пожалуйста, укажите ${AppConfig.inventoryTypeLabels.disposal} наименование`,
              validator: (rule, value) => {
                const isSuit = form.getFieldValue('isSuit')
                const fields = form.getFieldsValue('inventory')
                const disposal = fields.inventory[AppConfig.inventoryTypes.disposal]
                if (isSuit && disposal && !value) {
                  return Promise.reject(rule?.message)
                }

                return Promise.resolve()
              }
            })
          ]}
        >
          <Input
            size='middle'
            placeholder={`${AppConfig.inventoryTypeLabels.disposal} наименование`}
          />
        </Form.Item>
        <Form.Item
          name={['inventory', AppConfig.inventoryTypes.disposal]}
          label={`Код СБИС ${AppConfig.inventoryTypeLabels.disposal}`}
          rules={[
            // {
            //   message: adminValidationErrors.uid,
            //   validator: (rule, value) => validator({
            //     rule, value, name: 'uid'
            //   })
            // },
            (form) => ({
              message: 'Не указан код',
              validator: (rule, value) => {
                const isSuit = form.getFieldValue('isSuit')
                const inventoryFields = form.getFieldsValue('inventory')
                const { disposalName } = inventoryFields.inventory
                if (isSuit && disposalName && !value) {
                  return Promise.reject(rule?.message)
                }

                return Promise.resolve()
              }
            })
          ]}
        >
          <Input size='middle' />
        </Form.Item>
        <Form.Item
          name='price'
          label='Стоимость (за одну штуку)'
          rules={[{
            required: true,
            message: 'Пожалуйста, укажите стоимость отличную от нуля и не больше 2 знаков после запятой',
            validator: validatorPrice
          }]}
        >
          <Input
            size='middle'
            type='number'
            placeholder='Стоимость'
            autoComplete='off'
          />
        </Form.Item>
        <Form.Item
          name='isSuit'
          label='Форма'
          valuePropName='checked'
          rules={[
            (form) => ({
              message: 'Для создания формы требуется указать наличие данных Б/У или Утилизации',
              validator: (rule, value) => {
                if (value === false) return Promise.resolve()

                const inventoryFields = form.getFieldsValue('inventory')
                const { disposalName, usedName } = inventoryFields.inventory
                const disposal = inventoryFields.inventory[AppConfig.inventoryTypes.disposal]
                const used = inventoryFields.inventory[AppConfig.inventoryTypes.used]

                if ((used && usedName) || (disposalName && disposal)) {
                  return Promise.resolve()
                }

                return Promise.reject(rule?.message)
              }
            })
          ]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name='hasSerial'
          label='С серийным номером'
          valuePropName='checked'
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default NewAsset
