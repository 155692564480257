import React from 'react'
import CorrectInfo from './ErrorStep/CorrectInfo'
import { flowV2Steps } from './ProfileV2'

function LegalAgeStep () {
  return (
    <>
      <CorrectInfo step={flowV2Steps.legalAgeStep} />
    </>
  )
}

export default LegalAgeStep
