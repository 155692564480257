import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Col, Divider, message, Row, Space, Typography } from 'antd'

import Approval from '../../Approval/Approval'
import { useMutateAdminUpdatePartnerBankDetails } from '../../../../api/react-query/adminPartners'
import EditBankDetailsData from '../EditModals/EditBankDetailsData'
import { ConfigContext } from '../../../../context/configContext'
import DocumentPreview from './DocumentPreview'
import { profileScreen } from '../../../../translates'
import { AppConfig } from '../../../../AppConfig'

const { Text } = Typography

function PartnerBank ({ bankDetails, bankDetailsPhoto, loading, onCommit, partnerId, allowEdit, isIndividual }) {
  const {
    isSecurity,
    isAdmin,
    isCoordinator,
    isExternalCoordinator,
    isPartnerSupport,
    portalButtonState,
    user
  } = useContext(ConfigContext)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    mutate: mutateUserUpdateBankDetails,
    isError: isErrorUserUpdateBankDetails,
    isLoading: isLoadingUserUpdateBankDetails,
    isSuccess: isSuccessUserUpdateBankDetails
  } = useMutateAdminUpdatePartnerBankDetails()

  useEffect(() => {
    if (isErrorUserUpdateBankDetails) {
      message.error('Ошибка обновления данных партнера')
    }
  }, [isErrorUserUpdateBankDetails])
  useEffect(() => {
    if (isSuccessUserUpdateBankDetails) {
      message.success('Данные партнера успешно обновлены')
      handleCloseEditModal()
    }
  }, [isSuccessUserUpdateBankDetails])

  const handleOpenEditModal = () => {
    if (user?.online && (isExternalCoordinator || isCoordinator)) {
      portalButtonState?.stopStatusRequesting()
    }
    setIsModalOpen(true)
  }
  const handleCloseEditModal = useCallback(() => {
    if (user?.online && (isExternalCoordinator || isCoordinator)) {
      portalButtonState?.startStatusRequesting()
    }
    setIsModalOpen(false)
  }, [user?.online, isExternalCoordinator, isCoordinator, portalButtonState])

  const handleCommit = (d, o, c) => onCommit(d, o, c)

  return (
    <>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Row>
          <Col span={8}><Text type='secondary'>ФИО Банковских реквизитов</Text></Col>
          <Col span={16}>
            <Row justify='space-between'>
              <Text>{bankDetails?.name}</Text>
              {
                !isSecurity && allowEdit && <Button type='primary' size='small' onClick={handleOpenEditModal}>Редактировать</Button>
              }
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={8}><Text type='secondary'>Расчетный счет</Text></Col>
          <Col span={16}><Text>{bankDetails?.bankAccount}</Text></Col>
        </Row>
        <Row>
          <Col span={8}><Text type='secondary'>БИК</Text></Col>
          <Col span={16}><Text>{bankDetails?.bankBik}</Text></Col>
        </Row>
        <Row>
          <Col span={8}><Text type='secondary'>Название банка</Text></Col>
          <Col span={16}><Text>{bankDetails?.bankName}</Text></Col>
        </Row>
        <Row>
          <Col>
            <DocumentPreview
              document={bankDetailsPhoto}
              text='Банковские реквизиты'
              partnerId={partnerId}
              documentType={profileScreen.input.bankDetailsPhoto.name}
            />
          </Col>
        </Row>
      </Space>
      {bankDetails?.allowReview &&
        <>
          <Divider />
          <Approval
            loading={loading}
            defaultOutcome={bankDetails?.status}
            defaultComment={bankDetails?.statusReason}
            onCommit={({ outcome, comment }) => handleCommit('bankDocument', outcome, comment)}
            targetType={AppConfig.reasonTargets.bankDetails}
            autocomplete={Boolean(isAdmin || isCoordinator || isPartnerSupport || isExternalCoordinator)}
          />
        </>}
      {isModalOpen &&
        <EditBankDetailsData
          onCancel={handleCloseEditModal}
          bankDetails={bankDetails}
          partnerId={partnerId}
          onChange={mutateUserUpdateBankDetails}
          visible={isModalOpen}
          loading={isLoadingUserUpdateBankDetails}
          isIndividual={isIndividual}
        />}
    </>
  )
}

export default PartnerBank
