import { Button, Typography, Modal, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'

import { useMutateAdminArchivePartner } from '../../../../api/react-query/adminPartners'
import { useParams } from 'react-router-dom'

const { Text } = Typography

const ToArchive = ({
  allowArchive = false
}) => {
  const { id } = useParams()
  const [isShow, setIsShow] = useState(false)

  const {
    mutate: mutateArchivePartner,
    data: dataArchivePartner,
    isError: isErrorArchivePartner,
    isLoading: isLoadingArchivePartner,
    isSuccess: isSuccessArchivePartner
  } = useMutateAdminArchivePartner()

  useEffect(() => {
    if (isErrorArchivePartner) {
      message.error(dataArchivePartner?.errorMessage || 'Ошибка смены занятости Партнера.')
    }
  }, [dataArchivePartner, isErrorArchivePartner])
  useEffect(() => {
    if (isSuccessArchivePartner) {
      if (dataArchivePartner?.errorMessage) {
        return message.error(dataArchivePartner?.errorMessage)
      }
      message.success('Успешная смена занятости партнера.')
    }
  }, [dataArchivePartner, isSuccessArchivePartner])

  const handleOnSave = () => mutateArchivePartner({ id })
  const okButtonProps = useMemo(() => ({ className: 'green', loading: isLoadingArchivePartner }), [isLoadingArchivePartner])
  const cancelButtonProps = useMemo(() => ({ disabled: isLoadingArchivePartner }), [isLoadingArchivePartner])
  if (!allowArchive) return null

  return (
    <>
      <Button onClick={() => setIsShow(true)} className='mb-3'>
        Смена занятости
      </Button>
      <Modal
        width={700}
        visible={isShow}
        title='Смена занятости Партнера'
        onCancel={() => setIsShow(false)}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
        onOk={handleOnSave}
      >
        <Text>При смене занятости Партнера будет расторгнута оферта и запрещены выплаты. Номер телефона Партнера станет доступным для повторной регистрации на Портале.</Text>
      </Modal>
    </>
  )
}
export default ToArchive
