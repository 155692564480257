import React, { useEffect } from 'react'
import {
  Typography,
  Form,
  Col,
  Row,
  Button, message, Divider
} from 'antd'

import './Report.css'
import { AppConfig } from '../../../AppConfig'
import { useGetReportsQueue, useMutateDispatchReportToQueue } from '../../../api/react-query/reports'
import ReportList from '../../../components/Admin/Reports/ReportList'

const { Title } = Typography

export default function BankRequisiteReport () {
  const [form] = Form.useForm()

  const {
    isError: isErrorReportsQueue,
    data: dataReportsQueue,
    isLoading: isLoadingReportsQueue,
    refetch: refetchReportsQueue
  } = useGetReportsQueue(AppConfig.report.types.bankRequisite)

  useEffect(() => {
    if (isErrorReportsQueue || dataReportsQueue?.errorMessage) {
      message.error(dataReportsQueue?.errorMessage || 'Не удалось получить отчеты')
    }
  }, [isErrorReportsQueue, dataReportsQueue])

  const {
    mutate: addBankRequisiteReportToQueue,
    isError: isErrorAddBankRequisiteReportToQueue,
    data: dataAddBankRequisiteReportToQueue,
    isLoading: isLoadingAddBankRequisiteReportToQueue
  } = useMutateDispatchReportToQueue()

  useEffect(() => {
    if (isErrorAddBankRequisiteReportToQueue || dataAddBankRequisiteReportToQueue?.errorMessage) {
      message.error(dataAddBankRequisiteReportToQueue?.errorMessage || 'Не удалось поставить отчет в очередь')
    }
  }, [isErrorAddBankRequisiteReportToQueue, dataAddBankRequisiteReportToQueue])

  const handleGetReport = () => {
    addBankRequisiteReportToQueue({
      type: AppConfig.report.types.bankRequisite
    })
  }

  return (
    <>
      <Title level={3} className='title-management'>Отчет заполнения БР</Title>
      <Form
        form={form}
        onFinish={handleGetReport}
      >

        <Row>
          <Col className='mr-3'>
            <Button
              type='primary'
              htmlType='submit'
              loading={isLoadingAddBankRequisiteReportToQueue}
            >
              Запросить отчет
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      <Row className='mt-3' gutter={15} align='middle'>
        <Col>
          <Title level={5}>Отчеты</Title>
        </Col>
        <Col>
          <Button onClick={() => refetchReportsQueue()}>Обновить</Button>
        </Col>
      </Row>
      <ReportList
        isLoadingReports={isLoadingReportsQueue}
        dataReports={dataReportsQueue?.items}
      />
    </>
  )
}
