import React, { useEffect, useState } from 'react'
import { Button, Col, Form, message, Modal, Row, Spin, Input } from 'antd'
import { useMutateReturnCardAdmin } from '../../../../api/react-query/adminPartners'

const { TextArea } = Input

const ReturnCardRequest = ({ partnerId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)
  const [form] = Form.useForm()

  const {
    mutate: returnCard,
    data: returnCardData,
    isLoading: isLoadingReturnCard,
    isError
  } = useMutateReturnCardAdmin()

  useEffect(() => {
    if (isError || returnCardData?.errorMessage) {
      message.error(returnCardData?.errorMessage || 'Не удалось отправить запрос на возврат анкеты')
    } else if (returnCardData?.isSuccess) {
      message.success('Запрос на возврат анкеты принят')
      handleCancel()
    }
  }, [returnCardData, isError])

  return (
    <div>
      <Button
        ghost
        type='primary'
        size='middle'
        onClick={showModal}
        className='mr-3'
      >
        Вернуть анкету
      </Button>
      <Modal
        width={700}
        title={
          <Row gutter={[12, 6]} align='middle'>
            <Col className='mr-3'><b>Возврат анкеты</b></Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className='assetsModalForm'
      >
        <Spin spinning={isLoadingReturnCard}>
          <Form
            layout='vertical'
            name='reissueCard'
            form={form}
            onFinish={(data) => returnCard({ id: partnerId, data })}
          >
            <Form.Item
              label='Причина отклонения анкеты'
              name='reason'
              rules={[{ required: true, message: 'Укажите, пожалуйста, причину отклонения анкеты' }]}
            >
              <TextArea />
            </Form.Item>
            <Row justify='end'>
              <Col><Row justify='end'><Button htmlType='submit' type='primary' className='mr-3' size='middle'>Вернуть анкету</Button></Row></Col>
              <Col><Row justify='end'><Button onClick={handleCancel} size='middle'>Отмена</Button></Row></Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
}

export default ReturnCardRequest
