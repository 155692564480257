import React, { useState } from 'react'
import { Button, Modal, Row, Col, Divider } from 'antd'

import {
  WriteOffAssetsModalButton,
  TransferAssetsModalButton,
  ReturnAssetsModalButton,
  PartnerActs,
  PartnerAssets,
  PartnerAssetsHistory
} from './'
import './Assets.css'

function Assets ({ partner }) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  return (
    <div>
      <Button type='primary' onClick={showModal}>
        Имущество
      </Button>
      <Modal
        width={1200}
        style={{ top: 20 }}
        title={
          <Row gutter={[12, 6]} align='middle'>
            <Col className='mr-3'><b>Имущество</b></Col>
            <Col><TransferAssetsModalButton partner={partner} /></Col>
            <Col><ReturnAssetsModalButton partner={partner} /></Col>
            <Col><WriteOffAssetsModalButton partner={partner} /></Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className='assetsModalForm'
      >
        <>
          <PartnerAssets partner={partner} />
          <PartnerActs partner={partner} />
          <PartnerAssetsHistory partner={partner} />
          <Divider className='small' />
        </>
      </Modal>
    </div>
  )
}

export default Assets
