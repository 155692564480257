import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DownOutlined, PlusOutlined } from '@ant-design/icons'
import { message, Tree, Spin, Typography } from 'antd'

import { useOrgTreeQuery } from '../../../api/react-query/org'
import { twoMinutes } from '../../../Constants'
import { sortByName } from '../../../helper'

const { Text } = Typography

export default function OrgTree ({
  height, // высота виртуального скрола дерева
  onSelect = () => {},
  isStoreOpen, // фильтр для запроса дерева
  isStoreClose, // фильтр для запроса дерева
  withEmptyCity = true, // фильтр для запроса дерева
  treeData,
  switcherIcon = <DownOutlined />, // иконка разворачивания дерева
  filteredCity = '', // фильтр по городам для отображения дерева
  filteredChain = '', // фильтр по сетям для отображения дерева
  withEditing, // показывать елементы добавления в дерево
  checkable,
  disabled = false,
  checkedKeys = [],
  onCheck = () => {},
  renderIcon, // функция которая возвращает иконку которая будет отображаться рядом с каждым элементом
  selectable = false
}) {
  const [orgTree, setOrgTree] = useState([])

  useEffect(() => {
    if (Object.keys(treeData || {})?.length) {
      setOrgTree(sortByName(treeData?.cities))
    }
  }, [treeData])

  const {
    isSuccess,
    isError,
    data,
    isLoading
  } = useOrgTreeQuery({
    withEmptyCity,
    withClosed: !isStoreOpen,
    withOpened: !isStoreClose
  }, {
    staleTime: twoMinutes,
    enabled: !Object.keys(treeData || {})?.length
  })

  useEffect(() => {
    if (isSuccess && !Object.keys(treeData || {})?.length) {
      const treeData = sortByName(data?.cities)
      setOrgTree(treeData)
    }
  }, [data, isSuccess, treeData])
  /**
   * Errors from api requests
   */
  useEffect(() => {
    if (isError || data?.errorMessage) {
      message.error(data?.errorMessage || data?.title || 'Ошибка доступа к данным')
    }
  }, [isError, data])

  const newElement = useCallback((title, type) => {
    if (!withEditing) return []
    return [{
      title: <Text type='secondary'> Добавить {title}</Text>,
      key: type ? `${type},new` : 'new',
      icon: <PlusOutlined />
    }]
  }, [withEditing])

  const tree = useMemo(() => {
    if (!orgTree?.length) return [...newElement('город')]
    let filtered = orgTree
      .filter(city => city?.name?.toLowerCase()?.includes(filteredCity?.toLowerCase()))
      .filter(city => {
        if (!filteredChain) return true
        return city?.chains.filter(chain => chain?.name?.toLowerCase()?.includes(filteredChain.toLowerCase()))?.length
      })
    if (filteredChain) {
      filtered = filtered.map(city => ({
        ...city,
        chains: city?.chains.filter(chain => chain?.name?.toLowerCase()?.includes(filteredChain.toLowerCase()))
      }))
    }

    if (!Object.keys(filtered).length) return [...newElement('город')]

    return [...newElement('город'), ...filtered.map(city => ({
      title: city.name,
      key: city.id,
      icon: renderIcon ? renderIcon(city.id) : null,
      children: [...newElement('сеть и магазин', city.id), ...city.chains?.map(chain => ({
        title: chain.name,
        key: city.id + ',' + chain.id,
        children: [...newElement('магазин', city.id + ',' + chain.id), ...chain.stores?.map(store => ({
          title: store.name,
          key: city.id + ',' + chain.id + ',' + store.id
        }))]
      }))]
    }))]
  }, [renderIcon, orgTree, filteredCity, filteredChain, newElement])

  return (
    <Spin spinning={isLoading} size='large'>
      {tree.length < 1
        ? <Text type='secondary'>Область видимости не задана</Text>
        : (
          <Tree
            showIcon
            height={height}
            checkable={checkable}
            disabled={disabled}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            switcherIcon={switcherIcon}
            onSelect={onSelect}
            treeData={tree}
            selectable={selectable}
          />
        )}
    </Spin>
  )
}
