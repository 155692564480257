import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Modal, Row, Col, message, Table } from 'antd'
import { useQueryAdminPartnerProfileHistory } from '../../../../api/react-query/adminPartners'
import { AppConfig } from '../../../../AppConfig'

function ProfileHistory ({ partnerId, disabled }) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  const {
    data: profileHistoryData,
    isLoading: profileHistoryLoading,
    isError: profileHistoryError
  } = useQueryAdminPartnerProfileHistory(partnerId, {
    enabled: !!partnerId && isModalVisible
  })

  useEffect(() => {
    if (profileHistoryError || (profileHistoryData && profileHistoryData.errorMessage)) {
      message.error(profileHistoryData.errorMessage || 'Ошибка получения истории данных партнера')
    }
  }, [profileHistoryData, profileHistoryError])

  const columns = [
    {
      title: 'Дата и время',
      dataIndex: 'timestamp',
      render: timestamp => (timestamp ? moment(timestamp).format(AppConfig.formats.shortDateAndTime) : '')
    },
    {
      title: 'Поле',
      dataIndex: 'column'
    },
    {
      title: 'Старое значение',
      dataIndex: 'oldValue'
    },
    {
      title: 'Новое значение',
      dataIndex: 'newValue'
    },
    {
      title: 'Автор действия',
      dataIndex: 'actor',
      render: actor => actor || 'Не указано'
    }
  ]

  return (
    <div>
      <Button
        disabled={disabled}
        onClick={showModal}
        type='primary'
      >
        История данных
      </Button>
      <Modal
        width={1200}
        style={{ top: 20 }}
        title={
          <Row gutter={[12, 6]} align='middle'>
            <Col className='mr-3'><b>История изменений данных партнера</b></Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className='assetsModalForm'
      >
        <Table
          columns={columns}
          loading={profileHistoryLoading}
          dataSource={profileHistoryData?.entries || []}
        />
      </Modal>
    </div>
  )
}

export default ProfileHistory
