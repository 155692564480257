export const policyScreen = {
  title: 'Соглашения',
  personalDataPolicy: {
    placeholder: 'Я даю согласие на обработку моих персональных данных в соответствии с приведенным текстом Соглашения.',
    placeholderSzOperator: 'Я даю согласие на обработку моих персональных данных в соответствии с приведенным текстом Согласия',
    placeholderPreV2: 'Я даю согласие на обработку',
    placeholderV2: 'моих персональных данных в соответствии с приведенным текстом Соглашения.',
    success: 'Соглашение на обработку персональных данных подтверждено.'
  },
  electronicSignaturePolicy: {
    placeholder: 'Я даю согласие на использование простой электронной подписи.',
    placeholderPreV2: 'Я даю согласие на использование',
    placeholderV2: 'простой электронной подписи.',
    success: 'Соглашение об использовании простой электронной подписи подтверждено.'
  },
  agreementText: 'Подтвердите, что  ознакомились с Условиями Соглашения',
  button: {
    submit: 'Далее',
    continue: 'Продолжить'
  },
  errors: {
    agreement: 'Не удалоось загрузить соглашение',
    agreementAccept: 'Не удалоось сохранить согласие'
  }
}
