import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'

export const REPORTS_KEY = 'reports'

const fetchReportsQueue = ({ queryKey }) => {
  const [/* key */, type] = queryKey
  const typeQuery = type && !Array.isArray(type) ? `/${type}` : ''
  let paramString
  if (type && Array.isArray(type)) {
    const typeParams = type.map(item => ['type', item])
    paramString = new URLSearchParams(typeParams).toString()
  }
  return api.get({ path: `${REPORTS_KEY}/queue${typeQuery}${paramString ? `?${paramString}` : ''}` })
}

const fetchDispatchReport = ({ type, data = {} }) => {
  return api.post({ path: `${REPORTS_KEY}/queue/${type}`, data })
}

export function useGetReportsQueue (type, options) {
  return useQuery([REPORTS_KEY, type], fetchReportsQueue, options)
}

export function useMutateDispatchReportToQueue () {
  return useMutation(fetchDispatchReport, {
    onSuccess: () => {
      queryClient.refetchQueries([REPORTS_KEY])
    }
  })
}
