import { errors } from '../translates'
import useCommonDict from './useCommonDict'
import { useOrgStoresQuery } from '../api/react-query/org'
import { sortByName } from '../helper'

export default function useStores (data, options) {
  return useCommonDict({
    errorMessage: errors.stores,
    queryMethod: useOrgStoresQuery,
    queryBody: data,
    queryOptions: options,
    successCallback: data => [...sortByName(data?.result || [])]
  })
}
