import React, { useMemo } from 'react'
import { BirthDateFormItem, NameFormItem } from '../../Common'
import { profileScreen } from '../../../translates'
import { createApiDataFromForm, getBirthDateRules, isShownSberIcon, validateDate, validateName } from '../../../helper'
import { useStores } from '../../../Store/Store'
import MiddleNameHideableFormItem from '../../Common/FormItems/MiddleNameHideableFormItem'
import PassportSeriesFormItem from '../../Common/FormItems/Passport/PassportSeriesFormItem'
import PassportNumberFormItem from '../../Common/FormItems/Passport/PassportNumberFormItem'
import PassportIssuerNameFormItem from '../../Common/FormItems/Passport/PassportIssuerNameFormItem'
import PassportIssuerCodeFormItem from '../../Common/FormItems/Passport/PassportIssuerCodeFormItem'
import PassportIssueDateFormItem from '../../Common/FormItems/Passport/PassportIssueDateFormItem'
import LegalAgeCapableItem from '../../Common/FormItems/LegalAgeCapableItem'
import CorrectInfo from './ErrorStep/CorrectInfo'
import { flowV2Steps } from './ProfileV2'
import { AppConfig } from '../../../AppConfig'
import VehicleNumberFormItem from '../../Common/FormItems/VehicleNumberFormItem'

function PassportStep ({ form, trigger, doFetchProfileCheck }) {
  const {
    partnerProfile: {
      profile,
      required,
      updateForm,
      visible,
      isCorrectionStatus
    } = {}
  } = useStores()

  const handleUpdateForm = () => updateForm({ ...form.getFieldsValue() })

  const handleChangeFormAndCheck = () => {
    const values = form.getFieldsValue()
    values.personalData = {
      ...values.personalData
    }
    const info = createApiDataFromForm(values)
    doFetchProfileCheck({ info })
  }

  const isShowLegalAgeCapable = useMemo(() => {
    return profile?.personalData?.isLegalAgeCapable === false || profile?.personalData?.isLegalAgeCapable === true
  }, [profile?.personalData?.isLegalAgeCapable])

  return (
    <>
      <CorrectInfo step={flowV2Steps.passportStep} />
      {visible?.personalData && (
        <>
          <NameFormItem
            name={['personalData', 'surname']}
            placeholder={profileScreen.input.surname.placeholder}
            rules={[{ required: true, message: profileScreen.input.surname.message, validator: validateName }]}
            label={profileScreen.input.surname.label}
          />

          <NameFormItem
            name={['personalData', 'firstName']}
            placeholder={profileScreen.input.firstName.placeholder}
            rules={[{ required: true, message: profileScreen.input.firstName.message, validator: validateName }]}
            label={profileScreen.input.firstName.label}
          />

          <MiddleNameHideableFormItem form={form} onUpdate={handleUpdateForm} trigger={trigger} />

          <BirthDateFormItem
            label={profileScreen.input.birthDate.label}
            placeholder={profileScreen.input.birthDate.placeholder}
            rules={[
              { required: required?.personalData, message: profileScreen.input.birthDate.message, validator: validateDate },
              ...getBirthDateRules()
            ]}
            onBlur={() => {
              form.validateFields([['personalData', 'birthDate']]).then((values) => {
                handleChangeFormAndCheck()
              }).catch(() => {})
            }}
          />
          {
            [AppConfig.partnerRoles.automotiveCourier, AppConfig.partnerRoles.automotiveCourierOnDemand].includes(profile?.personalData?.roleCode) && (
              <VehicleNumberFormItem
                hideForeignFormat
                requiredRule={!isCorrectionStatus}
                form={form}
                initValues={{
                  [profileScreen.input.isForeignVehicleNumber.name]: form.getFieldValue(['personalData', profileScreen.input.isForeignVehicleNumber.name]),
                  [profileScreen.input.carLicencePlate.name]: form.getFieldValue(['personalData', profileScreen.input.carLicencePlate.name])
                }}
              />
            )
          }
          {isShowLegalAgeCapable && <LegalAgeCapableItem onChange={handleChangeFormAndCheck} v2 />}

          <PassportSeriesFormItem classNameInput='noScrollNumber' showCheckIcon={isShownSberIcon('passport.series', profile)} />
          <PassportNumberFormItem classNameInput='noScrollNumber' showCheckIcon={isShownSberIcon('passport.number', profile)} />
          <PassportIssuerNameFormItem showCheckIcon={isShownSberIcon('passport.issuerName', profile)} />
          <PassportIssuerCodeFormItem showCheckIcon={isShownSberIcon('passport.issuerCode', profile)} />
          <PassportIssueDateFormItem showCheckIcon={isShownSberIcon('passport.issueDate', profile)} />

        </>
      )}
    </>
  )
}

export default PassportStep
