import React, { useEffect, useState } from 'react'
import { Layout, Button, Row, message, Spin, Typography, PageHeader, Input } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

import MobileHeader from '../../../components/Partner/MobileHeader/MobileHeader'
import { useDataApi } from '../../../api/useDataApi'
import { getHeaders } from '../../../api/react-query/api'
import { getShareLinkPath, partnerRoles4MultiOffer, policyTypes } from '../../../Constants'
import { offerScreen, profileContractScreen } from '../../../translates'
import './ProfileContractScreen.css'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import { AppConfig } from '../../../AppConfig'
import DocWithNotices from '../../../components/Partner/PolicyAgreement/DocWithNotices'

const { Paragraph } = Typography

export default function ProfileContractScreen () {
  const history = useHistory()
  const [offer, setOffer] = useState({})
  const [link, setLink] = useState('')

  const [{ data, isError, isLoading }] = useDataApi({
    initialUrl: 'partner/offer/view',
    initialParams: {
      method: 'GET',
      headers: getHeaders()
    }
  })
  useEffect(() => {
    if (!data?.isSuccess) return
    setOffer(data.offer)
  }, [data])
  /**
   * Errors from api requests
   */
  useEffect(() => {
    if (isError) {
      message.error(profileContractScreen.errors.offer)
    }
  }, [isError])

  const [{ data: dataProfile }] = useDataApi({
    initialUrl: 'partner/profile',
    initialParams: {
      method: 'GET',
      headers: getHeaders()
    }
  })

  const [{ data: linkData, isError: linkIsError, isLoading: linkIsLoading }, doFetch] = useDataApi({})
  useEffect(() => {
    if (linkData?.isSuccess) {
      setLink(getShareLinkPath(linkData.shareId))
    }
    if (linkIsError) {
      return message.error(profileContractScreen.errors.link)
    }
  }, [linkData, linkIsError])

  const createLink = () => {
    doFetch('partner/offer/share', {
      method: 'POST',
      headers: getHeaders()
    })
  }

  useEffect(() => {
    if (link) {
      message.success(profileContractScreen.copyLinkInBuffer)
      const copyText = document.querySelector('#input')
      copyText.select()
      document.execCommand('copy')
    }
  }, [link])

  return (
    <Layout className='ProfileContractScreen'>
      <MobileHeader />
      <Row className='mt-3'>
        <Button type='text' onClick={() => { history.goBack() }}>
          <LeftOutlined /> {profileContractScreen.button.back}
        </Button>
      </Row>
      <Row justify='space-around' align='middle'>
        <Button type='primary' loading={linkIsLoading} onClick={createLink}>{profileContractScreen.button.createLink}</Button>
      </Row>
      {
        partnerRoles4MultiOffer.includes(dataProfile?.partner?.profile?.personalData?.roleCode) && (
          <>
            <Row justify='space-around' align='middle' className='links'>
              <Link
                to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.cyberSecurityPolicy}`}
              >
                {offerScreen.offerParts.cyberSecurityPolicySingle}
              </Link>
            </Row>
            <Row justify='space-around' align='middle' className='links'>
              <Link
                to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.assemblyAndDeliveryPolicy}`}
              >
                {offerScreen.offerParts.assemblyAndDeliveryPolicySingle}
              </Link>
            </Row>
          </>
        )
      }
      <Row justify='space-around' align='middle' className='mt-3 ph-6'>
        {link && <Input value={link} id='input' />}
      </Row>
      <Spin spinning={isLoading} size='large'>
        <Row justify='space-around'>
          <PageHeader title={profileContractScreen.title} />
        </Row>
        <Row>
          <Paragraph className='document'>
            <DocWithNotices docData={offer} />
          </Paragraph>
        </Row>
      </Spin>
    </Layout>
  )
}
