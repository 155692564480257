import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function PassportPhoto1 (props) {
  return (
    <>
      <Title level={3}>Основной разворот</Title>
      <Text>Убедитесь, что машиночитаемая подпись внизу страницы хорошо видна</Text>
      <br />
      <img src='/img/photo-doc/passport-doc-1.png' className='img-doc' />
    </>
  )
}

export default PassportPhoto1
