import React, { useEffect, useReducer, useRef } from 'react'
import { message, Modal } from 'antd'
import { useMutateAdminPartnerVerificationFormAndStatus } from '../api/react-query/adminPartners'
import { COORDINATOR_PARTNER_ID, coordinatorTickStatusTimerMs } from '../Constants'
import { generatePath, useHistory } from 'react-router-dom'
import { ADMIN_PARTNER_PAGE, ADMIN_PARTNERS_PAGE } from '../routes'
import { simpleReducer } from '../helper'

const initialState = {
  isActive: false
}

export default function useCoordinatorState () {
  const history = useHistory()
  const timerIdRef = useRef()
  const partnerId = localStorage.getItem(COORDINATOR_PARTNER_ID)
  const [state, setState] = useReducer(simpleReducer, initialState)

  const setTimerApp = (method, ms) => {
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current)
    }
    if (method) {
      timerIdRef.current = setTimeout(method, ms)
    }
  }

  const {
    mutate: getFormAndStatus,
    data: dataFormAndStatus,
    isSuccess: isSuccessFormAndStatus,
    isError: isErrorFormAndStatus
  } = useMutateAdminPartnerVerificationFormAndStatus()

  const info = () => {
    Modal.info({
      title: 'Не на портале',
      content: (
        <div>
          Для продолжения работы перейдите в статус на портале
        </div>
      ),
      onOk () {
        history.replace(generatePath(ADMIN_PARTNERS_PAGE))
      }
    })
  }

  const processCoordinatorStatus = (data) => {
    localStorage.setItem(COORDINATOR_PARTNER_ID, data?.partnerId ?? null)
    if (data?.isUserOnline && data?.partnerId) {
      setTimerApp?.(getFormAndStatus, coordinatorTickStatusTimerMs)
      if (!partnerId || (partnerId && partnerId !== data?.partnerId)) {
        history.replace(generatePath(ADMIN_PARTNER_PAGE, { id: data?.partnerId }))
      }
      return
    } else if (!data?.isUserOnline) {
      partnerId && info()
      stopStatusRequesting()
      localStorage.removeItem(COORDINATOR_PARTNER_ID)
      return
    }
    setTimerApp?.(getFormAndStatus, coordinatorTickStatusTimerMs)
  }

  useEffect(() => {
    if (state.isActive) {
      if (isSuccessFormAndStatus && dataFormAndStatus?.isSuccess) {
        processCoordinatorStatus(dataFormAndStatus)
      } else if (isErrorFormAndStatus || dataFormAndStatus?.errorMessage) {
        message.error(dataFormAndStatus?.errorMessage || 'Ошибка получения данных анкеты для координатора')
        setTimerApp?.(getFormAndStatus, coordinatorTickStatusTimerMs)
      }
    }
  }, [isSuccessFormAndStatus, isErrorFormAndStatus, dataFormAndStatus, state.isActive])

  const startStatusRequesting = () => {
    setState({ isActive: true })
    getFormAndStatus()
  }

  const stopStatusRequesting = () => {
    setState({ isActive: false })
    setTimerApp?.(null)
  }

  return {
    setTimerApp,
    getFormAndStatus,
    statusData: dataFormAndStatus,
    partnerId,
    startStatusRequesting,
    stopStatusRequesting
  }
}
