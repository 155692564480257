import React from 'react'
import { Form, Checkbox, Tooltip, Typography } from 'antd'
import { profileScreen } from '../../../translates'
import { QuestionCircleOutlined } from '@ant-design/icons'

const { Text } = Typography
const hintStyle = { color: '#595959', fontSize: 'small', display: 'block', marginLeft: '1.5rem' }

function LegalAgeCapableItem ({ onChange, v2 = false }) {
  const handleChange = (check) => {
    onChange?.(check)
  }
  return (
    <div className='ant-row ant-form-item' style={{ textAlign: 'left' }}>
      <label>
        <Form.Item
          name={['personalData', profileScreen.input.isLegalAgeCapable.name]}
          valuePropName='checked'
          noStyle
        >
          <Checkbox style={{ marginRight: '0.5rem' }} onChange={handleChange} />
        </Form.Item>
        {profileScreen.input.isLegalAgeCapable.label}
        {
          v2
            ? (<><br /><Text style={hintStyle}>{profileScreen.input.isLegalAgeCapable.hintV2}</Text></>)
            : (
              <Tooltip placement='top' title={profileScreen.input.isLegalAgeCapable.hint} trigger='click' color='white' overlayInnerStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                <QuestionCircleOutlined style={{ marginLeft: 4 }} />
              </Tooltip>
            )
        }
      </label>
    </div>
  )
}

export default LegalAgeCapableItem
