import React, { useReducer, useMemo, useEffect } from 'react'
import {
  Typography,
  Form,
  DatePicker,
  Col,
  Row,
  Button, message, Divider
} from 'antd'
import moment from 'moment-timezone'

import './Report.css'
import { simpleReducer } from '../../../helper'
import FilterTitle from './FilterTitle'
import { AppConfig } from '../../../AppConfig'
import { useGetReportsQueue, useMutateDispatchReportToQueue } from '../../../api/react-query/reports'
import ReportList from '../../../components/Admin/Reports/ReportList'

const { Title } = Typography
const { RangePicker } = DatePicker

const now = moment()
const initialStart = moment().subtract(6, 'days')

export default function HistoryReport () {
  const [form] = Form.useForm()
  const initialState = {
    start: initialStart,
    end: now
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    isError: isErrorReportsQueue,
    data: dataReportsQueue,
    isLoading: isLoadingReportsQueue,
    refetch: refetchReportsQueue
  } = useGetReportsQueue(AppConfig.report.types.partnersChanges)

  useEffect(() => {
    if (isErrorReportsQueue || dataReportsQueue?.errorMessage) {
      message.error(dataReportsQueue?.errorMessage || 'Не удалось получить отчеты')
    }
  }, [isErrorReportsQueue, dataReportsQueue])

  const {
    mutate: addHistoryReportToQueue,
    isError: isErrorAddHistoryReportToQueue,
    data: dataAddHistoryReportToQueue,
    isLoading: isLoadingAddHistoryReportToQueue
  } = useMutateDispatchReportToQueue()

  useEffect(() => {
    if (isErrorAddHistoryReportToQueue || dataAddHistoryReportToQueue?.errorMessage) {
      message.error(dataAddHistoryReportToQueue?.errorMessage || 'Не удалось поставить отчет в очередь')
    }
  }, [isErrorAddHistoryReportToQueue, dataAddHistoryReportToQueue])

  const datePickerDefaultValue = useMemo(() => [state.start, state.end], [state.start, state.end])

  const handleGetReport = () => {
    const data = {
      minDate: moment(state.start)
        .set({ hour: 0, minute: 0, second: 0 })
        .format(AppConfig.formats.shortDateAndTimeApi),
      maxDate: moment(state.end)
        .set({ hour: 24, minute: 0, second: 0 })
        .format(AppConfig.formats.shortDateAndTimeApi)
    }
    addHistoryReportToQueue({
      type: AppConfig.report.types.partnersChanges,
      data
    })
  }
  const handleChangeFilter = ([start, end]) => setState({ start, end })

  return (
    <>
      <Title level={3} className='title-management'>Отчет по истории изменения данных партнеров</Title>
      <Form
        form={form}
        onFinish={handleGetReport}
      >

        <FilterTitle title='Период'>
          <Form.Item>
            <RangePicker
              defaultValue={datePickerDefaultValue}
              onChange={handleChangeFilter}
            />
          </Form.Item>
        </FilterTitle>

        <Row>
          <Col className='mr-3'>
            <Button
              type='primary'
              htmlType='submit'
              loading={isLoadingAddHistoryReportToQueue}
            >
              Запросить отчет
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      <Row className='mt-3' gutter={15} align='middle'>
        <Col>
          <Title level={5}>Отчеты</Title>
        </Col>
        <Col>
          <Button onClick={() => refetchReportsQueue()}>Обновить</Button>
        </Col>
      </Row>
      <ReportList
        isLoadingReports={isLoadingReportsQueue}
        dataReports={dataReportsQueue?.items}
      />
    </>
  )
}
