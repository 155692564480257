import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function LmkAttestationPhoto () {
  return (
    <>
      <Title level={3}>Страница с голограммой</Title>
      <Text>
        Убедитесь, что голограмма аттестации и печать рядом с ней хорошо видны
      </Text>
      <br />
      <br />
      <img src='/img/photo-doc/medical-doc-3.png' className='img-doc' />
    </>
  )
}

export default LmkAttestationPhoto
