import React from 'react'
import { Col, Row } from 'antd'
import { profileScreen } from '../../../../translates'
import { AppConfig } from '../../../../AppConfig'
import './RegistrationKindItem.css'

function RegistrationKindItem ({ form, acceptButton }) {
  return (
    <>
      <Row className='registration-kind'>
        <Col span={22}>
          {profileScreen.input.registrationKind.label}:{' '}
          {AppConfig.registrationTypeLabels[form?.personalDetails?.registrationKindCode || form?.registrationKindCode] || 'не указана'}
        </Col>
        {
          acceptButton && <Col span={2} style={{ textAlign: 'right' }}>{acceptButton}</Col>
        }
      </Row>
    </>
  )
}

export default RegistrationKindItem
