import React from 'react'
import TinkoffIcon from './TinkoffIcon'
import { flattenObject2, formatDateForFrontend, isShownTinkoffIcon } from '../../helper'

function CheckTinkoffIcon ({ forceShow = false, profileAndTinkoffIdProperty, profile, style }) {
  const getMultiProp = (profile, profileProp, propList) => {
    const flattenedObject = flattenObject2(profile)
    return propList.map(prop => {
      const val = flattenedObject[`${profileProp}.${prop}`]
      return prop === 'passport.issueDate' ? formatDateForFrontend(val) : val
    }).join('').trim()
  }

  if (forceShow) return <TinkoffIcon style={style} />

  if (profileAndTinkoffIdProperty === 'passport.series passport.number') {
    const propPassport = ['passport.series', 'passport.number']
    const personalDataPassport = getMultiProp(profile, 'personalData', propPassport)
    const tinkoffIdDataPassport = getMultiProp(profile, 'tinkoffData', propPassport)
    return personalDataPassport === tinkoffIdDataPassport && personalDataPassport && tinkoffIdDataPassport
      ? <TinkoffIcon style={style} />
      : null
  }

  if (profileAndTinkoffIdProperty === 'passport.issuerName passport.issuerCode passport.issueDate') {
    const propIssuer = [
      'passport.issuerName',
      'passport.issuerCode',
      'passport.issueDate'
    ]
    const personalDataIssuer = getMultiProp(profile, 'personalData', propIssuer)
    const tinkoffIdDataIssuer = getMultiProp(profile, 'tinkoffData', propIssuer)
    return personalDataIssuer === tinkoffIdDataIssuer && personalDataIssuer && tinkoffIdDataIssuer
      ? <TinkoffIcon style={style} />
      : null
  }

  if (profileAndTinkoffIdProperty === 'registrationAddress') {
    const propAddress = [
      'registrationAddress.zipCode',
      'registrationAddress.country',
      'registrationAddress.region',
      'registrationAddress.district',
      'registrationAddress.city',
      'registrationAddress.settlement',
      'registrationAddress.street',
      'registrationAddress.house',
      'registrationAddress.building',
      'registrationAddress.apartment'
    ]
    const personalDataAddress = getMultiProp(profile, 'personalData', propAddress)
    const tinkoffIdDataAddress = getMultiProp(profile, 'tinkoffData', propAddress)
    return personalDataAddress === tinkoffIdDataAddress && personalDataAddress && tinkoffIdDataAddress
      ? <TinkoffIcon style={style} />
      : null
  }

  return isShownTinkoffIcon(profileAndTinkoffIdProperty, profile) ? <TinkoffIcon style={style} /> : null
}

export default CheckTinkoffIcon
