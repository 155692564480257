import React, { useReducer } from 'react'
import ModalDrawerPopup from '../../../Common/ModalDrawerPopup/ModalDrawerPopup'
import { simpleReducer } from '../../../../helper'
import { Typography, Button } from 'antd'
import './ModalMyTaxApp.css'

const { Title, Text } = Typography

const modalTypes = {
  selfEmployed: 'selfEmployed',
  partner: 'partner'
}
const initialState = {
  isOpen: false,
  type: modalTypes.partner
}

const heights = {
  [modalTypes.selfEmployed]: '250',
  [modalTypes.partner]: '300'
}

function ModalMyTaxApp () {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleClose = () => {
    setState({ isOpen: false })
  }

  return (
    <ModalDrawerPopup
      className='legal-form-popup ModalMyTaxApp'
      isOpen={state.isOpen}
      onClose={handleClose}
      maskClosable
      destroyOnClose
      drawerHeight={heights[state.type]}
    >
      {state.type === modalTypes.selfEmployed && (
        <>
          <Title level={3}>Оформите самозанятость</Title>
          <Text>В приложении или на сайте «Мой налог». <br />Затем выберете Купер в качестве партнёра</Text>
          <Button className='btn-my-tax'><img src='/img/fns.png' />Перейти в «Мой налог»</Button>
          <Button className='btn-confirm'>Подтвердить самозанятость</Button>
        </>
      )}
      {state.type === modalTypes.partner && (
        <>
          <Title level={3}>Выберите партнёрство</Title>
          <Text>Зайдите в приложение или на сайт «Мой налог» и выберите Купер в качестве партнёра</Text>
          <Button className='btn-my-tax'><img src='/img/fns.png' />Перейти в «Мой налог»</Button>
          <Button className='btn-confirm'>Подтвердить партнёрство</Button>
          <Button>Подтвердить позже</Button>
        </>
      )}
    </ModalDrawerPopup>
  )
}

export default ModalMyTaxApp
