import { Form, Input } from 'antd'
import React from 'react'
import { adminValidationErrors } from '../../../translates/errors'
import { profileScreen } from '../../../translates'

const BankAccountFormItem = ({
  label = 'Номер расчетного счета',
  rules = undefined,
  requiredRule = true,
  messageRule = adminValidationErrors.bankAccount,
  size = 'large',
  placeholder = '40817810099910004312',
  validator,
  classNameInput = '',
  onChange = () => {},
  disabled = false
}) => {
  return (
    <Form.Item
      name={['bankDetails', profileScreen.input.bankAccount.name]}
      label={label}
      rules={rules ?? [{ required: requiredRule, message: messageRule, ...(validator ? { validator } : {}) }]}
    >
      <Input
        type='number'
        size={size}
        placeholder={placeholder}
        className={classNameInput}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  )
}
export default BankAccountFormItem
