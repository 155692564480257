import { Button, message, Typography, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { usePartnerVerificationStatusesQuery } from '../../../../api/react-query/dict'
import { twoMinutes } from '../../../../Constants'
import { useMutateAdminUpdatePartnerVerificationStatus } from '../../../../api/react-query/adminPartners'
import './VerificationStatus.css'

const { Text } = Typography

export default function VerificationStatus ({ profile }) {
  const { id } = useParams()
  const [verificationStatuses, setVerificationStatuses] = useState([])
  const {
    data: partnerVerificationStatusesData,
    isError: isErrorPartnerVerificationStatuses,
    isSuccess: isSuccessPartnerVerificationStatuses
  } = usePartnerVerificationStatusesQuery(
    {
      enabled: profile?.verificationStatus?.allowEdit,
      staleTime: twoMinutes
    })
  useEffect(() => {
    if (isSuccessPartnerVerificationStatuses) {
      setVerificationStatuses([...(partnerVerificationStatusesData?.result || [])])
    }
  }, [isSuccessPartnerVerificationStatuses, partnerVerificationStatusesData])

  useEffect(() => {
    if (isErrorPartnerVerificationStatuses) {
      message.error('Ошибка доступа к данным')
    }
  }, [isErrorPartnerVerificationStatuses])

  const {
    mutate: mutateUpdatePartnerVerificationStatus,
    data: dataUpdatePartnerVerificationStatus,
    isError: isErrorUpdatePartnerVerificationStatus,
    isLoading: isLoadingUpdatePartnerVerificationStatus,
    isSuccess: isSuccessUpdatePartnerVerificationStatus
  } = useMutateAdminUpdatePartnerVerificationStatus()

  useEffect(() => {
    if (isErrorUpdatePartnerVerificationStatus) {
      message.error('Ошибка применения к партнеру доп. статуса.')
    }
  }, [isErrorUpdatePartnerVerificationStatus])
  useEffect(() => {
    if (isSuccessUpdatePartnerVerificationStatus) {
      if (dataUpdatePartnerVerificationStatus?.errorMessage) {
        return message.error(dataUpdatePartnerVerificationStatus?.errorMessage)
      }
      message.success('Вы применили к партнеру доп. статус: ' + dataUpdatePartnerVerificationStatus?.partner?.profile?.verificationStatus?.status)
    }
  }, [isSuccessUpdatePartnerVerificationStatus])

  const handleChangeVerificationStatus = (status) => {
    mutateUpdatePartnerVerificationStatus({ status, id })
  }

  if (!profile?.verificationStatus?.allowEdit) return null
  return (
    <>
      <Row>
        <Spin spinning={isLoadingUpdatePartnerVerificationStatus}>
          <Row className='verification-status'>
            {verificationStatuses.map(verificationStatus => (
              <Button
                className={`mr-3 ${verificationStatus?.color} ${verificationStatus?.name === profile?.verificationStatus?.status && 'active'}`}
                key={verificationStatus?.name}
                onClick={() => handleChangeVerificationStatus(verificationStatus?.name)}
              >
                {verificationStatus?.name}
              </Button>
            ))}
          </Row>
        </Spin>
      </Row>
      {profile?.verificationStatus?.status && (
        <Row className='mt-3'>
          <Text>Статус партнера при проверке: {profile?.verificationStatus?.status}</Text>
        </Row>)}
    </>
  )
}
