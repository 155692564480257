import React, { useEffect, useReducer } from 'react'
import { Button, message } from 'antd'
import { authScreen } from '../../../translates'
import { isMobile, simpleReducer } from '../../../helper'
import { useMutateStartIODCPartner, useMutateStartSberIdPartner } from '../../../api/react-query/partners'
import { AppConfig } from '../../../AppConfig'

const initialState = {
  sberLink: null,
  loading: false
}

const ENABLE_MOBILE_APP_REDIRECT = false

const oidcParamNames = AppConfig.sberId.oidcParams

function AuthSberId ({ mobilePhone, type }) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = '/img/sberid-sdk.production.js'
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const {
    data: dataStart,
    mutate: startIODC,
    isLoading: isLoadingStart,
    isError: isErrorStart,
    isSuccess: isSuccessStart
  } = useMutateStartIODCPartner()

  const {
    data: dataStartSberId,
    mutate: startSberId,
    isLoading: isLoadingStartSberId,
    isError: isErrorStartSberId,
    isSuccess: isSuccessStartSberId
  } = useMutateStartSberIdPartner()

  useEffect(() => {
    if (isSuccessStart && dataStart?.isSuccess) {
      initSberSdk(dataStart)
    } else if (isErrorStart || dataStart?.errorMessage) {
      message.error(dataStart?.errorMessage || 'Ошибка запроса данных авторизации SberId')
      setState({ loading: false })
    }
  }, [dataStart, isSuccessStart, isErrorStart])

  useEffect(() => {
    if (isSuccessStartSberId && dataStartSberId?.isSuccess) {
      initSberSdk(dataStartSberId)
    } else if (isErrorStartSberId || dataStartSberId?.errorMessage) {
      message.error(dataStartSberId?.errorMessage || 'Ошибка запроса персональных данных SberId')
      setState({ loading: false })
    }
  }, [dataStartSberId, isSuccessStartSberId, isErrorStartSberId])

  const initSberSdk = async (oidcParams) => {
    const params = {
      [oidcParamNames.response_type]: oidcParams[oidcParamNames.response_type],
      [oidcParamNames.client_type]: oidcParams[oidcParamNames.client_type],
      [oidcParamNames.client_id]: oidcParams[oidcParamNames.client_id],
      [oidcParamNames.redirect_uri]: oidcParams[oidcParamNames.redirect_uri],
      [oidcParamNames.state]: oidcParams[oidcParamNames.state],
      [oidcParamNames.scope]: oidcParams[oidcParamNames.scope],
      [oidcParamNames.nonce]: oidcParams[oidcParamNames.nonce],
      ...(oidcParams[oidcParamNames.login_hint] ? { [oidcParamNames.login_hint]: oidcParams[oidcParamNames.login_hint] } : {})
    }
    if (window.SberidSDK) {
      const { link, deeplink } = await window.SberidSDK.getUrl(oidcParams.sberid_host, params)
      const formattedLink = link?.startsWith(oidcParams.sberid_host) ? link : `${oidcParams.sberid_host}?${link}`
      setState({
        sberLink: { link: formattedLink, deeplink }
      })
    }
  }

  useEffect(() => {
    if (state.sberLink) {
      if (ENABLE_MOBILE_APP_REDIRECT && isMobile()) {
        setTimeout(() => { window.location = state.sberLink.link }, 100)
        window.location = state.sberLink.deeplink
      } else {
        window.location = state.sberLink.link
      }
      setState({ loading: false })
    }
  }, [state.sberLink])

  const handleClick = () => {
    setState({ loading: true })
    if (type === AppConfig.sberId.buttonType.auth) {
      startIODC({ mobilePhone: mobilePhone || '' })
    } else {
      startSberId()
    }
  }

  return (
    <div className='sberContainer'>
      <Button
        className='sberId'
        block
        size='large'
        type='primary'
        onClick={handleClick}
        loading={state.loading || isLoadingStart || isLoadingStartSberId}
      >
        <img src='/img/sber-icon.png' />
        {type === AppConfig.sberId.buttonType.auth ? authScreen.button.sberId : 'Сбер ID'}
      </Button>
    </div>
  )
}

export default AuthSberId
