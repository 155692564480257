import React, { useCallback, useEffect, useReducer } from 'react'
import { Button, Col, Form, message, Row, Select, Typography, Divider } from 'antd'

import './Report.css'
import { simpleReducer, sortByName } from '../../../helper'
import FilterTitle from './FilterTitle'
import { AppConfig } from '../../../AppConfig'
import { useOrgTreeQuery } from '../../../api/react-query/org'
import ReportList from '../../../components/Admin/Reports/ReportList'
import { useGetReportsQueue, useMutateDispatchReportToQueue } from '../../../api/react-query/reports'

const { Title } = Typography
const { Option } = Select

const selectSearchFilter = (input, option) => {
  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export default function LmkReport () {
  const [form] = Form.useForm()
  const initialState = {
    cities: [],
    selectedCities: []
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    isSuccess: isSuccessOrgTree,
    isError: isErrorOrgTree,
    data: dataOrgTree,
    isLoading: isLoadingOrgTree
  } = useOrgTreeQuery({
    withEmptyCity: true,
    withClosed: true,
    withOpened: true
  })

  const {
    isError: isErrorReportsQueue,
    data: dataReportsQueue,
    isLoading: isLoadingReportsQueue,
    refetch: refetchReportsQueue
  } = useGetReportsQueue(AppConfig.report.types.partnersLmk)

  const {
    mutate: addReportToQueue,
    isError: isErrorAddReportToQueue,
    data: dataAddReportToQueue,
    isLoading: isLoadingAddReportToQueue
  } = useMutateDispatchReportToQueue()

  useEffect(() => {
    if (isSuccessOrgTree && dataOrgTree?.isSuccess) {
      const cities = sortByName(dataOrgTree?.cities)
      setState({ cities })
    }
    if (isErrorOrgTree || dataOrgTree?.errorMessage) {
      message.error(dataOrgTree?.errorMessage || dataOrgTree?.title || 'Ошибка доступа к данным')
    }
  }, [dataOrgTree, isSuccessOrgTree, isErrorOrgTree])

  useEffect(() => {
    if (isErrorAddReportToQueue || dataAddReportToQueue?.errorMessage) {
      message.error(dataAddReportToQueue?.errorMessage || 'Не удалось поставить отчет в очередь')
    }
  }, [isErrorAddReportToQueue, dataAddReportToQueue])

  useEffect(() => {
    if (isErrorReportsQueue || dataReportsQueue?.errorMessage) {
      message.error(dataReportsQueue?.errorMessage || 'Не удалось получить отчеты')
    }
  }, [isErrorReportsQueue, dataReportsQueue])

  const handleChangeCities = useCallback(
    (value, items) => {
      setState({
        selectedCities: items.map(e => e.key)
      })
    },
    []
  )

  const handleAddReportToQueue = () => {
    addReportToQueue({
      type: AppConfig.report.types.partnersLmk,
      data: {
        cityIds: state.selectedCities.length ? state.selectedCities : undefined
      }
    })
  }

  return (
    <>
      <Title level={3} className='title-management'>Отчет по ЛМК</Title>
      <Form
        form={form}
      >

        <FilterTitle title='Город'>
          <Form.Item name='cities'>
            <Select
              loading={isLoadingOrgTree}
              onChange={handleChangeCities}
              size='middle'
              placeholder='Выберите город'
              showSearch
              optionFilterProp='children'
              filterOption={selectSearchFilter}
              allowClear
              mode='multiple'
            >
              {state.cities?.map(city => (
                <Option key={city.id} value={city.name}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </FilterTitle>

        <Row>
          <Col className='mr-3'>
            <Button
              loading={isLoadingAddReportToQueue}
              type='primary'
              htmlType='button'
              onClick={() => handleAddReportToQueue()}
            >
              Запросить отчет
            </Button>
          </Col>
        </Row>
      </Form>
      {
        dataReportsQueue?.items &&
          <>
            <Divider />
            <Row className='mt-3' gutter={15} align='middle'>
              <Col>
                <Title level={5}>Отчеты</Title>
              </Col>
              <Col>
                <Button onClick={() => refetchReportsQueue()}>Обновить</Button>
              </Col>
            </Row>
            <ReportList
              isLoadingReports={isLoadingReportsQueue}
              dataReports={dataReportsQueue?.items}
            />
          </>
      }
    </>
  )
}
