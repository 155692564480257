import { Form } from 'antd'
import React from 'react'
import { MaskedInput } from 'antd-masked-input'
import { errors, profileScreen } from '../../../translates'
import { validateDate, validateDateNotFuture } from '../../../helper'

const FireBriefingDateItem = ({
  label = profileScreen.input.briefingDate.label,
  rules = undefined,
  required = false,
  messageRule = profileScreen.input.briefingDate.message,
  size = 'large',
  placeholder = profileScreen.input.briefingDate.placeholder
}) => {
  return (
    <Form.Item
      name={['fireBriefingDetails', profileScreen.input.briefingDate.name]}
      label={label}
      rules={rules ?? [
        { required: required, message: messageRule, validator: validateDate },
        {
          message: errors.futureDate,
          validator: (rule, value) => validateDateNotFuture({ rule, date: value })
        }
      ]}
    >
      <MaskedInput
        size={size}
        placeholder={placeholder}
        mask='11.11.1111'
      />
    </Form.Item>
  )
}
export default FireBriefingDateItem
