import React, { useEffect, useState } from 'react'
import { Select, Typography, Modal, message } from 'antd'
import { numberDeclension } from '../../../helper'
import { useMutateUserActivate, useMutateUserArchive } from '../../../api/react-query/assets'
import { useAssetStatusesQuery } from '../../../api/react-query/dict'

const { Option } = Select
const { Text } = Typography
const { confirm } = Modal

function AssetActions ({ assets, disabled = false, size = 'middle' }) {
  const [action, setAction] = useState(null)
  const [assetStatuses, setAssetStatuses] = useState([])
  const handleChange = value => setAction(value)

  const {
    mutate: mutateActivate,
    isSuccess: isSuccessActivate,
    isError: isErrorActivate,
    isLoading: isLoadingActivate
  } = useMutateUserActivate()
  const {
    mutate: mutateArchive,
    isSuccess: isSuccessArchive,
    isError: isErrorArchive,
    isLoading: isLoadingArchive
  } = useMutateUserArchive()

  useEffect(() => {
    if (isSuccessActivate || isSuccessArchive) {
      message.success('Имущество успешно обновлено')
    } else if (isErrorActivate || isErrorArchive) {
      message.error('Ошибка обновления имущества')
    }
  }, [isSuccessActivate, isErrorActivate, isSuccessArchive, isErrorArchive])

  const {
    data: assetsData,
    isError: isErrorAssets,
    isSuccess: isSuccessAssets
  } = useAssetStatusesQuery({})

  useEffect(() => {
    if (isSuccessAssets) {
      setAssetStatuses(assetsData?.result)
    } else if (isErrorAssets) {
      console.error(assetsData)
      message.error('Ошибка получения списка типов имущества')
    }
  }, [assetsData, isErrorAssets, isSuccessAssets])

  useEffect(() => {
    if (!action) return
    const title = `${assets.length} ${numberDeclension(assets.length, [
      'единицу имущества',
      'единицы имущества',
      'единиц имущества'
    ])}`
    confirm({
      title: `Обновить ${title}`,
      content: `Вы уверены что хотите обновить ${title}`,
      okButtonProps: { loading: isLoadingActivate || isLoadingArchive },
      cancelButtonProps: { disabled: isLoadingActivate || isLoadingArchive },
      onOk: () => {
        assets.forEach(asset => {
          action === assetStatuses[0].id ? mutateActivate(asset) : mutateArchive(asset)
        })
        setAction(null)
      },
      onCancel: () => setAction(null)
    })
  }, [action, assets, mutateActivate, isLoadingActivate])

  return (
    <Select
      size={size}
      dropdownClassName='dropdown'
      className='ml-3'
      value={action}
      placeholder='Действия'
      disabled={disabled}
      onChange={handleChange}
    >
      {assetStatuses.map(status => (
        <Option key={status.id} value={status.id}>
          <div className={`icon icon-${status.id}`} />
          <Text className={`label-${status.id}`}>{status.name}</Text>
        </Option>
      ))}
    </Select>
  )
}

export default AssetActions
