import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function InnPhoto2 () {
  return (
    <>
      <Title level={3}>Мой налог</Title>
      <Text>Выберите пункт «Прочее» → «Профиль» → и сделайте снимок экрана.</Text>
      <br />
      <br />
      <Text>Экран может отличаться, но на нём должны быть видны ФИО и номер</Text>
      <br />
      <img src='/img/photo-doc/inn-doc-2.png' className='img-doc' />
    </>
  )
}

export default InnPhoto2
