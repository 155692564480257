import React, { useMemo } from 'react'
import { Col, Row } from 'antd'
import { AppConfig } from '../../../AppConfig'

function PartnerHeader ({ statType }) {
  const span = useMemo(() => {
    return statType === AppConfig.partnerStatTypes.newPartners ? 6 : 12
  }, [statType])

  return (
    <Row>
      <Col span={span}>ФИО</Col>
      {statType === AppConfig.partnerStatTypes.newPartners && (<Col span={span}>Статус</Col>)}
      <Col span={span}>Время статуса</Col>
      {statType === AppConfig.partnerStatTypes.newPartners && (<Col span={span}>В работе</Col>)}
    </Row>
  )
}

export default PartnerHeader
