import React from 'react'
import { Col, Row } from 'antd'

function CoordinatorHeader () {
  return (
    <Row>
      <Col span={4}>ФИО</Col>
      <Col span={4}>Время авторизации</Col>
      <Col span={5}>На портале</Col>
      <Col span={6}>Города</Col>
      <Col span={3}>Приоритет анкеты</Col>
      <Col span={2}>Статус</Col>
    </Row>
  )
}

export default CoordinatorHeader
