import React from 'react'
import { Tooltip } from 'antd'

function SberIcon ({ height = 20, width = 20, style }) {
  return (
    <Tooltip title='Получено из Сбер ID'>
      <svg width={width} height={height} style={style} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M22.1522 11.1992C22.1522 10.5259 22.0905 9.86791 21.9749 9.22796L19.6078 10.9731C19.6104 11.0476 19.6104 11.1221 19.6104 11.1992C19.6104 15.8639 15.8169 19.6574 11.1522 19.6574C6.4875 19.6574 2.69404 15.8639 2.69404 11.1992C2.69404 6.5345 6.4875 2.74104 11.1522 2.74104C12.9204 2.74104 14.5627 3.2859 15.9197 4.21628L18.0581 2.64081C16.1716 1.11417 13.7686 0.199219 11.1522 0.199219C5.07652 0.199219 0.152222 5.12352 0.152222 11.1992C0.152222 17.2749 5.07652 22.1992 11.1522 22.1992C17.2279 22.1992 22.1522 17.2724 22.1522 11.1992Z' fill='#21A038' />
        <path d='M19.857 4.47363C20.3762 5.14443 20.8208 5.8769 21.1755 6.66078L11.1521 14.0472L6.96545 11.4232L6.96545 8.26452L11.1521 10.8886L19.857 4.47363Z' fill='#21A038' />
      </svg>
    </Tooltip>
  )
}

export default SberIcon
