import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'
import { ADMIN_PARTNER_KEY } from './adminPartners'
import { USERS_KEY, USERS_ONLINE_KEY } from './users'

export const SA_PARTNERS_KEY = 'sa/partners'
export const SA_USERS_KEY = 'sa/users'
export const SA_EMAIL_KEY = 'sa/email'
export const SA_PHONE_KEY = 'sa/phone'
export const AVAILABLE_SUBSCRIPTIONS_KEY = 'topics'
export const USER_SUBSCRIPTIONS_KEY = 'subscriptions'

const getAvailableSubscriptions = ({ queryKey }) => {
  const [/* key */, id] = queryKey
  return api.get({ path: `${SA_EMAIL_KEY}/${AVAILABLE_SUBSCRIPTIONS_KEY}${id ? `?userId=${id}` : ''}` })
}
const getUserSubscriptions = ({ queryKey }) => {
  const [/* key */, id] = queryKey
  return api.get({ path: `${SA_EMAIL_KEY}/${USER_SUBSCRIPTIONS_KEY}/${id}` })
}
const setUserSubscriptions = ({ id, topicIds }) => {
  return api.post({ path: `${SA_EMAIL_KEY}/${USER_SUBSCRIPTIONS_KEY}/${id}`, data: { topicIds } })
}
const unbanSmsPartner = ({ id }) => {
  return api.post({ path: `${SA_PARTNERS_KEY}/sms/unban/${id}` })
}
const setNewPartnerPriority = ({ userId, priority }) => {
  return api.post({ path: `${SA_USERS_KEY}/${userId}/setPartnerTakeNewPriority`, data: { priority } })
}

const unbanPhone = (data) => {
  return api.post({ path: `${SA_PHONE_KEY}/sms/unban`, data })
}

export function useAvailableSubscriptionsQuery (id, options) {
  return useQuery([AVAILABLE_SUBSCRIPTIONS_KEY, id], getAvailableSubscriptions, options)
}
export function useUserSubscriptionsQuery (id, options) {
  return useQuery([USER_SUBSCRIPTIONS_KEY, id], getUserSubscriptions, options)
}
export function useMutateUserSubscriptions () {
  return useMutation(setUserSubscriptions, {
    onSuccess: (data, variables) => {
      queryClient.refetchQueries([USER_SUBSCRIPTIONS_KEY, variables.id])
    }
  })
}

export function useMutateUnbanSmsPartner () {
  return useMutation(unbanSmsPartner, {
    onSuccess: (data, variables) => {
      queryClient.resetQueries([ADMIN_PARTNER_KEY, variables.id], { active: true })
    }
  })
}

export function useMutateSetNewPartnerPriority () {
  return useMutation(setNewPartnerPriority, {
    onSuccess: (data, variables) => {
      queryClient.resetQueries([USERS_KEY, USERS_ONLINE_KEY], { active: true })
    }
  })
}

export function useMutateUnbanPhone () {
  return useMutation(unbanPhone)
}
