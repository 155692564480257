export const profileContractScreen = {
  title: 'Ваш Договор',
  button: {
    back: 'Назад',
    createLink: 'Создать ссылку на скачивание Договора'
  },
  errors: {
    offer: 'Не удалоось загрузить контракт',
    link: 'Не удалоось создать ссылку для скачивания'
  },
  copyLinkInBuffer: 'Ссылка скопирована в буфер обмена'
}
